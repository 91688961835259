import React from "react";
import { useSharedData } from "../SharedDataContext";

const PropertiesInput = ({
	label,
	options,
	optionID,
	textID,
	inputValue,
	selectedOption,
	onInputChange,
	onSelectChange,
	placeholderText,
	rows
}) => {
	const {labels, setLabels} = useSharedData();

	return (
		// <div className="chat-row-container">
		<>
			<div class="labelquestion">
				<label for="prompt">{label}</label>
				{/* <span>?</span> */}
			</div>
			{/* <label>{label}</label> */}
			{options && options.length > 0 && (
				// <div>
					<select value={selectedOption} id={optionID} onChange={onSelectChange}>
						<option value={0}>-{labels.lblPleaseChoose}-</option>
						{options.map((optionObj) => (
							<option key={`${optionID}_${optionObj.id}`} value={optionObj.id}>
								{optionObj.option}
							</option>
						))}
					</select>
				// </div>
			)}
			<textarea
				type="text"
				id={textID}
				placeholder={placeholderText}
				onChange={onInputChange}
				value={inputValue}
				cols="30" rows={rows}
			/>
			</>
		// </div>
	);
};

export default PropertiesInput;