import { useEffect, useState } from "react";

import {
	SwipeableList,
	LeadingActions,
	SwipeableListItem,
	SwipeAction,
	TrailingActions,
} from "react-swipeable-list";
import BookmarkOn from "../images/bookmark_on.svg";
import Star from "../images/star.png"
import BookmarkOff from "../images/bookmark_off.svg";
import Next from "../images/next.svg";

import { RiDeleteBin6Line } from "react-icons/ri";
import { BsBookmarkStar } from "react-icons/bs";
import { GoBookmarkSlash } from "react-icons/go";

import padlock from "../images/padlock.png"
import User from "../images/user.svg"
import firmenpunkt from "../images/firmenpunkt_logo.svg"
import circlelogo from "../images/CircleLogo.png"

import { Link } from "react-router-dom";

import { useSharedData } from "../SharedDataContext";

const PromptItem = (props) => {
	const id = props.id;
	const input = props.input;
	const output = props.output;
	const date = props.date;
	const promptname = props.name;
	const loggedInUser = props.loggedInUser;
	const template = props.template

	const { labels, setLabels, callBackend } = useSharedData();

	const {
		prompts,
		setPrompts,
		users,
		departments,
		markiertePrompts,
		promptDepartments,
		setMarkiertePrompts,
		kategorieList,
		promptCategories,
	} = useSharedData();

	const kategorieIDs = promptCategories.filter(
		(entry) => entry.fkPromptID == id
	);
	
	const filtered2 = kategorieIDs.map((element) => ({
		id: element.pkPromptCategoryID,
		eigenschaftName: `${kategorieList.find(
			(kategorie) =>
				kategorie.pkDataPromptCategoryID ===
				element.fkDataPromptCategoryID
		)?.strPromptCategoryName}`,
	})); 

	const [autor, setAutor] = useState("");
	const [userBereich, setUserBereich] = useState("");

	const [changeable, setChangeable] = useState(false);

	const [marked, setMarked] = useState(0);

	useEffect(() => {
		if (loggedInUser === null) {
			//setChangeable(false)
			// return false
		} else {
			if (
				prompts.find((item) => item.pkPromptID === id)?.fkUserID ===
				loggedInUser.pkUserID ||
				loggedInUser.fkDataRoleID > 1
			) {
				setChangeable(true);
			}
		}

		const autorData = users
			.filter((u) => u.pkUserID === props.fkUserID)
			.map((u) => `${u.strFirstName} ${u.strSurName}`)
			.join(", ");
		setAutor(autorData || "Unbekannter Autor");

		const departmentData = departments
			.filter((d) =>
				promptDepartments
					.filter((dep) => dep.fkPromptID === id)
					.map((elm) => elm.fkDepartmentID)
					.includes(d.pkDepartmentID)
			)
			.map((d) => d.strDepartment)
			.join(", ");
		setUserBereich(departmentData || "Unbekannter Bereich");
	}, []);
	useEffect(() => {
		const selectedPrompt = markiertePrompts.filter(
			(item) =>
				item?.fkUserID === loggedInUser.pkUserID &&
				item.fkPromptID === id
		);
		if (!selectedPrompt || selectedPrompt.length === 0) {
			setMarked(0);
		} else {
			setMarked(1);
		}
	}, []);

	const onMarkItem = () => {
		if (marked === 1) {
			setMarked(0);
			setMarkiertePrompts(
				markiertePrompts.filter((item) => item.fkPromptID !== id)
			);
			callBackend({
				action: "delete_bookmark",
				payload: { fkPromptID: id, fkUserID: loggedInUser.pkUserID },
			});
		}
		if (marked === 0) {
			setMarked(1);
			const newMarkiertPrompt = {
				fkPromptID: id,
				fkUserID: loggedInUser.pkUserID,
				Datum: new Date(),
			};
			markiertePrompts.push(newMarkiertPrompt);
			setMarkiertePrompts(markiertePrompts);
			callBackend({
				action: "save_bookmark",
				payload: { fkPromptID: id, fkUserID: loggedInUser.pkUserID },
			});
		}
	};
	const onDeleteItem = () => {
		if (changeable === true) {
			if (marked === 1) {
				setMarkiertePrompts(
					markiertePrompts.filter((item) => item.fkPromptID !== id)
				);
			}
			callBackend({
				action: "delete_prompt",
				payload: { pkPromptID: id },
			});
			callBackend({
				action: "delete_bookmark",
				payload: { fkPromptID: id },
			});
			callBackend({
				action: "delete_prompt_category",
				payload: { fkPromptID: id },
			});
			callBackend({
				action: "delete_prompt_tag",
				payload: { fkPromptID: id },
			});
			const filtereditems = prompts.filter(
				(item) => item.pkPromptID !== id
			);
			setPrompts(filtereditems);
		}
	};
	const leadingActions = () => (
		<LeadingActions>
			<SwipeAction
				onClick={() => {
					onMarkItem();
				}}
			>
				{marked === 0 ? (
					<span>
						{labels.lblMark} <BsBookmarkStar />
					</span>
				) : marked === 1 ? (
					<span>
						{labels.lblNoMark} <GoBookmarkSlash />
					</span>
				) : (
					"X"
				)}
			</SwipeAction>
		</LeadingActions>
	);
	const trailingActions = () => (
		<TrailingActions>
			<SwipeAction onClick={() => onDeleteItem()}>
				{changeable === true ? (
					<span>
						<RiDeleteBin6Line />
						{labels.lblDelete}
					</span>
				) : (
					""
				)}
			</SwipeAction>
		</TrailingActions>
	);
	return (
		// PromptItem als swipeable Item
		<div class="saved-prompt">
			{/* <SwipeableList fullSwipe={true}>
				<SwipeableListItem
					leadingActions={leadingActions()}
					trailingActions={trailingActions()}
				> */}
			<div class="title-bookmark" >
				<h3 class="title" data-title={promptname}>{promptname}</h3>
				<span>
				{template === 1 &&(
					<img
						className="bookmark"
						// onClick={onMarkItem}
						class="highlighted"
						src={Star}
						></img>
				)}
				{marked === 1 ? (
					<img
						className="bookmark"
						onClick={onMarkItem}
						class="highlighted"
						src={BookmarkOn}
					/>
				) : (
					<img
						className="bookmark"
						onClick={onMarkItem}
						class="non-highlighted"
						src={BookmarkOff}
					/>
				)}
				</span>
			</div>
			<div class="index">
				<div>
					<img src={User} alt="user" class="user"></img>
					<span class="creatorname"> {autor}</span>
				</div>
				<span> | </span>
				<span class="creationdate">{date}</span>
			</div>
			<div>
				<h4>{labels.lblPrompt}</h4>
				<p class="prompt">{input}</p>
			</div>
			{/* <div key={id} className="PromptSwipeable"> */}
			{/* <div className="row-1">{promptname}</div>
						{marked === 1 ? (
							<img
								className="bookmark"
								onClick={onMarkItem}
								src={BookmarkOn}
							/>
						) : (
							<img
								className="bookmark"
								onClick={onMarkItem}
								src={BookmarkOff}
							/>
						)} */}
			{/* <div className="row-2">
							<span className="text-title">{labels.lblPromptInput}: </span>
							<div className="text-container text-container-2rows">
								{input}
							</div>
						</div>
						<div className="row-3">
							<span className="text-title">{labels.lblAIOutput}:</span>
							<div className="text-container text-container-3rows">
								{output}
							</div>
						</div> */}

			<div class="subindex">
				<span>{userBereich}</span>
				<span> {filtered2[0]?.eigenschaftName==undefined? '|':filtered2[0]?.eigenschaftName} </span>
				{/* <span>Subcategory</span>
				<span> | </span>
				<span>Tags</span>
				<span> | </span> */}
				<Link to={`/ergebnis/${id}`}>
					<a>{labels.lblFullView} {'>'}</a>
				</Link>
			</div>
			{/* <div className="row-4">
				<span> {autor}</span>
				<span> {userBereich}</span>
				<span>{date}</span> */}
{/* 
				<Link to={`/ergebnis/${id}`}>
					<span>
						{labels.lblFullView}{" "}

					</span>
				</Link>
			</div> */}
		</div>
	);
	return (
		// PromptItem als swipeable Item
		<div className="PromptSwipeable">
			<SwipeableList fullSwipe={true}>
				<SwipeableListItem
					leadingActions={leadingActions()}
					trailingActions={trailingActions()}
				>
					<div key={id} className="PromptSwipeable">
						<div className="row-1">{promptname}</div>
						{marked === 1 ? (
							<img
								className="bookmark"
								onClick={onMarkItem}
								src={BookmarkOn}
							/>
						) : (
							<img
								className="bookmark"
								onClick={onMarkItem}
								src={BookmarkOff}
							/>
						)}
						<div className="row-2">
							<span className="text-title">{labels.lblPromptInput}: </span>
							<div className="text-container text-container-2rows">
								{input}
							</div>
						</div>
						<div className="row-3">
							<span className="text-title">{labels.lblAIOutput}:</span>
							<div className="text-container text-container-3rows">
								{output}
							</div>
						</div>
						<div className="row-4">
							<span> {autor}</span>
							<span> {userBereich}</span>
							<span>{date}</span>

							<Link to={`/ergebnis/${id}`}>
								<span>
									{labels.lblFullView}{" "}
									<img className="next" src={Next} />
								</span>
							</Link>
						</div>
					</div>
				</SwipeableListItem>
			</SwipeableList>
		</div>
	);
};

export default PromptItem;
