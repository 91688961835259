import LogoImage from "../images/Promptothek.png";

import padlock from "../images/padlock.png"
import profileUser from "../images/profile-user.png"
import firmenpunkt from "../images/firmenpunkt_logo.svg"
import circlelogo from "../images/CircleLogo.png"

import { callBackend, useSharedData } from "../SharedDataContext";
import React, { useEffect, useState } from "react";
import Wave from "../images/wave.png"
import { useNavigate } from "react-router-dom";

const LogoutPage = () => {
	const navigate = useNavigate();
    const moveToLogin =()=>{
        navigate('/')
    }
    const canceln=()=>{
        document.getElementById('logout').classList.add('noDisplay')
    }
	return (
		<div id="logout" class="logout-body noDisplay">
            <div class="logout-box">
                <img src={Wave} alt="wave" class="wave"/>
                <p>Are you sure you want to log out?</p>
                <div class="logout-buttons">
                    <button class="stay" onClick={canceln}><a>NO</a></button>
                    <button class="leave" onClick={moveToLogin}><a>YES</a></button>
                </div>
            </div>
        </div>
	);
};
export default LogoutPage;
