import LogoImage from "../images/Promptothek.png";
import React from "react";

import { useNavigate } from "react-router-dom";

const Abmelden = () => {
	const navigate = useNavigate();

	const backToLogin = () => {
		navigate("/");
	};

	return (
		<div className="LoginSeite">

			<div className="Logo_login">
				<img id="Logo" name="Logo" src={LogoImage} alt="" srcSet="" />
			</div>
			<div>
                <button onClick={backToLogin}>Wieder Anmelden</button>
            </div>
		</div>
		
	);
};
export default Abmelden;
