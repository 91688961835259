import EigenschaftItem from './EigenschaftItem'

import { useState, useEffect } from 'react'

const EigenschaftenList = ({ eigenschaftenWert, setEigenschaftenWert, openAddEigenschaft }) => {
  const [EigenschaftenList, setEigenschaftenList] = useState([])
  useEffect(() => {

    if (eigenschaftenWert.length > 0) {

      setEigenschaftenList(
        eigenschaftenWert.map((item,key) => (
       
          <EigenschaftItem
            key={key}
            id={item.id}
            name={item.eigenschaftName}
            eigenschaftenWert={eigenschaftenWert}
            setEigenschaftenWert={setEigenschaftenWert}
            openAddEigenschaft={openAddEigenschaft}
            // fkDataPromptCategoryID={fkDataPromptCategoryID}
            // fkDataPromptSubCategoryID={fkDataPromptSubCategoryID}
            isChangeable={true}
          />
        ))
      )
    } else {
      setEigenschaftenList([])
    }
  }, [eigenschaftenWert])

  if (eigenschaftenWert.length === 0) {
    return <h2>-</h2>
  }
  return (
    <>
    {/* <div> */}
      {/* <ul> */}
        {EigenschaftenList}
        {/* </ul> */}
    {/* </div> */}
    </>
  )
}
export default EigenschaftenList
