import React, { useEffect, useState } from "react";
import {  useSharedData } from "../SharedDataContext";
import BereichItem from "./BereichItem";

const BereicheList = (props) => {
	const { departments, setDepartments, loggedInUser,callBackend } = useSharedData();
	const [divisions, setDivisions] = useState([]);
	const [newDivision, setNewDivision] = useState({
		strDepartment: "",
		fkCompanyID: loggedInUser.fkCompanyID,
	});
	const { labels, setLabels } = useSharedData();
	const [newField, setNewField] = useState(false);
	const showNewField = () => {
		if (newField) {
			setNewField(false);
		} else {
			setNewField(true);
		}
	};

	useEffect(() => {
		if (loggedInUser) {
			const newList = departments.filter(
				(u) => u.fkCompanyID === loggedInUser.fkCompanyID
			);
			setDivisions(newList);
			setNewDivision({
				strDepartment: "",
				fkCompanyID: loggedInUser.fkCompanyID,
			});
		}
	}, []);

	const addDivision = () => {
		//ToDo: prevent Duplicates?
		if (newDivision.strDepartment) {
			callBackend({ action: "save_department", payload: newDivision })
				.then((rst) => {
					if (rst.serverAction === "database_insert_success") {
						const division = {
							...newDivision,
							pkDepartmentID: rst.pkDepartmentID,
							datTsControl: rst.datTsControl,
						};
						setDivisions([...divisions, division]);
						setDepartments([...departments, division]);
						setNewDivision({
							strDepartment: "",
							fkCompanyID: loggedInUser.fkCompanyID,
						});
					} else {
						console.log(rst);
					}
				})
				.catch((err) => console.log(err));
		}
		showNewField(false);
	};

	const handleNameChange = (e) => {
		setNewDivision({ ...newDivision, strDepartment: e.target.value });
	};

	const handleDeleteClick = (id) => {
		callBackend({
			action: "delete_department",
			payload: { pkDepartmentID: id },
		})
			.then((rst) => {
				if (rst.serverAction === "database_deletion_success") {
					const updatedDivisions = departments.filter(
						(item) => item.pkDepartmentID !== id
					);
					setDepartments(updatedDivisions);
					const newList = updatedDivisions.filter(
						(u) => u.fkCompanyID === loggedInUser.fkCompanyID
					);

					setDivisions(newList);
				} else {
					console.log(rst);
				}
			})
			.catch((err) => console.log(err));
	};

	const bereichitems = divisions.map((item, index) => (
		<BereichItem
			index={index}
			key={`department_${index}`}
			id={item.pkDepartmentID}
			name={item.strDepartment}
			date={item.datTsControl.split(" ")[0]}
			onDelete={handleDeleteClick.bind(null, item.pkDepartmentID)}
		/>
	));

	return (
		<>
			{newField === false && divisions.length > 0 && (
				<>
					<hr></hr>
					<table class="cd table centered">
						<thead>
							<tr>
								<th class="table-title" colspan="2">{labels.lblDepartment}</th>
							</tr>
						</thead>

						<tbody>
							<tr class="row">
								<th>{labels.lblDepartment}</th>
								<th className={loggedInUser.fkDataRoleID > 1?"td-middle":''}>{labels.lblCreationDate}</th>
								{loggedInUser.fkDataRoleID > 1&&(
									<th>
										<p class="NewDepartment" >
											<span class=" curserPointer" onClick={showNewField}>
												{newField === true ? "-" : "+"} {labels.lblNew}
											</span>{" "}

										</p>
									</th>
								)}

							</tr>
							{bereichitems}
						</tbody>
					</table>
				</>
			)}

			{newField === false && divisions.length == 0 && (
				<>
					<hr></hr>
					<table class="cd table centered">
						<thead>
							<tr>
								<th class="table-title" colspan="2">{labels.lblDepartment}</th>
							</tr>
						</thead>

						<tbody>
							<tr class="row">
								<th>{labels.lblDepartment}</th>
								<th className={loggedInUser.fkDataRoleID > 1?"td-middle":''}>{labels.lblCreationDate}</th>
								{loggedInUser.fkDataRoleID > 1&&(
									<th>
										<p class="NewDepartment" >
											<span class=" curserPointer" onClick={showNewField}>
												{newField === true ? "-" : "+"} {labels.lblNew}
											</span>{" "}

										</p>
									</th>
								)}

							</tr>
							{labels.lblNoData} 
							{/* {bereichitems} */}
						</tbody>
					</table>
				</>
			)}

			{loggedInUser.fkDataRoleID > 1 && newField === true && (

				<>
					<hr></hr>
					<table class="cd table centered">
						<thead>
							<tr>
								<th class="table-title" colspan="2">{labels.lblNewDepartment}</th>
							</tr>
						</thead>

						<tbody>
							<tr class="row">
								<th>{labels.lblNewDepartment}</th>
								<th>
									<p class="NewDepartment" >
										<span class=" curserPointer" onClick={showNewField}>
											{newField === true ? "-" : "+"} {labels.lblNew}
										</span>{" "}

									</p>
								</th>
							</tr>
							<tr class="row">
								<td>
									<input
										class="department-input"
										placeholder="Name"
										type="text"
										value={newDivision.strDepartment}
										onChange={handleNameChange}
									/>
								</td>
								<td class="departmentButton">
									<button onClick={showNewField}>{labels.lblCancel}</button>
									<button onClick={addDivision}>{labels.lblSave}</button>

								</td>
							</tr>
							<tr class="row"></tr>
						

						</tbody>
					</table>

				</>
			)}
		</>

	);

	return (
		<div className="firmendatenDiv">
			{divisions.length > 0 && (
				<>
					{loggedInUser.fkDataRoleID > 1 && (
						<table>
							<thead>
								<tr
									className="BereichHeader"
									onClick={showNewField}
								>
									<td>
										<p>
											<span>
												{newField === true ? "-" : "+"}
											</span>{" "}
											Neu
										</p>
									</td>
								</tr>
							</thead>
						</table>
					)}
					{newField === false && (
						<table className="BenutzerList">
							<thead>
								<tr>
									<th>N</th>
									<th>{labels.lblDepartment}</th>
									<th>{labels.lblCreationDate}</th>
									{loggedInUser.fkDataRoleID > 1 && (
										<th>Actions</th>
									)}
								</tr>
							</thead>
							<tbody>{bereichitems}</tbody>
						</table>
					)}
				</>
			)}
			{divisions.length === 0 && (
				<table>
					<tbody>
						<tr>
							<td>
								<span>{labels.lblNoData}</span>
							</td>
						</tr>
					</tbody>
				</table>
			)}
			{loggedInUser.fkDataRoleID > 1 && newField === true && (
				<div className="neueBereichDiv">
					<div>
						<h3>{labels.lblNewDepartment}</h3>
						{/* <label>Name:</label> */}
						<input
							placeholder="Name"
							type="text"
							value={newDivision.strDepartment}
							onChange={handleNameChange}
						/>
					</div>
					<button onClick={addDivision}>{labels.lblChange}</button>
					<button onClick={showNewField}>{labels.lblSave}</button>
				</div>
			)}
		</div>
	);
};

export default BereicheList;
