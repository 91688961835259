import React, { useState } from "react";

import { useSharedData } from "../SharedDataContext";
import swal from "sweetalert";

const BenutzerInfoItem = (props) => {
	const { arrOptions, boolEdit, strEditType, strValueID, value, label, userID, EditEmployees = false } = props;
	const { labels, setLabels } = useSharedData();
	const [isEditing, setIsEditing] = useState(false);
	const [editedValue, setEditedValue] = useState(value);
	const { loggedInUser } = useSharedData();

	const {
		users,
		setUsers,
		setLoggedInUser,
		userDepartments,
		setUserDepartments,
		callBackend,
	} = useSharedData();
	const options = Array.isArray(arrOptions) ? arrOptions : [];
	const currentUser = users.find((user) => user.pkUserID === userID);

	const handleEditClick = () => {
		if (isEditing) {
			if (
				strValueID === "strUserName" &&
				users.find(
					(user) =>
						user.strUserName === editedValue &&
						user.pkUserID !== userID
				)
			) {
				swal(labels.lblUserExist);
				return;
			}
			if (
				strValueID === "fkDataRoleID" &&
				loggedInUser.pkUserID === userID &&
				loggedInUser.fkDataRoleID === 3 &&
				editedValue < 3
			) {
				if (
					users.filter(
						(user) =>
							user.fkDataRoleID > 2 &&
							!(loggedInUser.pkUserID === user.pkUserID)
					).length
				) {
					if (
						!window.confirm(
							labels.lblChageAdminStatus1
							// "Administrator-Status aufgeben?\nDiese Aktion kann nicht rückgängig gemacht werden!"
						)
					) {
						return;
					}
				} else {
					swal(
						labels.lblChageAdminStatus2
						// "Administrator-Status kann derzeit nicht aufgegeben werden!\nEs muss mindestens ein Account mit Administrationsrechten existieren!"
					);
					return;
				}
			}
			if (strEditType !== "multiselect") {
				const formattedValue = /^(pk|fk|int)/.test(strValueID)
					? Number(editedValue)
					: editedValue;

				callBackend({
					action: "update_user",
					payload: {
						pkUserID: userID,
						[strValueID]: formattedValue,
					},
				}).catch((err) => console.log(err));

				setUsers(
					users.map((user) =>
						user.pkUserID === userID
							? { ...user, [strValueID]: formattedValue }
							: user
					)
				);
				if (userID == loggedInUser.pkUserID) {
					setLoggedInUser((prior) => ({
						...prior,
						[strValueID]: formattedValue,
					}));
				}

				if (
					strValueID === "intMainDepartment" &&
					!userDepartments.find(
						(dep) =>
							dep.fkDepartmentID === formattedValue &&
							dep.fkUserID === currentUser.pkUserID
					)
				) {
					callBackend({
						action: "save_user_department",
						payload: {
							fkDepartmentID: formattedValue,
							fkUserID: userID,
						},
					}).catch((err) => console.log(err));
					setUserDepartments([
						...userDepartments,
						{ fkDepartmentID: formattedValue, fkUserID: userID },
					]);
				}
			} else {
				const newValue = editedValue.includes(
					currentUser.intMainDepartment
				)
					? editedValue
					: [...editedValue, currentUser.intMainDepartment];
				const arrDel = userDepartments
					.filter(
						(obj) =>
							!newValue.includes(obj.fkDepartmentID) &&
							obj.fkUserID === userID
					)
					.map((obj) => obj.fkDepartmentID);
				const arrAdd = newValue.filter(
					(obj) =>
						!userDepartments
							.filter((entry) => entry.fkUserID === userID)
							.map((entry) => entry.fkDepartmentID)
							.includes(obj)
				);

				arrDel.forEach((departmentID) =>
					callBackend({
						action: "delete_user_department",
						payload: {
							fkDepartmentID: departmentID,
							fkUserID: userID,
						},
					}).catch((err) => console.log(err))
				);
				arrAdd.forEach((departmentID) =>
					callBackend({
						action: "save_user_department",
						payload: {
							fkDepartmentID: departmentID,
							fkUserID: userID,
						},
					}).catch((err) => console.log(err))
				);

				setUserDepartments((prior) => {
					let arrRet = prior.filter(
						(obj) =>
							editedValue.includes(obj.fkDepartmentID) ||
							obj.fkUserID !== userID
					);

					return [
						...arrRet,
						...arrAdd.map((obj) => ({
							fkDepartmentID: obj,
							fkUserID: userID,
						})),
					];
				});
			}
		}
		setIsEditing(!isEditing);
	};

	const handleInputChange = (e) => {
		setEditedValue(e.target.value);
	};

	const handleCheckboxSelect = (e) => {
		if (e.target.checked) {
			setEditedValue([...editedValue, Number(e.target.value)]);
		} else if (e.target.value !== currentUser.intMainDepartment) {
			setEditedValue(
				editedValue.filter((val) => val !== Number(e.target.value))
			);
		}
	};

	const objEditTypes = {
		multiselect: (

			<>
				<span className="selectSpan">
					<div className="editDiv">
						{options.map((option, key) => (
							<div
								className="editDivValue"
								key={`${strValueID}_${key}`}
							>
								<input
									type="checkbox"
									className="BereichCheckBox"
									name={`${strValueID}_${key}`}
									value={option.value}
									onChange={handleCheckboxSelect}
									disabled={Array.isArray(value)
										? option.value ===
										currentUser.intMainDepartment
										: false}
									checked={
										Array.isArray(value)
											? editedValue.includes(option.value) ||
											option.value ===
											currentUser.intMainDepartment
											: false
									}
								/>
								<label className="editDivLabel" htmlFor={`${strValueID}_${key}`}>
									{option.name}
								</label>
							</div>
						))}
					</div>
				</span>
			</>

		),
		select: (
			<td>
			<select
				className="td-select"
				name={strValueID}
				onChange={handleInputChange}
				value={editedValue}
			>
				{options.map((opt, key) => (
					<option key={`${strValueID}_${key}`} value={opt.value}>
						{opt.name}
					</option>
				))}
			</select>
			</td>
		),
		text: (
			<input
				type="text"
				value={editedValue}
				onChange={handleInputChange}
			/>
		),
	};

	const displayValue =
		strEditType === "select"
			? options.find((option) => option.value == editedValue)?.name
			: strEditType === "multiselect"
				? options
					.filter((option) => value.includes(option.value) || currentUser.fkDataRoleID > 1)
					.map((option) => option.name)
					.join(", ")
				: editedValue;

	return (
		<tr className="row">
			<th>{label}</th>
			{isEditing ? (
				objEditTypes[strEditType]
			) : (
				<>
					<td className="tbOverflow"><span className="rowSpanWidth">{displayValue}</span></td>
				</>
			)}
			{(boolEdit &&
				!(strEditType ===
					"multiselect" && currentUser.fkDataRoleID > 1) &&
				strEditType !== "disabled") || EditEmployees ? (
				<td className="td-button">
					<button onClick={handleEditClick} className="table-button">
						<a>{isEditing ? labels.lblSave : labels.lblChange}</a>
					</button>
				</td>
			) : (
				<td className="td-button">
				</td>
			)}
		</tr>
	);

	return (
		<tr>
			<td className="td1">{label}</td>
			<td className="td2">
				<div>
					<div>
						{isEditing ? (
							objEditTypes[strEditType]
						) : (
							<span>{displayValue}</span>
						)}
						{((loggedInUser?.fkDataRoleID > 2 ||
							(strValueID !== "fkDataRoleID" &&
								loggedInUser?.fkDataRoleID > 1)) &&
							!(strEditType ===
								"multiselect" && currentUser.fkDataRoleID > 1) &&
							strEditType !== "disabled") || EditEmployees ? (
							<button onClick={handleEditClick}>
								{isEditing ? labels.lblSave : labels.lblChange}
							</button>
						) : (
							<div
								style={{
									display: "inline-block",
									width: "1px",
									height: "35px",
								}}
							/>
						)}
					</div>
				</div>
			</td>
		</tr>
	);
};

export default BenutzerInfoItem;
