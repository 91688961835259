import React, { useState } from "react";
import { useSharedData } from "../SharedDataContext";
import swal from "sweetalert";

import img_upload from "../images/upload-photo-svgrepo-com.svg";

const FirmLogoUpload = (props) => {
	const { firms, setFirms, formatValues, loggedInUser, labels, callBackend } = useSharedData();
	let strCustomLogo = firms?.filter(
		(elm) => elm.pkCompanyID === loggedInUser.fkCompanyID
	)[0]?.strCompanyLogo;
	const [strImgUrl, setImgUrl] = useState(strCustomLogo);

	const chageLogo=()=>{
		if (strImgUrl) {
			callBackend({
				action: "update_firm",
				payload: {
					pkCompanyID: loggedInUser.fkCompanyID,
					strCompanyLogo: strImgUrl,
				},
			}).then(() => {

				callBackend({ action: "get_firms" })
					.then((res) => {
						strCustomLogo = strImgUrl
						setFirms(formatValues(res));
					})
					.catch((err) => console.log(err));
			})


		}
	}


	const blnIsAdmin = loggedInUser.fkDataRoleID > 1;
	if (blnIsAdmin) {
		return (
			<>
			<div 
				id="uploadDiv"

				onClick={(clickEvent) => {
					document.getElementById("companyLogoInput")?.click();
				}}
				onDrop={(dropEvent) => {
					dropEvent.preventDefault();
							const objReader = new FileReader();
							objReader.addEventListener("load", () => {
								setImgUrl(objReader.result);
							});

							let objFile = dropEvent.dataTransfer.items[0].getAsFile();
							if(objFile){
								objReader.readAsDataURL(objFile);
							} else {
								const strURL = dropEvent.dataTransfer.getData('url')
								if(/^data:image\/\S{3,};base64,/.test(strURL)){
									setImgUrl(strURL);
								} else {
									fetch(strURL).then((response)=>{
										objReader.readAsDataURL(response.blob);
									}).catch((err)=>{
										swal(labels.lblRequireLocalUpload)
									})
								}
								console.log(strURL);
							}
				}}
				onDragOver={(dragOverEvent) => {
					dragOverEvent.preventDefault();
				}}
			>
				<input
					type="file"
					id="companyLogoInput"
					name="companyLogoInput"
					onChange={(e) => {
						e.preventDefault();
						const arrFiles = e.target.files || [],
							objReader = new FileReader();
						objReader.addEventListener("load", () => {
							setImgUrl(objReader.result);
						});
						if (arrFiles[0])
							objReader.readAsDataURL(arrFiles[0]);
					}}
					style={{ display: "none" }}
				/>
				<img
					id="companyLogo"
					src={strImgUrl || img_upload}
					alt=""
					style={
						strImgUrl
							? { maxWidth: "200px", maxHeight: "100px" }
							: {
									maxHeight: "5em",
									maxWidth: "100%",
								}
						}
					/>

				</div>
				<button className="logoOutputButton" onClick={chageLogo}>{labels.lblSave}</button>
			</>
		);
	} else {
		return <>
			<img
				id="companyLogo"
				src={strImgUrl || img_upload}
				style={
					strImgUrl
						? { maxWidth: "200px", maxHeight: "100px" }
						: {
							maxHeight: "5em",
							maxWidth: "100%",
						}
				}
			/>
		</>;
	}
};

export default FirmLogoUpload;
