import React, { useState } from "react";
import { useSharedData } from "../SharedDataContext";
import { Label } from "@mui/icons-material";

const BereichItem = (props) => {
	const { departments, setDepartments, loggedInUser, callBackend } = useSharedData();
	const value = props.name;
	const [isEditing, setIsEditing] = useState(false);
	const [editedValue, setEditedValue] = useState(value);
	const handleEditClick = () => {
		setIsEditing(!isEditing);
	};
	const { labels, setLabels } = useSharedData();
	const handleSaveEditClick = () => {
		callBackend({
			action: "update_department",
			payload: {
				pkDepartmentID: props.id,
				strDepartment: editedValue,
			},
		})
			.then((rst) => {
				setDepartments(
					departments.map((obj) =>
						obj.pkDepartmentID === props.id
							? { ...obj, strDepartment: editedValue }
							: obj
					)
				);
				setIsEditing(false);
			})
			.catch((err) => console.log(err));
	};
	const handleInputChange = (e) => {
		setEditedValue(e.target.value);
	};

	return (
		<tr className="row">
			<td>
				{isEditing ? (
					<div>
						<input
							class="department-input"
							type="text"
							value={editedValue}
							onChange={handleInputChange}
						/>
					</div>
				) : (
					<div>
						<span>{editedValue || value}</span>
					</div>
				)}
			</td>
			<td className={loggedInUser.fkDataRoleID > 1?"td-middle":''}>{props.date}</td>
			{loggedInUser.fkDataRoleID > 1 && (
				<td class="departmentButton">
					{isEditing ? (
						<button type="button" onClick={handleSaveEditClick}>
							{labels.lblSave}
						</button>
					) : (
						<button type="button" onClick={handleEditClick}>
							{labels.lblChange}
						</button>
					)}
					<button type="button" onClick={props.onDelete}>
						{labels.lblDelete}
					</button>
				</td>
			)}
		</tr>
	);

	return (
		<tr>
			<td>{props.index}</td>
			<td>
				{isEditing ? (
					<div>
						<input
							type="text"
							value={editedValue}
							onChange={handleInputChange}
						/>
					</div>
				) : (
					<div>
						<span>{editedValue || value}</span>
					</div>
				)}
			</td>
			<td>{props.date}</td>
			{loggedInUser.fkDataRoleID > 1 && (
				<td style={{ width: "430px", textAlign: "center" }}>
					{isEditing ? (
						<button type="button" onClick={handleSaveEditClick}>
							{labels.lblSave}
						</button>
					) : (
						<button type="button" onClick={handleEditClick}>
							{labels.lblChange}
						</button>
					)}
					<button type="button" onClick={props.onDelete}>
						{labels.lblDelete}
					</button>
				</td>
			)}
		</tr>
	);
};

export default BereichItem;
