import React, { useState, useEffect } from "react";
import FilterInput from "./FilterInput";
import Checkbox from "./Checkbox";
import { useSharedData } from "../SharedDataContext";
import { useNavigate } from 'react-router'
import FilterSvg from "../images/filter.svg"
const PromptFilter = (props) => {
	const [checkbox1, setCheckbox1] = useState(false);
	const [checkbox2, setCheckbox2] = useState(false);
	const [checkbox3, setCheckbox3] = useState(true);
	const [promptusers, setPromptusers] = useState([]);
	const { labels, setLabels } = useSharedData();

	const {
		prompts,
		setPrompts,
		tags,
		setTags,
		setMarkiertePrompts,
		promptCategories,
		promptTags,
		setPromptTags,
		callBackend,
		kategorieList,
		unterkategorieList,
	} = useSharedData();

	let usedTags = tags;
	let updateTags = promptTags;
	let updateTagsID = updateTags.map(item => item.fkDataPromptTagID);
	usedTags = tags.filter((item) =>
		updateTagsID.includes(item.pkDataPromptTagID)
	)



	const [promptbereiche, setPromptbereiche] = useState([]);
	const [filterCategory, setFilterCategory] = useState([]);
	const [filterCategoryActive, setFilterCategoryActive] = useState(false);
	const [filterTag, setFilterTag] = useState([]);
	const [filterTagActive, setFilterTagActive] = useState(false);

	const [filterTemplate, setFilterTemplate] = useState([]);
	const [filterTemplateActive, setFilterTemplateActive] = useState(false);

	const {
		departments,
		userDepartments,
		markiertePrompts,
		promptDepartments,
		users,
		loggedInUser,
	} = useSharedData();
	const filteredprompts = props.filteredprompts;
	const [filterText, setFilterText] = useState("");
	const navigate = useNavigate()
	useEffect(() => {
		if (!loggedInUser) {
			navigate('/');
		} else {
			const bereicheIDs =
				loggedInUser.fkDataRoleID < 2
					? userDepartments
						.filter((elm) => elm.fkUserID === loggedInUser.pkUserID)
						.map((elm) => elm.fkDepartmentID)
					: departments
						.filter(
							(dep) =>
								dep.fkCompanyID === loggedInUser.fkCompanyID
						)
						.map((elm) => elm.pkDepartmentID);

			const userIDs = userDepartments
				.filter((elm) => bereicheIDs.includes(elm.fkDepartmentID))
				.map((elm) => elm.fkUserID)
				.reduce(
					(acc, elm) => (acc.includes(elm) ? acc : [...acc, elm]),
					[]
				);

			for (const k in filteredprompts) {
				if (!userIDs.includes(filteredprompts[k].fkUserID)) {
					userIDs.push(filteredprompts[k].fkUserID);
				}
			}
			const userList = [];
			for (const id of userIDs) {
				const item = users.find((dataItem) => dataItem.pkUserID === id);
				if (item) {
					let name = "";
					name = item.strSurName + " " + item.strFirstName;
					userList.push({ id: id, userName: name, checked: false });
				}
			}
			userList.push({ id: 0, userName: labels.lblAll, checked: true });
			const bereicheList = [];
			bereicheList.push({ id: 0, bereichName: labels.lblAll, checked: true });
			for (const id of bereicheIDs) {
				const item = departments.find(
					(dataItem) => dataItem.pkDepartmentID === id
				);
				if (item) {
					let name = "";
					name = item.strDepartment;
					bereicheList.push({
						id: id,
						bereichName: name,
						checked: false,
					});
				}
			}
			// bereicheList.push({ id: 0, bereichName: labels.lblAll, checked: true });
			setPromptbereiche(bereicheList);
			setPromptusers(userList);
		}
	}, [filteredprompts]);

	const handleCheckbox1Change = () => {
		setCheckbox3(true)
		setCheckbox1(false)
		setCheckbox2(false)
	};
	const handleCheckbox2Change = () => {
		setCheckbox3(false)
		setCheckbox1(true)
		setCheckbox2(false)
	};
	const handleCheckbox3Change = () => {
		setCheckbox3(false)
		setCheckbox1(false)
		setCheckbox2(true)
	};
	function filterBereichHandler() {
		const checkeddataIDs = promptbereiche
			.filter((item) => item.checked === true)
			.map((item) => item.id);

		let filtereddata = [];
		if (!(checkeddataIDs.length === 1 && checkeddataIDs[0] === 0)) {
			filtereddata = filteredprompts.filter((elm) =>
				promptDepartments
					.filter((elm2) => elm.pkPromptID === elm2.fkPromptID)
					.map((elm3) => elm3.fkDepartmentID)
					.some((elm4) => checkeddataIDs.includes(elm4))
			);
		} else {
			filtereddata =
				loggedInUser.fkDataRoleID > 1
					? filteredprompts
					: filteredprompts.filter((elm) =>
						promptDepartments
							.filter(
								(elm2) => elm.pkPromptID === elm2.fkPromptID
							)
							.map((elm3) => elm3.fkDepartmentID)
							.some((elm4) =>
								promptbereiche
									.map((elm) => elm.id)
									.includes(elm4)
							)
					);
		}

		return filtereddata;
	}
	function filterBenutzerHandler() {
		const checkeddataIDs = promptusers
			.filter((item) => item.checked === true)
			.map((item) => item.id);

		let filtereddata = filteredprompts;
		if (!(checkeddataIDs.length === 1 && checkeddataIDs[0] === 0)) {
			filtereddata = filteredprompts.filter((item) =>
				checkeddataIDs.includes(item.fkUserID)
			);
		}

		return filtereddata;
	}
	// filter for Marked
	function filterMarkedHandler() {
		let newprompts = filteredprompts;

		let filtereddata;
		if (checkbox1 === true && checkbox2 === false) {
			//get ids for marked
			const markeddata = markiertePrompts.filter(
				(item) => item.fkUserID === loggedInUser.pkUserID
			);
			const markeddataIDs = [];
			for (const k in markeddata) {
				markeddataIDs.push(markeddata[k].fkPromptID);
			}
			//prompts
			filtereddata = newprompts.filter((item) =>
				markeddataIDs.includes(item.pkPromptID)
			);
		}

		if (checkbox1 === false && checkbox2 === true) {
			//get ids for marked
			const markeddata = markiertePrompts.filter(
				(item) => item.fkUserID === loggedInUser.pkUserID
			);
			const markeddataIDs = [];
			for (const k in markeddata) {
				markeddataIDs.push(markeddata[k].fkPromptID);
			}

			filtereddata = newprompts.filter(
				(item) => !markeddataIDs.includes(item.pkPromptID)
			);
		}
		if ((checkbox1 === true && checkbox2 === true) || checkbox3 === true) {
			filtereddata = newprompts; //
		}
		return filtereddata;
	}

	function filterInputTextHandler(value) {
		let filtereddata = filteredprompts;
		// Function to filter the text items based on the filter text
		filtereddata = filteredprompts.filter((item) =>
			item.txtImput.toLowerCase().includes(value.toLowerCase())
		);

		const pkPromptIDs = filtereddata.map((item) => item.pkPromptID);
		return pkPromptIDs;
	}

	function filterOutputTextHandler(value) {

		let filtereddata = filteredprompts;
		// Function to filter the text items based on the filter text
		filtereddata = filteredprompts.filter((item) =>
			item.txtOutput.toLowerCase().includes(value.toLowerCase())
		);

		const pkPromptIDs = filtereddata.map((item) => item.pkPromptID);

		return pkPromptIDs;
	}

	function filterTagsTextHandler(value) {

		let filtereddata = filteredprompts;
		let filteredTags = tags;
		let filteredPromptTag = promptTags;

		// Function to filter the text items based on the filter text
		filteredTags = tags.filter((item) =>
			item.strDataPromptTag.toLowerCase().includes(value.toLowerCase())
		);
		let filteredTagsID = filteredTags.map(item => item.pkDataPromptTagID);
		filteredPromptTag = promptTags.filter((item) =>
			filteredTagsID.includes(item.fkDataPromptTagID)
		)
		let filteredPromptTagID = filteredPromptTag.map(item => item.fkPromptID);
		filtereddata = filteredprompts.filter((item) =>
			filteredPromptTagID.includes(item.pkPromptID)
		)
		const pkPromptIDs = filtereddata.map((item) => item.pkPromptID);
		return pkPromptIDs;
	}

	function filterCategoryTextHandler(value) {
		let filtereddata = filteredprompts;
		let filteredCategories = promptCategories;
		let filteredSubcategories = promptCategories;
		let filteredKategorie = kategorieList;
		let filteredUnterkategorie = unterkategorieList;

		filteredKategorie = kategorieList.filter((item) =>
			item.strPromptCategoryName.toLowerCase().includes(value.toLowerCase())
		);
		let filteredKategorieID = filteredKategorie.map((item) => item.pkDataPromptCategoryID);

		filteredUnterkategorie = unterkategorieList.filter((item) =>
			item.strPromptSubCategoryName.toLowerCase().includes(value.toLowerCase())
		);
		let filteredUnterkategorieID = filteredUnterkategorie.map((item) => item.pkDataPromptSubCategoryID);
		filteredCategories = promptCategories.filter((item) =>
			filteredKategorieID.includes(item.fkDataPromptCategoryID)
		)
		let filteredCategoriesID = filteredCategories.map((item) => item.fkPromptID);

		filteredSubcategories = promptCategories.filter((item) =>
			filteredUnterkategorieID.includes(item.fkDataPromptSubCategoryID)
		)

		let filteredSubcategoriesID = filteredSubcategories.map((item) => item.fkPromptID);

		let IDs = filteredCategoriesID.concat(filteredSubcategoriesID)

		// Function to filter the text items based on the filter text
		filtereddata = filteredprompts.filter((item) =>
			IDs.includes(item.pkPromptID)
		)
		// filtereddata = filteredprompts.filter((item) =>
		// 	item.txtOutput.toLowerCase().includes(value.toLowerCase())
		// );
		const pkPromptIDs = filtereddata.map((item) => item.pkPromptID);
		return pkPromptIDs;
	}


	function filterTitleTextHandler(value) {
		let filtereddata = filteredprompts;
		// Function to filter the text items based on the filter text
		filtereddata = filteredprompts.filter((item) =>
			item.strPromptName.toLowerCase().includes(value.toLowerCase())
		);

		const pkPromptIDs = filtereddata.map((item) => item.pkPromptID);

		return pkPromptIDs;
	}

	function filterCommentTextHandler(value) {
		let filtereddata = filteredprompts;
		// Function to filter the text items based on the filter text
		filtereddata = filteredprompts.filter((item) =>
			item.txtComment.toLowerCase().includes(value.toLowerCase())
		);
		const pkPromptIDs = filtereddata.map((item) => item.pkPromptID);

		return pkPromptIDs;
	}

	useEffect(() => {
		let filtereddata;

		let filteredMarked = filterMarkedHandler();
		let filteredBereiche = filterBereichHandler();
		let filteredBenutzer = filterBenutzerHandler();
		const lfdPromptIds1 = filteredMarked.map((item) => item.pkPromptID);
		const lfdPromptIds2 = filteredBereiche.map((item) => item.pkPromptID);
		const lfdPromptIds3 = filteredBenutzer.map((item) => item.pkPromptID);


		const commonIDs3 = [
			...new Set(
				lfdPromptIds1.filter((id) => lfdPromptIds2.includes(id))
			),
		];
		let commonIDs4 = commonIDs3

		if (filterTagActive) {
			commonIDs4 = [
				...new Set(
					commonIDs3.filter((id) => filterTag.includes(id))
				),
			];
		}
		let commonIDs5 = commonIDs4

		if(filterTemplateActive){
			commonIDs5 = [
				...new Set(
					commonIDs4.filter((id) => filterTemplate.includes(id))
				),
			];
		}

		let commonIDs = commonIDs5
		if (filterCategoryActive) {
			commonIDs = [
				...new Set(
					commonIDs5.filter((id) => filterCategory.includes(id))
				),
			];
		}


		if (commonIDs && commonIDs.length === 0) {
			filtereddata = [];
		} else {
			const commonIDs2 = [
				...new Set(
					lfdPromptIds3.filter((id) => commonIDs.includes(id))
				),
			];

			filtereddata = filteredprompts.filter((item) =>
				commonIDs2.includes(item.pkPromptID)
			);

			if (commonIDs2 && commonIDs2.length === 0) {
			} else if (filterText != "") {
				const set1 =
					// variableValues.variable1
					// 	? 
					new Set(filterTitleTextHandler(filterText))
				// : [];
				const set2 =
					// variableValues.variable2
					// 	? 
					new Set(filterInputTextHandler(filterText))
				// : [];
				const set3 =
					// variableValues.variable3
					// 	? 
					new Set(filterOutputTextHandler(filterText))
				// : [];
				const set4 = new Set(filterTagsTextHandler(filterText))

				const set5 = new Set(filterCategoryTextHandler(filterText))

				// Merge all arrays and convert to a Set and back to an array to return the list of unique IDs
				// Filter according to the remaining filter selections
				const commonIDs3 = [
					...new Set([...set1, ...set2, ...set3, ...set4, ...set5]),
				].filter((id) => commonIDs2.includes(id));

				filtereddata = filteredprompts.filter((item) =>
					commonIDs3.includes(item.pkPromptID)
				);
			}
		}

		//let newdata=filterMarkedHandler()

		let allebereiche = false;
		let allebenutzer = false;
		if (promptbereiche.length > 0) {
			let checkeddata = promptbereiche.filter(
				(item) => item.checked === true
			);
			if (checkeddata.length === 1 && checkeddata[0].id === 0) {
				//alle bereiche
				allebereiche = true;
			}
		}

		if (promptusers.length > 0) {
			let checkeddata = promptusers.filter(
				(item) => item.checked === true
			);
			if (checkeddata.length === 1 && checkeddata[0].id === 0) {
				//alle bereiche
				allebenutzer = true;
			}
		}

		if (allebereiche === true && allebenutzer === true) {
		} else {
		}
		props.setFilteredprompts(filtereddata);
	}, [
		checkbox1,
		checkbox2,
		checkbox3,
		filterText,
		promptusers,
		promptbereiche,
		filterCategory,
		filterTag,
		filterTemplate,
		// variableValues,
	]);

	const handleUserChange = (item) => {
		const id = item;

		const updatedArray = promptusers.map((item) => {
			if (item.id === id) {
				return { ...item, checked: !item.checked };
			}
			return item;
		});
		// Find the index of the item with id=0
		if (id === 0) {
			const index = updatedArray.findIndex((item) => item.id === 0);
			if (updatedArray[index].checked) {
				updatedArray.forEach((item) => {
					if (item.id !== 0) {
						item.checked = false;
					}
				});
			}
		} else {
			const indexid = updatedArray.findIndex((item) => item.id === id);
			if (updatedArray[indexid].checked) {
				updatedArray.forEach((item) => {
					if (item.id === 0) {
						item.checked = false;
					}
				});
			}
		}

		const checkedItems = updatedArray.filter(
			(item) => item.checked === true
		);
		if (checkedItems.length === 0) {
			updatedArray.forEach((item) => {
				if (item.id === 0) {
					item.checked = true;
				}
			});
		}

		setPromptusers(updatedArray);
	};

	const handleHighlightedChange = (ID) => {
		if (ID == 1) {
			handleCheckbox1Change()
		} else if (ID == 2) {
			handleCheckbox2Change()
		} else if (ID == 3) {
			handleCheckbox3Change()
		}
	}

	const handleBereichChange = (item) => {
		const id = Number(item);
		const updatedArray = promptbereiche.map((item) => {
			if (item.id === id) {
				return { ...item, checked: true };
			}
			return { ...item, checked: false };
		});
		// Find the index of the item with id=0
		if (id === 0) {
			const index = updatedArray.findIndex((item) => item.id === 0);
			if (updatedArray[index].checked) {
				updatedArray.forEach((item) => {
					if (item.id !== 0) {
						item.checked = false;
					}
				});
			}
		} else {
			const indexid = updatedArray.findIndex((item) => item.id === id);
			if (updatedArray[indexid].checked) {
				updatedArray.forEach((item) => {
					if (item.id === 0) {
						item.checked = false;
					}
				});
			}
		}

		const checkedItems = updatedArray.filter(
			(item) => item.checked === true
		);
		if (checkedItems.length === 0) {
			updatedArray.forEach((item) => {
				if (item.id === 0) {
					item.checked = true;
				}
			});
		}
		setPromptbereiche(updatedArray);
	};
	const handleCategoryChange = (ID) => {

		let filteredKategorie = kategorieList
		let filteredCategories = promptCategories
		let filtereddata = filteredprompts;
		if (ID != 'All') {
			filteredKategorie = kategorieList.filter((item) =>
				item.pkDataPromptCategoryID == Number(ID)
			);
			let filteredKategorieID = filteredKategorie.map((item) => item.pkDataPromptCategoryID);

			filteredCategories = promptCategories.filter((item) =>
				filteredKategorieID.includes(item.fkDataPromptCategoryID)
			)
			let filteredCategoriesID = filteredCategories.map((item) => item.fkPromptID);

			filtereddata = filteredprompts.filter((item) =>
				filteredCategoriesID.includes(item.pkPromptID)
			)
		}

		// filtereddata = filteredprompts.filter((item) =>
		// 	item.txtOutput.toLowerCase().includes(value.toLowerCase())
		// );
		const pkPromptIDs = filtereddata.map((item) => item.pkPromptID);

		setFilterCategoryActive(true)

		setFilterCategory(pkPromptIDs)
	}

	const handleTagChange = (ID) => {
		let filtereddata = filteredprompts;
		let filteredTags = tags;
		let filteredPromptTag = promptTags;
		if (ID != 'All') {
			// Function to filter the text items based on the filter text
			filteredTags = tags.filter((item) =>
				item.pkDataPromptTagID == Number(ID)
			);
			let filteredTagsID = filteredTags.map(item => item.pkDataPromptTagID);
			filteredPromptTag = promptTags.filter((item) =>
				filteredTagsID.includes(item.fkDataPromptTagID)
			)
			let filteredPromptTagID = filteredPromptTag.map(item => item.fkPromptID);
			filtereddata = filteredprompts.filter((item) =>
				filteredPromptTagID.includes(item.pkPromptID)
			)
		}
		const pkPromptIDs = filtereddata.map((item) => item.pkPromptID);
		setFilterTagActive(true)
		setFilterTag(pkPromptIDs)
	}

	const handleTemplateChange = (ID) => {
		let filtereddata = filteredprompts;
		let filteredTags = tags;
		let filteredPromptTag = promptTags;
		if (ID != 'All') {
			// Function to filter the text items based on the filter text
			filtereddata = filteredprompts.filter((item) =>
				item.intTemplate == Number(ID)
			);
			// let filteredTagsID = filteredTags.map(item => item.pkDataPromptTagID);
			// filteredPromptTag = promptTags.filter((item) =>
			// 	filteredTagsID.includes(item.fkDataPromptTagID)
			// )
			// let filteredPromptTagID = filteredPromptTag.map(item => item.fkPromptID);
			// filtereddata = filteredprompts.filter((item) =>
			// 	filteredPromptTagID.includes(item.pkPromptID)
			// )
		}
		const pkPromptIDs = filtereddata.map((item) => item.pkPromptID);
		setFilterTemplateActive(true)
		setFilterTemplate(pkPromptIDs)
	}

	// const handleVariableChange = (variableName, value) => {
	// 	setVariableValues((prevValues) => ({
	// 		...prevValues,
	// 		[variableName]: value,
	// 	}));
	// };
	return (

		<form class="library-filters">
			{/* <div className="search"> */}
			<div class="medium-screen">
				<div className="topnav">
					<FilterInput
						filterText={filterText}
						onFilterChange={setFilterText}
					/>
				</div>
				{/* <button class="hamburger-menu">
					<img src={FilterSvg} alt="filter icon" class="filter-icon" />
				</button> */}
			</div>

			<div class="filter">
				<label for="category">{labels.lblCategory}:</label>
				<select onChange={(e) => handleCategoryChange(e.target.value)} name="category" >
					<option value='All' selected>{labels.lblAll}</option>
					{kategorieList.map((item, index) => (
						<option key={index} value={item.pkDataPromptCategoryID} >{item.strPromptCategoryName}</option>
					))}
				</select>
			</div>

			<div class="filter">
				<label for="category">{labels.lblTags}:</label>
				<select onChange={(e) => handleTagChange(e.target.value)} name="category" >
					<option>{labels.lblAll}</option>
					{usedTags.map((item, index) => (
						<option key={index} value={item.pkDataPromptTagID} >{item.strDataPromptTag}</option>
					))}
				</select>
			</div>

			<div class="filter">
				<label for="category">{labels.lblFilterDepartment}:</label>
				<select onChange={(e) => handleBereichChange(e.target.value)} name="category" >
					{promptbereiche.map((item, index) => (
						<option key={index} value={item.id} >{item.bereichName}</option>
					))}
				</select>
			</div>

			{/* <div class="filter">
				<label for="category">{labels.lblFilterDepartment}:</label>
				<select onChange={(e) => handleBereichChange(e.target.value)} name="category" id="category">
					{promptbereiche.map((item, index) => (
						<option key={index} value={item.id} >{item.bereichName}</option>
					))}
				</select>
			</div> */}
			{/* <div class="filter">
                <label for="category">Category:</label>
                <select name="category" id="category">
				{promptbereiche.map((item, index) => (
					<option value="betriebsmanager">{item.bereichName}</option>
				))}
                </select>
            </div>
			<div class="filter">
                <label for="category">Tag:</label>
                <select name="category" id="category">
				{promptbereiche.map((item, index) => (
					<option value="betriebsmanager">{item.bereichName}</option>
				))}
                </select>
            </div> */}
			<div class="filter">
				<label for="category">{labels.lblHighlighted}:</label>
				<select onChange={(e) => handleHighlightedChange(e.target.value)}>
					{/* {promptbereiche.map((item, index) => ( */}
					<option value={1}>{labels.lblAll}</option>
					<option value={2}>{labels.lblHighlighted}</option>
					<option value={3}>{labels.lblNoHighlighted}</option>

					{/* ))} */}
				</select>
			</div>

			<div class="filter">
				<label for="category">{labels.lblTemplate}:</label>
				<select onChange={(e) => handleTemplateChange(e.target.value)}>
					{/* {promptbereiche.map((item, index) => ( */}
					<option value={'All'}>{labels.lblAll}</option>
					<option value={1}>{labels.lblYes}</option>
					<option value={0}>{labels.lblNo}</option>

					{/* ))} */}
				</select>
			</div>

			{/* <div className="filterBereiche">
				<h3>{labels.lblFilterDepartment}:</h3>
				{promptbereiche.map((item, index) => (
					<Checkbox
						key={index}
						label={item.bereichName}
						checked={item.checked}
						onChange={handleBereichChange.bind(null, item)}
					/>
				))}
			</div>
			
				<div className="filterBereiche">
					<h3>{labels.lblFilterMark}:</h3>
					<Checkbox
						label={labels.lblMarked}
						checked={checkbox1}
						onChange={handleCheckbox1Change}
					/>
					<Checkbox
						label={labels.lblNoMark}
						checked={checkbox2}
						onChange={handleCheckbox2Change}
					/>
					<Checkbox
						label={labels.lblAll}
						checked={checkbox3}
						onChange={handleCheckbox3Change}
					/>
				</div> */}

		</form>
	);
	return (
		<div className="search">
			<div className="FilterDiv">
				<FilterInput
					filterText={filterText}
					onFilterChange={setFilterText}
				// variableValues={variableValues}
				// onVariableChange={handleVariableChange}
				/>
				<br />
				<br />
				<div className="filterBereiche">
					<h3>{labels.lblFilterMark}:</h3>
					<Checkbox
						label={labels.lblMarked}
						checked={checkbox1}
						onChange={handleCheckbox1Change}
					/>
					<Checkbox
						label={labels.lblNoMark}
						checked={checkbox2}
						onChange={handleCheckbox2Change}
					/>
					<Checkbox
						label={labels.lblAll}
						checked={checkbox3}
						onChange={handleCheckbox3Change}
					/>
				</div>
				<br />
				<br />
				<div className="filterBereiche">
					<h3>{labels.lblFilterDepartment}:</h3>
					{promptbereiche.map((item, index) => (
						<Checkbox
							key={index}
							label={item.bereichName}
							checked={item.checked}
							onChange={handleBereichChange.bind(null, item)}
						/>
					))}
				</div>
				<br />
				<br />
				{/* <div className="filterBereiche">
					<h3>{labels.lblFilterDepartment}:</h3>
					{promptbereiche.map((item, index) => (
						<Checkbox
							key={index}
							label={item.bereichName}
							checked={item.checked}
							onChange={handleBereichChange.bind(null, item)}
						/>
					))}
				</div> */}
				{/* <div className="filterBereiche">
					<h3>{labels.lblFilterUser}:</h3>
					{promptusers.map((item, index) => (
						<Checkbox
							key={index}
							checked={item.checked}
							label={item.userName}
							onChange={handleUserChange.bind(null, item)}
						/>
					))}
				</div> */}
				{/* <br />
				<br />
				<div className="filterBereiche">
					<h3>Kategorie:</h3>
					{promptusers.map((item, index) => (
						<Checkbox
							key={index}
							checked={item.checked}
							label={item.userName}
							onChange={handleUserChange.bind(null, item)}
						/>
					))}
				</div> */}
			</div>
		</div>
	);
};

export default PromptFilter;
