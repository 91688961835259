import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NeueKategorie from "./NeueKategorie";
import NeueUnterkategorie from "./NeueUnterkategorie";
import EigenschaftenList from "./EigenschaftenList";
import { useSharedData } from "../../SharedDataContext";
import { GrDuplicate } from "react-icons/gr";
import swal from "sweetalert";

const KategorienComponent = ({
	promptID,
	eigenschaftenWert,
	setEigenschaftenWert,
	openAddEigenschaft = false,
	addEigenschschaft
}) => {
	const [ausgewaehlteKategorie, setAusgewaehlteKategorie] = useState("");
	const [ausgewaehlteUnterkategorie, setAusgewaehlteUnterKategorie] =
		useState("");
	const { kategorieList, setKategorieList, callBackend } = useSharedData();
	const { unterkategorieList, setUnterkategorieList } = useSharedData();
	const { loggedInUser, promptCategories, setPromptCategories } =
		useSharedData();
	const [ausgewaehlteList, setAusgewaehlteList] = useState([]);
	const location = useLocation();
	const ShowContainer = location.pathname === "/promptspeichern" || location.pathname === "/prompterstellen/option2" || location.pathname === "/prompterstellen/option3"

	const { labels, setLabels } = useSharedData();

	const addKategorieHandler = (neueKategorie) => {
		callBackend({
			action: "save_category",
			payload: {
				strPromptCategoryName: neueKategorie,
			},
		}).then((rst) => {
			setKategorieList((prevData) => {
				return [
					...prevData,
					{
						pkDataPromptCategoryID: rst.pkDataPromptCategoryID,
						strPromptCategoryName: neueKategorie,
						// intStatus: 1, // ToDo: vom Server zurückgeben?
						// datTsControl: rst.datTsControl // ToDo: vom Server zurückgeben?
					},
				];
			});
		});
	};

	const addUnterkategorieHandler = (neueUnterkategorie) => {
		//KatId
		const kategorie = kategorieList.find(
			(kategorie) =>
				kategorie.strPromptCategoryName === ausgewaehlteKategorie
		);
		if (kategorie) {
			callBackend({
				action: "save_subcategory",
				payload: {
					strPromptSubCategoryName: neueUnterkategorie,
					fkDataPromptCategoryID: kategorie.pkDataPromptCategoryID,
				},
			}).then((rst) => {
				setUnterkategorieList((prevData) => {
					return [
						...prevData,
						{
							pkDataPromptSubCategoryID:
								rst.pkDataPromptSubCategoryID,
							strPromptSubCategoryName: neueUnterkategorie,
							fkDataPromptCategoryID:
								kategorie.pkDataPromptCategoryID,
							// intStatus: 1, // ToDo: vom Server zurückgeben?
							// datTsControl: rst.datTsControl
						},
					];
				});
				setAusgewaehlteList((prevData) => {
					return [
						...prevData,
						{
							pkDataPromptSubCategoryID:
								rst.pkDataPromptSubCategoryID,
							strPromptSubCategoryName: neueUnterkategorie,
							fkDataPromptCategoryID:
								kategorie.pkDataPromptCategoryID,
							// intStatus: 1, // ToDo: vom Server zurückgeben?
							// datTsControl: rst.datTsControl
						},
					];
				});
			});
		}
	};

	useEffect(() => {
		const kategorie = kategorieList.find(
			(kategorie) =>
				kategorie.strPromptCategoryName === ausgewaehlteKategorie
		);
		if (kategorie) {
			const filtered = unterkategorieList.filter(
				(item) =>
					item.fkDataPromptCategoryID ===
					kategorie.pkDataPromptCategoryID
			);
			setAusgewaehlteList(filtered);
		}
	}, [unterkategorieList, ausgewaehlteKategorie]);

	const handleKategorieChange = (event) => {
		setAusgewaehlteKategorie(event.target.value);
		if (event.target.value != "none") {
			const ausgewaehlteKategorieID = kategorieList.find(
				(item) => item.strPromptCategoryName === event.target.value
			).id;
			const filtered = unterkategorieList.filter(
				(item) =>
					item.fkDataPromptCategoryID === ausgewaehlteKategorieID
			);
			setAusgewaehlteList(filtered);
			setAusgewaehlteUnterKategorie("");
		}
		{
			setAusgewaehlteUnterKategorie("");
		}
	};

	const handleUnterkategorieChange = (event) => {
		setAusgewaehlteUnterKategorie(event.target.value);
	};

	const clickAddEigenschaftenHandler = (event) => {
		event.preventDefault();
		const ausgewaehlteKategorieID = kategorieList.find(
			(item) => item.strPromptCategoryName === ausgewaehlteKategorie
		).pkDataPromptCategoryID;
		let ausgewaehlteUnterkategorieID = -10
		if(ausgewaehlteUnterkategorie){
			ausgewaehlteUnterkategorieID = ausgewaehlteList
			.filter(
				(item) =>
					item.fkDataPromptCategoryID === ausgewaehlteKategorieID
			)
			.find(
				(item) =>
					item.strPromptSubCategoryName === ausgewaehlteUnterkategorie
			).pkDataPromptSubCategoryID;
		}
		

		let newEigenschaft = {
			fkPromptID: promptID || 0,
			eigenschaftName:
			ausgewaehlteUnterkategorieID==-10?
				ausgewaehlteKategorie: ausgewaehlteKategorie + ": " + ausgewaehlteUnterkategorie,
			fkDataPromptCategoryID: ausgewaehlteKategorieID,
			fkDataPromptSubCategoryID: ausgewaehlteUnterkategorieID,
		};
		let duplicate = false;
		const checkForDuplicate = (obj) => {
			return (
				eigenschaftenWert.reduce(
					(acc, obj1) =>
						acc ||
						(obj1.fkDataPromptCategoryID ===
							obj.fkDataPromptCategoryID &&
							obj1.fkDataPromptSubCategoryID ===
							obj.fkDataPromptSubCategoryID),
					false
				) ||
				promptCategories.reduce(
					(acc, obj1) =>
						acc ||
						(obj1.fkPromptID === promptID &&
							obj1.fkDataPromptCategoryID ===
							obj.fkDataPromptCategoryID &&
							obj1.fkDataPromptSubCategoryID ===
							obj.fkDataPromptSubCategoryID),
					false
				)
			);
		};
		duplicate = checkForDuplicate(newEigenschaft);

		if (!duplicate) {
			setEigenschaftenWert([...eigenschaftenWert, newEigenschaft]);
			if (!ShowContainer) {
				callBackend({
					action: "save_prompt_category",
					payload: {
						fkDataPromptCategoryID: ausgewaehlteKategorieID,
						fkDataPromptSubCategoryID: ausgewaehlteUnterkategorieID,
						fkPromptID: promptID,
						fkUserID: loggedInUser.pkUserID,
					},
				}).then((rst) => {
					setPromptCategories([
						...promptCategories,
						{
							pkPromptCategoryID: rst.pkPromptCategoryID,
							fkDataPromptCategoryID: ausgewaehlteKategorieID,
							fkDataPromptSubCategoryID:
								ausgewaehlteUnterkategorieID,
							fkPromptID: promptID,
							fkUserID: loggedInUser.pkUserID,
						},
					]);
				});
			}
			if(openAddEigenschaft){
				addEigenschschaft()
			}
		} else {
			swal(
				`${labels.lblDuplicateCategory1} '${newEigenschaft.eigenschaftName}' ${labels.lblDuplicateCategory2}`
			);
		}
		
	};

	return (
		<>
			{/* <div className="kategorien"> */}
			<div className="category">
				<label>{labels.lblCategory}:</label>
				<select
					id="kategorie"
					onChange={handleKategorieChange}
					value={ausgewaehlteKategorie}
				>
					<option value="none">-- {labels.lblPleaseChoose} --</option>
					{kategorieList.map((k) => (
						<option
							key={k.pkDataPromptCategoryID}
							value={k.strPromptCategoryName}
						>
							{k.strPromptCategoryName}
						</option>
					))}
				</select>
				{/* <NeueKategorie
					onAddKategorie={addKategorieHandler}
					kategorieList={kategorieList}
				/> */}
			</div>
			{ausgewaehlteKategorie && (
				<div className="subcategory">
					<label>{labels.lblSubcategory}:</label>
					{ausgewaehlteList && ausgewaehlteList.length > 0 && (
						<div>
							<select
								id="unterkategorie"
								value={ausgewaehlteUnterkategorie}
								onChange={handleUnterkategorieChange}
							>
								<option value="">-- {labels.lblPleaseChoose} --</option>
								{ausgewaehlteList.map((optionObj) => (
									<option
										key={`unterkategorie_${optionObj.pkDataPromptSubCategoryID}`}
										value={
											optionObj.strPromptSubCategoryName
										}
									>
										{optionObj.strPromptSubCategoryName}
									</option>
								))}
							</select>
						</div>
					)}
					{/* <NeueUnterkategorie
						onAddUnterkategorie={addUnterkategorieHandler}
						ausgewaehlteKategorie={ausgewaehlteKategorie}
						kategorieList={kategorieList}
						unterkategorieList={unterkategorieList}
					/> */}

					{ausgewaehlteKategorie != "none" &&
						// ausgewaehlteUnterkategorie != "" && 
						(
							// < className="buttons">

							<button class="add-category add-view-category" onClick={clickAddEigenschaftenHandler}>
								{labels.lblAddCategory}
							</button>

						)}

					{ShowContainer === true ? (
						// <div className="Eigenschaften-container">
						eigenschaftenWert.length > 0 && (
							<>
								{/* <h3>{labels.lblCategory}</h3> */}
								<EigenschaftenList
									openAddEigenschaft={openAddEigenschaft}
									eigenschaftenWert={eigenschaftenWert}
									setEigenschaftenWert={setEigenschaftenWert}
								/>
							</>
						)
						// </div>
					) : (
						""
					)}

				</div>
			)}


			{/* </div> */}
		</>
	);
};
export default KategorienComponent;
