import React from "react";
import { useSharedData } from "../SharedDataContext";

const InputRow = ({ strRowLabel, arrInputs, fnOnChange, rows }) => {
	const {labels, setLabels} = useSharedData();
	const onChange = (e) => {
		fnOnChange({ strID: e.target.name, value: e.target.value });
	};

	return (
		<>
			<div class="labelquestion">
				<label for="title">{strRowLabel}</label>
				{/* <span>?</span> */}
			</div>
			{/* <div className="chat-row-container"> */}
				{/* <label>{strRowLabel}</label> */}
				{arrInputs.map((input) =>
					input.strType === "select" ? (
						<select
							key={input.strVariableName}
							name={input.strVariableName}
							value={input.value}
							onChange={onChange}
						>
							<option value={0}>-{labels.lblPleaseChoose}-</option>
							{input.options?.map((option) => (
								<option
									key={`${input.strVariableName}_${option.value}`}
									value={option.value}
								>
									{option.strName}
								</option>
							))}
						</select>
					) : (
						// <>
						<textarea
							type={input.strType}
							key={input.strVariableName}
							name={input.strVariableName}
							placeholder={input.strPlaceholder}
							value={input.value}
							onChange={onChange}
							cols="30" rows={rows}
						>
						</textarea>
						// {/* <input
						// 	type={input.strType}
						// 	key={input.strVariableName}
						// 	name={input.strVariableName}
						// 	placeholder={input.strPlaceholder}
						// 	value={input.value}
						// 	onChange={onChange}
						// />
						// </> */}
					)
				)}
			{/* </div> */}
		</>
	);
};

export default InputRow;
