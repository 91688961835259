import PromptItem from "./PromptItem";
import { useState } from "react";
import { useSharedData } from "../SharedDataContext";

const PromptList = (props) => {
	const [sortedByDate, setSortedByDate] = useState(false);
	const { prompts, setPrompts, users } = useSharedData();
	const {labels, setLabels} = useSharedData();
	const sortByDate = () => {
		const sortedData = [...prompts];
		if (sortedByDate) {
			sortedData.sort((a, b) => new Date(b.Date) - new Date(a.Date));
		} else {
			sortedData.sort((a, b) => new Date(a.Date) - new Date(b.Date));
		}
		setPrompts(sortedData);
		setSortedByDate(!sortedByDate);
	};

	return (
		<div class="all-prompts">
		<div class="row-prompt">
			{/* Prompts SwipeableList*/}
			{props.filteredprompts.length === 0 && <div> {labels.lblNoData} </div>}
			{props.filteredprompts.map((item, index) => (
				<PromptItem
					index={index}
					key={`prompt_${item.pkPromptID}`}
					id={item.pkPromptID}
					input={item.txtImput}
					output={item.txtOutput}
					name={item.strPromptName}
					comment={item.txtComment}
					type={item.Type}
					fkUserID={item.fkUserID}
					date={item.datCreate}
					tagIDs={item.TagIDs}
					setTags={props.setTags}
					loggedInUser={props.loggedInUser}
					template={item.intTemplate}
				/>
			))}
		</div>
		</div>
	);
};

export default PromptList;
