import React, { useState, useEffect } from "react";
import Checkbox from "./Checkbox";
import { useSharedData } from "../SharedDataContext";
import SearchSvg from "../images/search.svg";

const FilterInput = (props) => {
	const [enteredData, setEnteredData] = useState("");
	// const [checkbox1, setCheckbox1] = useState(props.variableValues.variable1);
	// const [checkbox2, setCheckbox2] = useState(props.variableValues.variable2);
	// const [checkbox3, setCheckbox3] = useState(props.variableValues.variable3);
	const {labels, setLabels} = useSharedData();
	// useEffect(() => {
	// 	props.onVariableChange("variable1", checkbox1);
	// 	props.onVariableChange("variable2", checkbox2);
	// 	props.onVariableChange("variable3", checkbox3);
	// }, [checkbox1, checkbox2, checkbox3]);

	const dataChangeHandler = (event) => {
		setEnteredData(event.target.value);
		setTimeout(() => {
			props.onFilterChange(event.target.value);
		}, 500);
	};

	// const handleCheckbox1Change = () => {
	// 	setCheckbox1(!checkbox1);
	// };

	// const handleCheckbox2Change = () => {
	// 	setCheckbox2(!checkbox2);
	// };

	// const handleCheckbox3Change = () => {
	// 	setCheckbox3(!checkbox3);
	// };

	return (
		<div class="search-container">
		<label>{labels.lblSearch}:</label><br></br>
			<input
			    placeholder=""
				type="text"
				value={enteredData}
				onChange={dataChangeHandler}
				
			/>
			<button type="button"><img src={SearchSvg} alt="search icon" class="search-icon"/></button>
			{/* <Checkbox
				label={labels.lblTitle}
				checked={checkbox1}
				onChange={handleCheckbox1Change}
			/>
			<Checkbox
				label={labels.lblPromptInput}
				checked={checkbox2}
				onChange={handleCheckbox2Change}
			/>
			<Checkbox
				label={labels.lblAIOutput}
				checked={checkbox3}
				onChange={handleCheckbox3Change}
			/> */}
		</div>
	);
};
export default FilterInput;
