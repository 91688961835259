import React, { useReducer } from "react";
import swal from "sweetalert";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
	
	formatValues,
	useSharedData,
	useUser,
} from "../SharedDataContext";
import { Link, useLocation } from "react-router-dom";

import ChatArtDropdown from "../component/ChatArtDropdown";
import ChatComponent from "../component/ChatComponentProcedural";
import ChatDropdown from "../component/ChatDropdown";
import Header from "../component/Header";
import DepartmentComponent from "../component/Departments/DepartmentComponent";
import KategorienComponent from "../component/Kategorien_Unterkategorien_Eigenschaften/KategorienComponent";
import TagsComponent from "../component/Tags/TagsComponent";
import SeitenListe from "../component/SeitenListe";
import Footer from "../component/Footer";
import Up from "../images/up.svg"

const PromptErstellen = () => {
	const { labels, setLabels, callBackend } = useSharedData();
	const navigate = useNavigate();

	const location = useLocation();
	let option = location.pathname.split("/")[2]
	// if(option==labels.lblSimplePrompt){
	// 	option='option2'
	// }else if(option==labels.lblSocialmediaPrompt){
	// 	option="option3"
	// }else if(option==labels.lblImagePrompt){
	// 	option='option4'
	// }
	// if(location.pathname!=='prompterstellen'){
	// 	if(1==omg){
	// 		test='option1'
	// 	}else if(2==omg){
	// 		test='option2'
	// 	}
	// }
	// setwebChatType(omg)

	const [ergebnisWert, setErgebnisWert] = useState("");
	const [noteValue, setNoteValue] = useState("");
	const [titleValue, setTitleValue] = useState("");

	const [eigenschaftenWert, setEigenschaftenWert] = useState([]);
	const [departmentWert, setDepartmentWert] = useState([]);
	const [tagsList, setTagsList] = useState([]);
	const [blnReload, setReload] = useReducer((prior) => (prior + 1) % 2, 0)

	const { tags, setTags, promptTags, setPromptTags } = useSharedData();
	const { prompts, setPrompts, promptData, setPromptData } = useSharedData();
	const { setPromptDepartments, setPromptCategories } = useSharedData();
	const { setKategorieList, setUnterkategorieList } = useSharedData();
	const [active, setActive] = useState(true)
	const [showSave, setShowSave] = useState(false)

	const { loggedInUser } = useUser();
	

	const [blnTemplete, setTemplete] = useState(false);


	useEffect(() => {
		if (!loggedInUser) {
			navigate("/");
		} else {
			callBackend({ action: "get_categories", payload: { fkDataLanguageID: loggedInUser.fkDataLanguageID } })
				.then((res) => {
					setKategorieList(formatValues(res));
				})
				.catch((err) => console.log(err));

			callBackend({ action: "get_subcategories", payload: { fkDataLanguageID: loggedInUser.fkDataLanguageID } })
				.then((res) => {
					setUnterkategorieList(formatValues(res));
				})
				.catch((err) => console.log(err));

			callBackend({ action: "get_tags" })
				.then((res) => {
					setTags(formatValues(res));
				})
				.catch((err) => console.log(err));
		}
	}, [loggedInUser]);

	const pageName = {
		name: "Erfassung",
		link1: "/",
		link2: "/",
		hide1: "yes",
		hide2: "no",
		userId: 0,
	};

	// const openNotizDialog = () => {
	// 	swal("Möchten Sie eine Notiz hinzugügen?", {
	// 		buttons: {
	// 			cancel: "Abbrechen",
	// 			confirm: true,
	// 		},
	// 		content: "input",
	// 	}).then((notizValue) => {
	// 		if (notizValue != undefined) {
	// 			addTitelDialog(notizValue);
	// 		}
	// 	});
	// };
	// const addTitelDialog = (notizValue) => {
	// 	swal("Geben Sie bitte einen Titel ein!", {
	// 		buttons: {
	// 			cancel: "Abbrechen",
	// 			confirm: true,
	// 		},
	// 		content: "input",
	// 	}).then((titelValue) => {
	// 		if (titelValue != undefined) {
	// 			if (titelValue.trim() === "") {
	// 				noTitle(notizValue);
	// 			} else {
	// 				swal(
	// 					`Das Prompt wurde mit Titel ${titelValue} hinzugefügt gespeichert.`
	// 				).then(() => {
	// 					addPrompt(notizValue, titelValue);
	// 				});
	// 			}
	// 		}
	// 	});
	// };
	// const noTitle = (notizValue) => {
	// 	swal("Der Titel kann nicht leer sein", {
	// 		button: false,
	// 		timer: 1000,
	// 	}).then(() => addTitelDialog(notizValue));
	// };

	let promptID;

	const addPrompt = (notizValue, titelValue) => {
		let input = document.getElementById("inputText").value;
		let output = document.getElementById("outputText").textContent;
		callBackend({
			action: "save_prompt",
			payload: {
				fkUserID: loggedInUser.pkUserID,
				fkDataPromptRoleID: promptData.fkDataPromptRoleID || 0,
				fkDataPromptProvideID: promptData.fkDataPromptProvideID || 0,
				fkDataPromptOutputID: promptData.fkDataPromptOutputID || 0,
				fkDataPromptPlatformID: promptData.fkDataPromptPlatformID || 0,
				fkDataLanguageID: promptData.fkDataLanguageID || 0,
				strPromptName: titelValue,
				intPromptKind: promptData.intPromptKind || 0,
				txtSubject: promptData.txtSubject || null,
				txtImput: input,
				txtOutput: output,
				txtComment: notizValue,
				intMinLength: promptData.intMinLength || 0,
				intMaxLength: promptData.intMaxLength || 0,
				txtAudience: promptData.txtAudience || null,
				txtCallToAction: promptData.txtCallToAction || null,
				txtForm: promptData.txtForm || null,
				intEmojis: promptData.intEmojis || 0,
				intPublicPrompt: blnPublicPrompt ? 1 : 0,
				intTemplate: blnTemplete ? 1 : 0,
			},
		}).then((rst) => {
			setPrompts([
				...prompts,
				{
					pkPromptID: rst.pkPromptID,
					fkUserID: loggedInUser.pkUserID,
					fkDataPromptRoleID: promptData.fkDataPromptRoleID || 0,
					fkDataPromptProvideID:
						promptData.fkDataPromptProvideID || 0,
					fkDataPromptOutputID: promptData.fkDataPromptOutputID || 0,
					fkDataPromptPlatformID:
						promptData.fkDataPromptPlatformID || 0,
					fkDataLanguageID: promptData.fkDataLanguageID || 0,
					strPromptName: titelValue,
					intPromptKind: promptData.intPromptKind || 0,
					txtSubject: promptData.txtSubject || null,
					txtImput: input,
					txtOutput: output,
					txtComment: notizValue,
					intMinLength: promptData.intMinLength || 0,
					intMaxLength: promptData.intMaxLength || 0,
					txtAudience: promptData.txtAudience || null,
					txtCallToAction: promptData.txtCallToAction || null,
					txtForm: promptData.txtForm || null,
					intEmojis: promptData.intEmojis || 0,
					datCreate: rst.datCreate,
					datTsControl: rst.datTsControl,
					intPublicPrompt: blnPublicPrompt ? 1 : 0,
					intTemplate: blnTemplete ? 1 : 0,
				},
			]);
			let date = new Date().toISOString().slice(0, 10);

			eigenschaftenWert.map((obj) => {
				callBackend({
					action: "save_prompt_category",
					payload: {
						fkUserID: loggedInUser.pkUserID,
						fkDepartmentID: obj.fkDepartmentID,
						fkPromptID: rst.pkPromptID,
						fkDataPromptCategoryID: obj.fkDataPromptCategoryID,
						fkDataPromptSubCategoryID:
							obj.fkDataPromptSubCategoryID,
					},
				}).then((rst2) => {
					setPromptCategories((oldCategories) => [
						...oldCategories,
						{
							pkPromptCategoryID: rst2.pkPromptCategoryID || 0,
							fkUserID: loggedInUser.pkUserID,
							fkDepartmentID: obj.fkDepartmentID,
							fkPromptID: rst.pkPromptID,
							fkDataPromptCategoryID:
								obj.fkDataPromptCategoryID || 0,
							fkDataPromptSubCategoryID:
								obj.fkDataPromptSubCategoryID || 0,
							// intStatus: 1, // ToDo: vom Server zurückgeben?
							// datTsControl: rst.datTsControl
						},
					]);
				});
			});

			if (departmentWert.length) {
				departmentWert.map((obj) => {
					callBackend({
						action: "save_prompt_department",
						payload: {
							fkDepartmentID: obj.fkDepartmentID,
							fkPromptID: rst.pkPromptID,
						},
					}).then((rst2) => {
						setPromptDepartments((oldCategories) => [
							...oldCategories,
							{
								fkDepartmentID: obj.fkDepartmentID,
								fkPromptID: rst.pkPromptID,
								// intStatus: 1, // ToDo: vom Server zurückgeben?
								// datTsControl: rst.datTsControl
							},
						]);
					});
				});
			} else {
				callBackend({
					action: "save_prompt_department",
					payload: {
						fkDepartmentID: loggedInUser.intMainDepartment,
						fkPromptID: rst.pkPromptID,
					},
				}).then((rst2) => {
					setPromptDepartments((oldCategories) => [
						...oldCategories,
						{
							fkDepartmentID: loggedInUser.intMainDepartment,
							fkPromptID: rst.pkPromptID,
						},
					]);
				});
			}

			tagsList.forEach((obj) => {
				callBackend({
					action: "save_prompt_tag",
					payload: {
						fkPromptID: rst.pkPromptID,
						fkDataPromptTagID: obj.pkDataPromptTagID,
					},
				});
			});
			const newPromptTags = tagsList.map((obj) => ({
				fkPromptID: rst.pkPromptID,
				fkDataPromptTagID: obj.pkDataPromptTagID,
				intStatus: 1,
				datTsControl: date,
			}));
			setPromptTags([...promptTags, ...newPromptTags]);
		});
		setNoteValue("");
		setTitleValue("");
		setErgebnisWert("");
		setEigenschaftenWert([]);
		setDepartmentWert([]);
		setTagsList([]);
		setReload();
		document.getElementById("inputText").value = "";
		setPromptData({ action: "reset_prompt" });
		setTemplete(false)
	};

	// const clickSaveButtonHandler = (event) => {
	// 	event.preventDefault();
	// 	openNotizDialog();
	// };

	const [selectedChatType, setSelectedChatType] = useState(option);
	const [selectedArt, setSelectedArt] = useState("art1");
	const [disableArt, setDisableArt] = useState(true);
	const [blnPublicPrompt, setPublicPrompt] = useState(
		loggedInUser?.fkDataRoleID < 2
	);

	const handleOptionChange = (event) => {
		const selectedOption = event.target.value;
		setSelectedChatType(selectedOption);

		setPromptData({ action: "reset_prompt" });
		//setInputText('')
		//setOutputText('')
	};

	const handleArtChange = (event) => {
		const selectedArtOption = event.target.value;
		setSelectedArt(selectedArtOption);
	};

	const clickSaveButtonHandler = () => {

		if (titleValue) {
			addPrompt(noteValue, titleValue)
			swal(labels.lblSuccessfullySaved)
		}
	};

	useEffect(() => {
		setSelectedArt("art1");
		if (selectedChatType === "option4") {
			setDisableArt(false);
		} else {
			setDisableArt(true);
		}
	}, []);

	useEffect(() => {
		if (selectedChatType === "option4") {
			setDisableArt(false);
		} else {
			setDisableArt(true);
		}
	}, [selectedChatType]);

	useEffect(() => {
		setPromptData({ action: "reset_prompt" });
	}, []);

	useEffect(() => {
		if (ergebnisWert.trim()) {
			if (titleValue.trim()) {
				setActive(false)
			} else {
				setActive(true)
			}
		} else {

			setActive(true)
		}
	}, [ergebnisWert, titleValue])

	const buttonActive = () => {
		if (active) {
			swal(labels.lblProvideOutput)
		} else {
			window.scrollTo(0, 0);
			clickSaveButtonHandler()
		}

	}


	return (
		<>
			<SeitenListe />
			<div class="prompt-body">
				<h2 class="prompt-title">{option === "option2" ? labels.lblSimplePrompt : option === "option3" ? labels.lblSocialmediaPrompt : labels.lblImagePrompt}</h2>
				<div class="prompt-content">
					<aside class="categorytags">
						{/* <form action="#"> */}
						<KategorienComponent
							key={`category_component_${blnReload}`}
							promptID={promptID}
							eigenschaftenWert={eigenschaftenWert}
							setEigenschaftenWert={setEigenschaftenWert}
						/>
						<TagsComponent
							key={`tags_component_${blnReload}`}
							tags={tags}
							setTags={setTags}
							tagsList={tagsList}
							setTagsList={setTagsList}
						/>
						{loggedInUser?.fkDataRoleID > 1 && (
							<div className="tags">
								<span>
									<label for="tags">{labels.lblTemplate}:</label>
									{/* <h3>{labels.lblTag}:</h3> */}
									<input
										key={`template_component_${blnReload}`}
										class="temp-checkBox"
										type="checkbox"
										id="blnPrivate"
										defaultChecked={blnTemplete}
										onClick={(e) => {
											setTemplete(e.target.checked);
										}}
									/>
								</span>
							</div>
						)}
						{/* </form> */}
					</aside>
					<section class="prompt-section">
						<div class="characteristics">
							{/* <form> */}
							{/* <ChatComponent
									key={`chat_component_${blnReload}`}
									setNoteValue={setNoteValue}
									noteValue={noteValue}
									setTitleValue={setTitleValue}
									titleValue={titleValue}
									ergebnisWert={ergebnisWert}
									setErgebnisWert={setErgebnisWert}
									selectedChatType={selectedChatType}
									publicPrompt={blnPublicPrompt}
								/>
								<button
									disabled={active}
									className="save"
									onClick={clickSaveButtonHandler}
								>
									{labels.lblSave}
								</button> */}

							<ChatComponent
								key={`chat_component_${blnReload}_${option}`}
								setNoteValue={setNoteValue}
								noteValue={noteValue}
								setTitleValue={setTitleValue}
								titleValue={titleValue}
								ergebnisWert={ergebnisWert}
								setErgebnisWert={setErgebnisWert}
								selectedChatType={option}
								publicPrompt={blnPublicPrompt}
								setShowSave={setShowSave}
								showSave={showSave}
							/>

							<button
								style={showSave ? { margin: "25px auto 10px auto" } : { display: "none" }}
								// disabled={active}
								className="save"
								onClick={buttonActive}
							>
								{labels.lblSave}
							</button>

							{/* </form> */}
						</div>
					</section>
				</div>
			</div>
			<Footer />
		</>
	);

	return (
		<div>
			{/* <Header items={pageName} /> */}
			<SeitenListe />
			<section className="Erfassungsseite">
				<div className="wrapper">
					<div className="SeiteForm">
						{/* <div className="OptionForm">
							<ChatDropdown
								selectedOption={selectedChatType}
								handleOptionChange={handleOptionChange}
							/>
							<ChatArtDropdown
								selectedArtOption={selectedArt}
								handleArtChange={handleArtChange}
								disabled={disableArt}
							/>
						</div> */}
						{/* <DepartmentComponent
							promptID={promptID}
							departmentWert={departmentWert}
							setDepartmentWert={setDepartmentWert}
						/> */}

						<KategorienComponent
							promptID={promptID}
							eigenschaftenWert={eigenschaftenWert}
							setEigenschaftenWert={setEigenschaftenWert}
						/>

						<TagsComponent
							tags={tags}
							setTags={setTags}
							tagsList={tagsList}
							setTagsList={setTagsList}
						/>
						{/* {loggedInUser?.fkDataRoleID > 1 && (
							<div className="private_prompt">
								<label htmlFor="blnPrivate">
									Prompt für Mitarbeiter sichtbar machen
								</label>
								<input
									type="checkbox"
									id="blnPrivate"
									defaultChecked={blnPublicPrompt}
									onClick={(e) => {
										setPublicPrompt(e.target.checked);
									}}
								/>
							</div>
						)} */}
					</div>
					<div className="HauptForm">
						<ChatComponent
							key={`chat_component_${blnReload}_${option}`}
							setNoteValue={setNoteValue}
							noteValue={noteValue}
							setTitleValue={setTitleValue}
							titleValue={titleValue}
							ergebnisWert={ergebnisWert}
							setErgebnisWert={setErgebnisWert}
							selectedChatType={option}
							publicPrompt={blnPublicPrompt}
						/>
						<div className="speicherButton">
							<button
								disabled={active}
								className="btn-right"
								onClick={clickSaveButtonHandler}
							>
								<p>{labels.lblSave}</p>
							</button>
						</div>
					</div>

				</div>
			</section>
			<Footer />
		</div>
	);
};

export default PromptErstellen;
