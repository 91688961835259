import { Fragment } from 'react'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useSharedData } from '../SharedDataContext'
import padlock from "../images/padlock.png"
import profileUser from "../images/profile-user.png"
import firmenpunkt from "../images/firmenpunkt_logo.svg"
import circlelogo from "../images/CircleLogo.png"
import LogoutPage from '../pages/Logout'



const SeitenListe = () => {
  const [activePage, setActivePage] = useState(null)
  const location = useLocation()
  const { firms, labels, setLabels, callBackend } = useSharedData();
  const { loggedInUser, setLoggedInUser } = useSharedData();
  const [activeNav, setActiveNav] = useState(false)
  const pageList = [
    { name: labels.rootPromptSave, link: '/promptspeichern', active: "promptspeichern", more: [] },
    {
      name: labels.rootPromptCreate, link: '/prompterstellen', active: "prompterstellen", more: [
        { name: labels.lblSimplePrompt, link: '/prompterstellen/option2', active: "prompterstellen" },
        { name: labels.lblSocialmediaPrompt, link: '/prompterstellen/option3', active: "prompterstellen" },
        { name: labels.lblImagePrompt, link: '/prompterstellen/option4', active: "prompterstellen" },
      ]
    },
    { name: labels.rootSearch, link: '/ergebnis', active: "ergebnis", more: [] },
    loggedInUser?.fkDataRoleID > 1?
    {
      name: labels.rootSettings, link: '/einstellung', active: "einstellung", more: [
        { name: labels.lblPersonalInformation, link: '/einstellung/0', active: "einstellung" },
        { name: labels.lblCompanyInformation, link: '/einstellung/1', active: "einstellung" },
        { name: labels.lblUserlist, link: '/einstellung/2', active: "einstellung" },
        { name: 'LOG OUT', link: '/einstellung/2', active: "einstellung" },

      ]
    }:
    {
      name: labels.rootSettings, link: '/einstellung', active: "einstellung", more: [
        { name: labels.lblPersonalInformation, link: '/einstellung/0', active: "einstellung" },
        { name: labels.lblCompanyInformation, link: '/einstellung/1', active: "einstellung" },
        { name: 'LOG OUT', link: '/einstellung/2', active: "einstellung" },

      ]
    },
  ]

  const language = (value) => {
    
    if (loggedInUser) {
      callBackend({
        action: "update_user",
        payload: {
          pkUserID: loggedInUser.pkUserID,
          'fkDataLanguageID': value,
        },
      }).catch((err) => console.log(value));
      setLoggedInUser({ ...loggedInUser, fkDataLanguageID: value })
    }

  }

  // const handlePageClick = (page) => {
  //   setActivePage(page)
  // }
  const showLogout = () => {
    closeNav()
    document.getElementById('logout').classList.remove('noDisplay')
  }
  //const currentPage = location.pathname.split('/')[1];
  const currentPage = `${location.pathname.split('/')[1]}`
  const openNav = () => {
    document.getElementById('navHamburger').classList.toggle('active')
    document.getElementById('navMenuDiv').classList.toggle('active')

  }
  const closeNav = () => {
    document.getElementById('navHamburger').classList.remove('active')
    document.getElementById('navMenuDiv').classList.remove('active')

  }
  const scrollTop = () => {
    window.scrollTo(0, 0);
  }
  // console.log(currentPage)
  // // <Link to={item.link}>{item.name}</Link>
  // console.log(pageList)
  const list = pageList.map((item) => (
    !item.more.length ?
      <li
        // class="link-style"
        key={item.name}
        onClick={closeNav}
        // className={activePage === item.name ? 'active' : ''}
        className={'nav-item'}
      >
        <Link className="nav-link link-style" key={item.name} to={item.link} onClick={scrollTop}>
          {item.name}
        </Link>
      </li>
      :
      <li
        className={'nav-item curserDefault'}
        key={item.name}
      >
        {/* {item.name} */}
        <span class="link-style noselect">{item.name}</span>
        <ul
          class="submenu"
        >
          {item.more.map((moreItem) => (
            <>{moreItem.name !== "LOG OUT" ?
              <li
                onClick={closeNav}
                key={moreItem.name}>
                <Link key={moreItem.name} class="nav-link link-style" to={moreItem.link} onClick={scrollTop}>

                  {moreItem.name}

                </Link> </li> :
              // <Link className='link-non-style' key={moreItem.name} to={moreItem.link}>
              <li
                onClick={showLogout}
                key={moreItem.name}>
                <a class="nav-item link-style curserPointer">{moreItem.name}</a>
              </li>
              // </Link>
            }
            </>
          ))}
        </ul>
      </li>
  ))

  // let strCustomLogo = firms?.filter((elm)=> elm.pkCompanyID === loggedInUser.fkCompanyID)[0]?.strCompanyLogo;

  return (
    <>
        <nav className="nav">
          <div className="first-row">
            <div className="image-logo-header ">
              <img src={firms?.filter((elm)=> elm.pkCompanyID === loggedInUser.fkCompanyID)[0]?.strCompanyLogo || firmenpunkt} alt="Promptothek Logo"></img>
            </div>
            <div class="logo curserDefault">
              <h1>PROMPTOTHEK</h1>
              {/* <h3>A LIBRARY FOR EVERYTHING</h3> */}
            </div>
            <div>
              <span onClick={() => language(1)} class='' className={loggedInUser?.fkDataLanguageID == 1 ? 'noselect language chosen curserPointer' : 'noselect language curserPointer'}>DE</span>
              <span onClick={() => language(2)} class='' className={loggedInUser?.fkDataLanguageID == 2 ? 'noselect language chosen curserPointer' : 'noselect language curserPointer'}>EN</span>
            </div>
          </div>
          <div className="navbar second-row">
            <ul className="nav-menu menu" id='navMenuDiv'>
              {list}
            </ul>
            <div className="hamburger" id='navHamburger' onClick={openNav}>
              <span className="bar"></span><span class="bar"></span><span class="bar"></span>
            </div>
          </div>

        </nav>
      <LogoutPage></LogoutPage>
    </>
  )

  return (
    <Fragment>
      <nav>
        <ul className='navigation-list'>{list}</ul>
      </nav>
    </Fragment>
  )
}
export default SeitenListe
