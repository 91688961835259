import { TiDelete } from "react-icons/ti";
import { useSharedData } from "../../SharedDataContext";
import Delete from "../../images/delete.svg"

const TagItem = ({
	tagID,
	promptID,
	name,
	isChangeable,
	tagsList,
	setTagsList,
	isFullView
}) => {
	const { tags, setTags, promptTags, setPromptTags, callBackend } =
		useSharedData();

	const handleDeleteClick = () => {
		if (setTagsList) {
			setTagsList(
				tagsList.filter((obj) => obj.fkDataPromptTagID != tagID)
			);
		} else {
			const promptTagsNew = promptTags.filter(
				(obj) =>
					obj.fkDataPromptTagID != tagID || obj.fkPromptID != promptID
			);
			setPromptTags(promptTagsNew);
			callBackend({
				action: "delete_prompt_tag",
				payload: {
					fkDataPromptTagID: tagID,
					fkPromptID: promptID,
				},
			});

			// ToDo: Tag löschen wenn nicht genutzt???
			if (promptTagsNew.every((obj) => obj.fkDataPromptTagID != tagID)) {
				setTags(tags.filter((obj) => obj.pkDataPromptTagID != tagID));
			}
		}
	};

	return (
		// <div className="TagItem">
		<div class={isFullView ? "added-tag" : "tag-added"}>
			<span>
				{name}
			</span>

			{isChangeable === true ? (
				<img onClick={handleDeleteClick} class="delete" src={Delete} alt="Delete category"></img>
				// <TiDelete
				// 	className="deleteButton"
				// 	onClick={handleDeleteClick}
				// />
			) : (
				""
			)}


		</div>
		// </div>
	);
};

export default TagItem;
