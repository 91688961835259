import React, { useState, useEffect } from "react";
import PropertiesInput from "./PropertiesInput";
import { useSharedData } from "../SharedDataContext";
const objInitialValues = {
	role: "",
	task: "",
	thema: "",
	stil: "",
	emojis: "",
	länge: {
		min: "",
		max: "",
	},
	zielgruppe: "",
	callToAction: "",
	form: "",
	sprache: "",
	format: "",
	platform: "",
	object: "",
	adjective: "",
	position: "",
	picStyle: "",
	parameter: "",
	numberImg: "",
	onlyPrompt: ""
}
const StructuralChat = ({ onChatValuesChange, selectedChatType }) => {
	const [formData, setFormData] = useState(objInitialValues);
	useEffect(()=>{
		setPromptData({action:'reset_prompt'})
	},[selectedChatType])
	

	const handleChange = (event) => {
		const { name, value } = event.target;

		if (name.includes(".")) {
			// Handle nested fields
			const [parentName, childName] = name.split(".");
			setFormData((prevState) => ({
				...prevState,
				[parentName]: {
					...prevState[parentName],
					[childName]: value,
				},
			}));
		} else {
			// Handle direct fields
			setFormData((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};
	const { labels, setLabels } = useSharedData();
	const [roleValue, setRoleValue] = useState("");
	const [selectedRoleOption, setSelectedRoleOption] =
		useState("");
	const [taskValue, setTaskValue] = useState("");
	const [selectedTaskOption, setSelectedTaskOption] =
		useState("");

	const [formatValue, setFormatValue] = useState("");
	const [selectedFormatOption, setSelectedFormatOption] =
		useState("");
	const [platformValue, setPlatformValue] = useState("");
	const [selectedPlatformOption, setSelectedPlatformOption] =
		useState("");

	const {
		promptData,
		setPromptData,
		languages,
		outputs,
		platforms,
		roles,
		styles,
		tasks,
	} = useSharedData();

	/* ToDo: Werte zuordnen:
		intPromptKind: 0,
		txtSubject: null,

	thema
	stil
	form
	*/

	const handleGenericInput = (e) => {
		e.preventDefault();
		if (/^(pk|fk|int)/.test(e.target.id)) {
			const insertValue = Number(e.target.value);
			setPromptData({
				strID: e.target.id,
				value: insertValue && insertValue > 0 ? insertValue : 0,
			});
		} else if (/^(txt|dat|str)/.test(e.target.id)) {
			setPromptData({ strID: e.target.id, value: e.target.value });
		}
		const updatedFormData = structuredClone(formData);
		switch (e.target.id) {
			case "fkDataPromptRoleID":
				const txtPromptRole =
					roles.find(
						(obj) =>
							obj.pkDataPromptRoleID === Number(e.target.value)
					)?.strPromptRoleName || "";
				setSelectedRoleOption(txtPromptRole);
				updatedFormData.role = `${txtPromptRole}${roleValue ? " " + roleValue : ""
					}`;
				break;
			case "roleValue":
				setRoleValue(e.target.value);
				updatedFormData.role = `${selectedRoleOption ? selectedRoleOption + " " : ""
					}${e.target.value}`;
				break;
			case "fkDataPromptOutputID":
				const txtPromptFormat =
					outputs.find(
						(obj) =>
							obj.pkDataPromptOutputID === Number(e.target.value)
					)?.strPromptOutputName || "";
				setSelectedFormatOption(txtPromptFormat);
				updatedFormData.format = `${txtPromptFormat}${formatValue ? " " + formatValue : ""
					}`;
				break;
			case "formatValue":
				setFormatValue(e.target.value);
				updatedFormData.format = `${selectedFormatOption ? selectedFormatOption + " " : ""
					}${e.target.value}`;
				break;
			case "fkDataPromptProvideID":
				const txtPromptTask =
					tasks.find(
						(obj) =>
							obj.pkDataPromptProvideID === Number(e.target.value)
					)?.strPromptProvideName || "";
				setSelectedTaskOption(txtPromptTask);
				updatedFormData.task = `${txtPromptTask}${taskValue ? " " + taskValue : ""
					}`;
				break;
			case "task":
				setTaskValue(e.target.value);
				updatedFormData.task = `${selectedTaskOption ? selectedTaskOption + " " : ""
					}${e.target.value}`;
				break;
			case "txtSubject":
				updatedFormData.thema = e.target.value;
				break;
			case "fkDataPromptPlatformID":
				const txtPromptPlatform =
					platforms.find(
						(obj) =>
							obj.pkDataPromptPlatformID ===
							Number(e.target.value)
					)?.strPromptPlatformName || "";
				setSelectedPlatformOption(txtPromptPlatform);
				updatedFormData.platform = `${txtPromptPlatform}${platformValue ? " " + platformValue : ""
					}`;
				break;
			case "platformValue":
				setPlatformValue(e.target.value);
				updatedFormData.platform = `${selectedPlatformOption ? selectedPlatformOption + " " : ""
					}${e.target.value}`;
				break;
			case "fkDataLanguageID":
				updatedFormData.sprache =
					languages.find(
						(obj) => obj.pkDataLanguageID === Number(e.target.value)
					)?.strLanguage || "";
				break;
			case "txtAudience":
				updatedFormData.zielgruppe = e.target.value;
				break;
			case "intMaxLength":
				const valMaxLength = Number(e.target.value);
				updatedFormData.länge.max =
					valMaxLength && valMaxLength > 0 ? valMaxLength : 0;
				break;
			case "intMinLength":
				const valMinLength = Number(e.target.value);
				updatedFormData.länge.min =
					valMinLength && valMinLength > 0 ? valMinLength : 0;
				break;
			case "txtCallToAction":
				updatedFormData.callToAction = e.target.value;
				break;
			case "intEmojis":
				const arrEmojis = ["", labels.lblFew, labels.lblMuch];
				updatedFormData.emojis = arrEmojis[e.target.value];
				break;
			case "txtForm":
				updatedFormData.form = e.target.value;
				break;
			case "txtObject":
				updatedFormData.object = e.target.value;
				break;
			case "txtAdjective":
				updatedFormData.adjective = e.target.value;
				break;
			case "txtPosition":
				updatedFormData.position = e.target.value;
				break;
			case "txtPicStyle":
				updatedFormData.picStyle = e.target.value;
				break;
			case "txtParameter":
				updatedFormData.parameter = e.target.value;
				break;
			case "intNumberImg":

				const imageNumber = Number(e.target.value);
				updatedFormData.numberImg =
					imageNumber && imageNumber > 0 ? imageNumber : 0;
				break;
				
			case "intOnlyPrompt":
				const arrYesNo = ["", labels.lblYes, labels.lblNo];
				updatedFormData.onlyPrompt = arrYesNo[e.target.value];
				break;
				
			// case "":
			//   break;
			default:
				break;
		}
		setFormData(updatedFormData);
	};

	useEffect(() => {
		onChatValuesChange(formData);
	}, [formData]);

	return (
		<>
			{roles && selectedChatType === "option3" && (
				<PropertiesInput
					label={labels.lblRole + ':'}
					options={roles.map((obj) => ({
						id: obj.pkDataPromptRoleID,
						option: obj.strPromptRoleName,
					}))}
					optionID={"fkDataPromptRoleID"}
					textID={"roleValue"}
					inputValue={roleValue}
					selectedOption={promptData.fkDataPromptRoleID}
					onInputChange={handleGenericInput}
					onSelectChange={handleGenericInput}
					selectedChatType={selectedChatType}
					placeholderText={labels.lblRolePlaceholder}
					rows={1}
				/>
			)}

			{tasks && selectedChatType === "option3" && (
				<PropertiesInput
					label={labels.lblTask + ':'}
					options={tasks.map((obj) => ({
						id: obj.pkDataPromptProvideID,
						option: obj.strPromptProvideName,
					}))}
					optionID={"fkDataPromptProvideID"}
					textID={"task"}
					inputValue={taskValue}
					selectedOption={promptData.pkDataPromptProvideID}
					onInputChange={handleGenericInput}
					onSelectChange={handleGenericInput}
					placeholderText={labels.lblTaskPlaceholder}
					selectedChatType={selectedChatType}
					rows={5}
				/>
			)}

			{
				/* The expression `selectedChatType==='option2'  & ` is checking if the value of `selectedChatType` is equal to
				`'option2'`. If it is, the expression will evaluate to `true`, otherwise it will evaluate to
				`false`. */
				selectedChatType === "option3" && (
					<>
						{/* <div class="labelquestion">
                                <label for="prompt">Thema:</label>
                                <span>?</span>
                            </div>
							<textarea
								cols="30" rows="2"
								type="text"
								id="txtSubject"
								// placeholder="Thema"
								value={promptData.txtSubject || ""}
								onChange={handleGenericInput}
							/> */}

						<div class="labelquestion">
							<label for="prompt">{labels.lblStyle}:</label>
							{/* <span>?</span> */}
						</div>
						<select
							name="stil"

							value={formData.stil}
							onChange={handleChange}
						>
							<option value="">-{labels.lblPleaseChoose} -</option>
							{styles.map((obj, idx) => (
								<option
									value={obj.strPromptStyleName}
									key={`style_${idx}`}
								>
									{obj.strPromptStyleName}
								</option>
							))}
						</select>

						<div class="labelquestion">
							<label for="prompt">{labels.lblEmojis}:</label>
							{/* <span>?</span> */}
						</div>
						<select
							name="emojis"
							id="intEmojis"
							value={promptData.intEmojis}
							onChange={handleGenericInput}
						>
							<option value="0">-{labels.lblPleaseChoose} -</option>
							{/* <option value='1'>keine</option> */}
							<option value="1">{labels.lblFew}</option>
							<option value="2">{labels.lblMuch}</option>
						</select>

						<div class="labelquestion">
							<label for="prompt">{labels.lblLength}:</label>
							{/* <span>?</span> */}
						</div>
						<input
							type="text"
							id="intMinLength"
							name="länge.min"
							placeholder={labels.lblMinPlaceholder}
							value={promptData.intMinLength || ""}
							onChange={handleGenericInput}
						/>
						<input
							type="text"
							id="intMaxLength"
							name="länge.max"
							placeholder={labels.lblMaxPlaceholder}
							value={promptData.intMaxLength || ""}
							onChange={handleGenericInput}
						/>

						<div class="labelquestion">
							<label for="prompt">{labels.lblTargetGroup}:</label>
							{/* <span>?</span> */}
						</div>
						<textarea
							cols="30" rows="2"
							type="text"
							id="txtAudience"
							name="zielgruppe"
							placeholder={labels.lblTargetGroupPlaceholder}
							value={promptData.txtAudience || ""}
							onChange={handleGenericInput}
						/>

						<div class="labelquestion">
							<label for="prompt">{labels.lblCallToAction}:</label>
							{/* <span>?</span> */}
						</div>
						<textarea
							cols="30" rows="2"
							type="text"
							id="txtCallToAction"
							name="callToAction"
							placeholder={labels.lblCallToActionPlaceholder}
							value={promptData.txtCallToAction || ""}
							onChange={handleGenericInput}
						/>



						<div class="labelquestion">
							<label for="prompt">{labels.lblTextStructure}</label>
						</div>
						<textarea
							cols="30" rows="2"
							type="text"
							id="txtForm"
							name="form"
							placeholder={labels.lblTextStructurePlaceholder}
							value={promptData.txtForm || ""}
							onChange={handleGenericInput}
						/>

						<div class="labelquestion">
							<label for="prompt">{labels.lblLanguage}:</label>
							{/* <span>?</span> */}
						</div>
						<select
							name="sprache"
							id="fkDataLanguageID"
							value={promptData.fkDataLanguageID}
							onChange={handleGenericInput}
						>
							<option value={0}>-{labels.lblPleaseChoose} -</option>
							{languages.map((obj, idx) => (
								<option
									value={obj.pkDataLanguageID}
									key={`language_${idx}`}
								>
									{obj.strLanguage}
								</option>
							))}
						</select>
					</>
				)
			}
			{outputs && selectedChatType === "option3" && (
				<PropertiesInput
					label={labels.lblFormat + ':'}
					options={outputs.map((obj) => ({
						id: obj.pkDataPromptOutputID,
						option: obj.strPromptOutputName,
					}))}
					inputValue={formatValue}
					optionID={"fkDataPromptOutputID"}
					textID={"formatValue"}
					selectedOption={promptData.fkDataPromptOutputID}
					onInputChange={handleGenericInput}
					onSelectChange={handleGenericInput}
					selectedChatType={selectedChatType}
					placeholderText={labels.lblFormatPlaceholder}
				/>
			)}

			{platforms && selectedChatType === "option3" && (
				<PropertiesInput
					label={labels.lblPlatform + ':'}
					options={platforms.map((obj) => ({
						id: obj.pkDataPromptPlatformID,
						option: obj.strPromptPlatformName,
					}))}
					optionID={"fkDataPromptPlatformID"}
					textID={"platformValue"}
					inputValue={platformValue}
					selectedOption={promptData.fkDataPromptPlatformID}
					onInputChange={handleGenericInput}
					onSelectChange={handleGenericInput}
					selectedChatType={selectedChatType}
					placeholderText={labels.lblPlatformPlaceholder}
				/>
			)}

			{selectedChatType === "option4" && (
				<>
					<div class="labelquestion">
						<label for="prompt">{labels.lblObject}</label>
					</div>
					<textarea
						cols="30" rows="2"
						type="text"
						id="txtObject"
						name="object"
						placeholder={labels.lblObjectPlaceholder}
						value={promptData.txtObject || ""}
						onChange={handleGenericInput}
					/>
					<div class="labelquestion">
						<label for="prompt">{labels.lblAdjective}</label>
					</div>
					<textarea
						cols="30" rows="2"
						type="text"
						id="txtAdjective"
						name="adjective"
						placeholder={labels.lblAdjectivePlaceholder}
						value={promptData.txtAdjective || ""}
						onChange={handleGenericInput}
					/>
					<div class="labelquestion">
						<label for="prompt">{labels.lblPosition}</label>
					</div>
					<textarea
						cols="30" rows="2"
						type="text"
						id="txtPosition"
						name="position"
						placeholder={labels.lblPositionPlaceholder}
						value={promptData.txtPosition || ""}
						onChange={handleGenericInput}
					/>
					{/* <PropertiesInput
						label={labels.lblPlatform+':'}
						options={platforms.map((obj) => ({
							id: obj.pkDataPromptPlatformID,
							option: obj.strPromptPlatformName,
						}))}
						optionID={"fkDataPromptPlatformID"}
						textID={"platformValue"}
						inputValue={platformValue}
						selectedOption={promptData.fkDataPromptPlatformID}
						onInputChange={handleGenericInput}
						onSelectChange={handleGenericInput}
						selectedChatType={selectedChatType}
						placeholderText={labels.lblPlatformPlaceholder}
					/> */}

					<div class="labelquestion">
						<label for="prompt">{labels.lblNumberImg}:</label>
					</div>
					<input
						type="text"
						id="intNumberImg"
						name="numberImg"
						placeholder={labels.lblNumberImgPlaceholder}
						value={promptData.intNumberImg || ""}
						onChange={handleGenericInput}
					/>


					<div class="labelquestion">
						<label for="prompt">{labels.lblStyle}</label>
					</div>
					<textarea
						cols="30" rows="2"
						type="text"
						id="txtPicStyle"
						name="picStyle"
						placeholder={labels.lblStylePlaceholder}
						value={promptData.txtPicStyle || ""}
						onChange={handleGenericInput}
					/>

					<div class="labelquestion">
						<label for="prompt">{labels.lblLanguage}:</label>
					</div>
					<select
						name="sprache"
						id="fkDataLanguageID"
						value={promptData.fkDataLanguageID}
						onChange={handleGenericInput}
					>
						<option value={0}>-{labels.lblPleaseChoose} -</option>
						{languages.map((obj, idx) => (
							<option
								value={obj.pkDataLanguageID}
								key={`language_${idx}`}
							>
								{obj.strLanguage}
							</option>
						))}
					</select>


					<div class="labelquestion">
						<label for="prompt">{labels.lblParameter}</label>
					</div>
					<textarea
						cols="30" rows="2"
						type="text"
						id="txtParameter"
						name="parameter"
						placeholder={labels.lblParameterPlaceholder}
						value={promptData.txtParameter || ""}
						onChange={handleGenericInput}
					/>

					<div class="labelquestion">
						<label for="prompt">{labels.lblOnlyPrompt}:</label>
					</div>
					<select
						name="onlyPrompt"
						id="intOnlyPrompt"
						value={promptData.intOnlyPrompt}
						onChange={handleGenericInput}
					>
						<option value={0}>-{labels.lblPleaseChoose} -</option>
						<option value="1">{labels.lblYes}</option>
						<option value="2">{labels.lblNo}</option>
					</select>

				</>
			)}
		</>
	);
};

export default StructuralChat;
