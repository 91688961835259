import React from 'react'
import { createRoot } from 'react-dom/client'

// import './css/dashboard.css'
// import './css/ergebnis.css'
// import './css/einstellung.css'
// import './css/erfassung.css'
// import './css/index.css'


import './cssNew/library.css'
import './cssNew/login.css'
import './cssNew/new-nav-footer.css'
import './cssNew/log-out.css'
import './cssNew/prompt-pages.css'
import './cssNew/prompt-view.css'
import './cssNew/reset.css'
import './cssNew/password.css'
import './cssNew/settings-tables.css'


import App from './App'
/* import Datetime from '../Datetime'; */

const container = document.getElementById('app')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <App />
  {/*   <Datetime/> */}
  </React.StrictMode>
)