import React, { useState } from "react";
import PasswordChangeInput from "./PasswordChangeInput";
import { useSharedData } from "../SharedDataContext";

const PasswordChange = ({ onClose }) => {
	const { loggedInUser } = useSharedData();
	const [state, setState] = useState({
		currentPassword: "",
		newPassword: "",
		confirmPassword: "",
		message: "",
	});
	const {labels, setLabels, callBackend} = useSharedData();
	const handleInputChange = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const { currentPassword, newPassword, confirmPassword } = state;

		callBackend({
			action: "verify_password",
			payload: {
				strPassword: currentPassword,
				pkUserID: loggedInUser.pkUserID,
			},
		})
			.then((rst) => {
				if (rst.serverAction !== "login_success") {
					setState({
						...state,
						message: "Aktuelles Passwort überprüfen!",
					});
				} else if (newPassword !== confirmPassword) {
					setState({
						...state,
						message: "Neue Passwörter stimmen nicht überein",
					});
				} else if (
					!/^(?=(.*[0-9]){1,})(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*([^\s\w]|_)){1,}).{8,}$/.test(
						newPassword
					)
				) {
					setState({
						...state,
						message:
							"Das Passwort muss mindestens 8 Zeichen lang sein und jeweils mindestens einen Großbuchstaben, einen Kleinbuchstaben , eine Zahl und ein Sonderzeichen enthalten!",
					});
				} else {
					// Add your password change logic here

					callBackend({
						action: "update_password",
						payload: {
							pkUserID: loggedInUser.pkUserID,
							strPassword: currentPassword,
							strNewPassword: newPassword,
						},
					})
						.then((rst) => {
							if (
								rst.serverAction === "database_update_success"
							) {
								setState({
									...state,
									message:
										"Das Passwort wurde erfolgreich geändert",
								});
							}
						})
						.catch((err) => console.log(err));
				}
			})
			.catch((err) => console.log(err));
	};

	return (
		<div id="passModal" class="modal">
			<div class="modal-content">
				<div class="form-close">
					<div>
						<h2>{labels.lblPleaseChangePassword}</h2>
						<form class="change-pass" onSubmit={handleSubmit}>
							<input
								type="hidden"
								autoComplete="username"
								value={loggedInUser.strUserName}
							/>
							<PasswordChangeInput
								label={labels.lblCurrentPassword}
								name="currentPassword"
								placeholder={labels.lblCurrentPassword}
								value={state.currentPassword}
								onChange={handleInputChange}
							/>
							<PasswordChangeInput
								label={labels.lblNewPassword}
								name="newPassword"
								placeholder={labels.lblNewPassword}
								value={state.newPassword}
								onChange={handleInputChange}
							/>
							<PasswordChangeInput
								label={labels.lblRepeatPassword}
								name="confirmPassword"
								placeholder={labels.lblRepeatPassword}
								value={state.confirmPassword}
								onChange={handleInputChange}
							/>

							{/* <button className="table-button" type="button" onClick={onClose}>
								<p>Schließen</p>
							</button> */}
							<button type="submit">
								{labels.lblSetPassword}
							</button>
						</form>
						
					</div>
					<span onClick={onClose} class="pass-close">&times;</span>
				</div>
				{state.message && <p style={{color: '#dd4040'}}>{state.message}</p>}
			</div>
		</div>
	);
};

export default PasswordChange;
