const PasswordChangeInput = ({ name, value, onChange, placeholder }) => {
	return (
		<div>
			<label>{placeholder}</label>
			<div class="textarea-container">
				<input
					type="password"
					// className="passwordInput"
					id={name}
					name={name}
					value={value}
					placeholder={placeholder}
					autoComplete={
						name === "currentPassword"
							? "current-password"
							: "new-password"
					}
					onChange={onChange}
				/>
			</div>
		</div>
	);
};

export default PasswordChangeInput;
