import React from "react";
import { useState, useEffect } from "react";
import Header from "../component/Header";
import FirmInfo from "../component/FirmInfo";
import SeitenListe from "../component/SeitenListe";
import NeueBenutzer from "../component/NeueBenutzer";
import BenutzerInfo from "../component/BenutzerInfo";
import { useSharedData } from "../SharedDataContext";
import { useNavigate } from "react-router";
import BenutzerList from "../component/BenutzerList";
import Footer from "../component/Footer";
import { useLocation } from "react-router-dom";
import KategorieAdmin from "../component/KategorieAdmin";

const Einstellung = () => {
	const [userId, setUserID] = useState(0);
	const navigate = useNavigate();
	const [firmId, setFirmId] = useState(null);
	const {labels, setLabels} = useSharedData();
	const [showBlock, setShowBlock] = useState(null);
	// const dataCtx = useContext(useSharedData);
	const { loggedInUser } = useSharedData();

	// const navigate = useNavigate();

	const location = useLocation();
	let option=location.pathname.split("/")[2]


	useEffect(() => {
		if (!loggedInUser) {
			navigate("/");
		} else {
			setFirmId(loggedInUser.fkCompanyID);
			const userId = loggedInUser.pkUserID;
			setUserID(userId);
			setShowBlock(Number(option));
		}
	}, [loggedInUser,option]);
	
return (
	<div>
		<SeitenListe />
		<div>
			{loggedInUser && (
				<div className="HauptForm">
					{showBlock === 0 && <BenutzerInfo />}
					{showBlock === 1 && <FirmInfo firmId={firmId} />}
					
					{/* {showBlock === 2 && <KategorieAdmin firmId={firmId} />} */}
					{showBlock === 2 && loggedInUser?.fkDataRoleID > 1 && <BenutzerList />}
					{/* {showBlock === 7 && <NeueBenutzer />} */}
				</div>
			)}
			<Footer />
		</div>
	</div>
);

	return (
		<div>
			<Header />
			<SeitenListe />
			<section className="Einstellungsseite">
				<div className="wrapper">
					<div className="SeiteForm">
						<ul>
							<li
								className={showBlock === 0 ? "active" : ""}
								onClick={() => setShowBlock(0)}
							>
								{labels.lblPersonalInformation}
							</li>
							<li
								className={showBlock === 1 ? "active" : ""}
								onClick={() => setShowBlock(1)}
							>
								{labels.lblCompanyInformation}
							</li>
							{/* <li className={showBlock === 2 ? "active" : ""}>
								Benachrichtigung
							</li>
							<li className={showBlock === 3 ? "active" : ""}>
								Sprache
							</li>
							<li className={showBlock === 4 ? "active" : ""}>
								Hintergrundfarbe
							</li> */}
							{loggedInUser?.fkDataRoleID > 1 ? (
								<li
									className={showBlock === 6 ? "active" : ""}
									onClick={() => setShowBlock(6)}
								>
									{labels.lblUserlist}
								</li>
							) : (
								""
							)}
							{loggedInUser?.fkDataRoleID > 1 ? (
								<li
									className="disabledButton"
									// className={showBlock[7] ? "active" : ""}
									// onClick={() => setShowBlock(7)}
								>
									{labels.lblNeuUser}
								</li>
							) : (
								""
							)}

							<li onClick={() => navigate("/abmelden")}>
								{labels.lblSwitchAccounts}
							</li>
						</ul>
					</div>
					{loggedInUser && (
						<div className="HauptForm">
							{showBlock === 0 && <BenutzerInfo />}
							{showBlock === 1 && <FirmInfo firmId={firmId} />}
							{/* {showBlock === 6 && <BenutzerList />} */}
							{/* {showBlock === 7 && <NeueBenutzer />} */}
						</div>
					)}
					<Footer />
				</div>
			</section>
		</div>
	);
};

export default Einstellung;
