import { useState } from "react";
import { useSharedData } from "../SharedDataContext";
import PasswordChange from "./PasswordChange";
import BenutzerInfoItem from "./BenutzerInfoItem";
// import Modal from "./modal";

const BenutzerInfo = () => {
	const { loggedInUser, userZusBereichList, departments, accountRoles, languages } = useSharedData();
	const {labels, setLabels} = useSharedData();
	const [newPassword, setNewPassword] = useState(false);

	const handleChangePasswordClick = () => {
		setNewPassword(true);
	};

	const handleUpdatePassword = () => {
		setNewPassword(false);
	};
	const closePasswordChange = () => {
		setNewPassword(false);
	};

	return (
		<>
		<div>

			<table className="pi settings-body table MainShape">
			<thead>
                <tr>
                    <th class="table-title" colspan="3">{labels.lblPersonalInformation}</th>
                </tr>
            </thead>
				<tbody>
					<BenutzerInfoItem
						label={labels.lblLastName}
						strEditType="text"
						strValueID="strSurName"
						value={loggedInUser.strSurName}
						userID={loggedInUser.pkUserID}
					/>
					<BenutzerInfoItem
						label={labels.lblFirstName}
						strEditType="text"
						strValueID="strFirstName"
						value={loggedInUser.strFirstName}
						userID={loggedInUser.pkUserID}
					/>
					<BenutzerInfoItem
						label={labels.lblEmail}
						strEditType="text"
						strValueID="strEmail"
						value={loggedInUser.strEmail}
						userID={loggedInUser.pkUserID}
					/>
					<BenutzerInfoItem
						label={labels.lblUserName}
						strEditType="text"
						strValueID="strEmail"
						value={loggedInUser.strEmail}
						userID={loggedInUser.pkUserID}
					/>
					<tr className="row">
						<th className="row">{labels.lblPassword}</th>
						
							{/* <div className="passwordDiv"> */}
								{!newPassword && (
									<>
									<td></td>
										<td className="td-button">
										<button
											className="table-button"
											onClick={handleChangePasswordClick}
										>
											<a id="openModalLink">{labels.lblChange}</a>
										</button>
										</td>
									</>
								)}
							{/* </div>
							<div> */}
								
							{/* </div> */}
					</tr>
					<BenutzerInfoItem
						label={labels.lblMainDepartment}
						strEditType="select"
						strValueID="intMainDepartment"
						arrOptions={userZusBereichList
							.filter(
								(department) =>
									department.fkCompanyID ===
									loggedInUser.fkCompanyID
							)
							.map((department) => ({
								value: department.pkDepartmentID,
								name: department.strDepartment,
							}))}
						value={loggedInUser.intMainDepartment}
						userID={loggedInUser.pkUserID}
					/>
					<BenutzerInfoItem
						label={labels.lblDepartment}
						strEditType="multiselect"
						arrOptions={departments
							.filter(
								(department) =>
									department.fkCompanyID ===
									loggedInUser.fkCompanyID
							)
							.map((department) => ({
								value: department.pkDepartmentID,
								name: department.strDepartment,
							}))}
						value={userZusBereichList.map(
							(item) => item.pkDepartmentID
						)}
						userID={loggedInUser.pkUserID}
					/>
					<BenutzerInfoItem
						label={labels.lblRole}
						strEditType="select"
						strValueID="fkDataRoleID"
						arrOptions={accountRoles
							.map((role) => ({
								value: role.pkDataRoleID,
								name: role.strRole,
							}))}
						value={loggedInUser.fkDataRoleID}
						userID={loggedInUser.pkUserID}
					/>
					{/* <BenutzerInfoItem
						label={labels.lblLanguage}
						strEditType="select"
						strValueID="fkDataLanguageID"
						EditEmployees={true}
						arrOptions={languages
							.map((role) => ({
								value: role.pkDataLanguageID,
								name: role.strLanguage,
							}))}
						value={loggedInUser.fkDataLanguageID}
						userID={loggedInUser.pkUserID}
					/> */}
					<BenutzerInfoItem
						label={labels.lblCreationDate}
						strEditType="disabled"
						value={loggedInUser.datCreate}
						userID={loggedInUser.pkUserID}
					/>
				</tbody>
			</table>
		</div>
		{newPassword && (
			// <Modal></Modal>
			<PasswordChange
				setNewPassword={handleUpdatePassword}
				onClose={closePasswordChange}
			/>
		)}
		</>

	);
};

export default BenutzerInfo;
