import React, { useReducer } from "react";
import swal from "sweetalert";

import padlock from "../images/padlock.png"
import profileUser from "../images/profile-user.png"
import firmenpunkt from "../images/firmenpunkt_logo.svg"
import circlelogo from "../images/CircleLogo.png"


import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
	
	formatValues,
	useSharedData,
	useUser,
} from "../SharedDataContext";

import ChatArtDropdown from "../component/ChatArtDropdown";
import ChatComponent from "../component/ChatComponentProcedural";
import ChatDropdown from "../component/ChatDropdown";
import Header from "../component/Header";
import DepartmentComponent from "../component/Departments/DepartmentComponent";
import KategorienComponent from "../component/Kategorien_Unterkategorien_Eigenschaften/KategorienComponent";
import TagsComponent from "../component/Tags/TagsComponent";
import SeitenListe from "../component/SeitenListe";
import Footer from "../component/Footer";
import { Co2Sharp } from "@mui/icons-material";

const PromptSpeichern = () => {
	const navigate = useNavigate();

	const [noteValue, setNoteValue] = useState("");
	const [titleValue, setTitleValue] = useState("");
	const [active, setActive] = useState(true)
	const [inputValue, setInputValue] = useState('')
	const [ergebnisWert, setErgebnisWert] = useState("");

	// const [chatType, setChatType] = useState("");
	const [eigenschaftenWert, setEigenschaftenWert] = useState([]);
	const [departmentWert, setDepartmentWert] = useState([]);
	const [tagsList, setTagsList] = useState([]);
	const [blnReload, setReload] = useReducer((prior) => (prior + 1) % 2, 0)

	const { tags, setTags, promptTags, setPromptTags, callBackend } = useSharedData();
	const { prompts, setPrompts, promptData, setPromptData } = useSharedData();
	const { setPromptDepartments, setPromptCategories } = useSharedData();
	const { setKategorieList, setUnterkategorieList } = useSharedData();
	const { labels, setLabels } = useSharedData();

	const { loggedInUser } = useUser();

	const { firms, zipCodes } = useSharedData();

	const [blnTemplete, setTemplete] = useState(false);


	const aufhellen = (hex, faktor) => {
		// Überprüfen, ob der HEX-Wert richtig formatiert ist
		if (!/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
			throw new Error('Ungültige HEX-Farbe');
		}

		// Entfernen des '#'-Symbols, falls vorhanden
		hex = hex.replace('#', '');

		// Konvertieren des HEX-Werts in RGB
		let r = parseInt(hex.substring(0, 2), 16);
		let g = parseInt(hex.substring(2, 4), 16);
		let b = parseInt(hex.substring(4, 6), 16);

		// Helligkeit des Weißwertes
		const weiß = 255;

		// Helligkeit der Farbe erhöhen, indem relative Helligkeit von Weiß hinzugefügt wird
		r += (weiß - r) * faktor / 100;
		g += (weiß - g) * faktor / 100;
		b += (weiß - b) * faktor / 100;

		// Begrenzen der Werte auf den Bereich von 0 bis 255
		r = Math.min(255, Math.max(0, r));
		g = Math.min(255, Math.max(0, g));
		b = Math.min(255, Math.max(0, b));

		// Konvertieren der RGB-Werte zurück in HEX
		r = Math.round(r).toString(16).padStart(2, '0');
		g = Math.round(g).toString(16).padStart(2, '0');
		b = Math.round(b).toString(16).padStart(2, '0');
		return `#${r}${g}${b}`;
	}

	useEffect(() => {
		if (!loggedInUser) {
			navigate("/");
		} else {
			callBackend({ action: "get_categories", payload: { fkDataLanguageID: loggedInUser.fkDataLanguageID } })
				.then((res) => {
					setKategorieList(formatValues(res));
				})
				.catch((err) => console.log(err));

			callBackend({ action: "get_subcategories", payload: { fkDataLanguageID: loggedInUser.fkDataLanguageID } })
				.then((res) => {
					setUnterkategorieList(formatValues(res));
				})
				.catch((err) => console.log(err));

			callBackend({ action: "get_tags" })
				.then((res) => {
					setTags(formatValues(res));
				})
				.catch((err) => console.log(err));

			if (firms) {
				const filteredFirm = firms.filter(
					(uk) => uk.pkCompanyID === loggedInUser.fkCompanyID
				);
				if (filteredFirm.length > 0) {
					var r = document.querySelector(':root');

					r.style.setProperty('--mainColor', filteredFirm[0].strCompanyColor);
					r.style.setProperty('--secondColor', aufhellen(filteredFirm[0].strCompanyColor, 30));
					r.style.setProperty('--thirdColor', aufhellen(filteredFirm[0].strCompanyColor, 80));
				}
			}

		}
	}, [loggedInUser, firms]);

	const pageName = {
		name: "promptspeichern",
		link1: "/",
		link2: "/",
		hide1: "yes",
		hide2: "no",
		userId: 0,
	};

	const openNotizDialog = () => {
		swal("Möchten Sie eine Notiz hinzugügen?", {
			buttons: {
				cancel: "Abbrechen",
				confirm: true,
			},
			content: "input",
		}).then((notizValue) => {
			if (notizValue != undefined) {
				addTitelDialog(notizValue);
			}
		});
	};
	const addTitelDialog = (notizValue) => {
		swal("Geben Sie bitte einen Titel ein!", {
			buttons: {
				cancel: "Abbrechen",
				confirm: true,
			},
			content: "input",
		}).then((titelValue) => {
			if (titelValue != undefined) {
				if (titelValue.trim() === "") {
					noTitle(notizValue);
				} else {
					swal(
						`Das Prompt wurde mit Titel ${titelValue} hinzugefügt gespeichert.`
					).then(() => {
						addPrompt(notizValue, titelValue);
					});
				}
			}
		});
	};
	const noTitle = (notizValue) => {
		swal("Der Titel kann nicht leer sein", {
			button: false,
			timer: 1000,
		}).then(() => addTitelDialog(notizValue));
	};

	let promptID;

	const addPrompt = (notizValue, titelValue) => {
		let input = document.getElementById("inputText").value;
		let output = document.getElementById("outputText").textContent;
		callBackend({
			action: "save_prompt",
			payload: {
				fkUserID: loggedInUser.pkUserID,
				fkDataPromptRoleID: promptData.fkDataPromptRoleID || 0,
				fkDataPromptProvideID: promptData.fkDataPromptProvideID || 0,
				fkDataPromptOutputID: promptData.fkDataPromptOutputID || 0,
				fkDataPromptPlatformID: promptData.fkDataPromptPlatformID || 0,
				fkDataLanguageID: promptData.fkDataLanguageID || 0,
				strPromptName: titleValue,
				intPromptKind: promptData.intPromptKind || 0,
				txtSubject: promptData.txtSubject || null,
				txtImput: input,
				txtOutput: output,
				txtComment: notizValue,
				intMinLength: promptData.intMinLength || 0,
				intMaxLength: promptData.intMaxLength || 0,
				txtAudience: promptData.txtAudience || null,
				txtCallToAction: promptData.txtCallToAction || null,
				txtForm: promptData.txtForm || null,
				intEmojis: promptData.intEmojis || 0,
				intPublicPrompt: blnPublicPrompt ? 1 : 0,
				intTemplate: blnTemplete ? 1 : 0,
			},
		}).then((rst) => {
			setPrompts([
				...prompts,
				{
					pkPromptID: rst.pkPromptID,
					fkUserID: loggedInUser.pkUserID,
					fkDataPromptRoleID: promptData.fkDataPromptRoleID || 0,
					fkDataPromptProvideID:
						promptData.fkDataPromptProvideID || 0,
					fkDataPromptOutputID: promptData.fkDataPromptOutputID || 0,
					fkDataPromptPlatformID:
						promptData.fkDataPromptPlatformID || 0,
					fkDataLanguageID: promptData.fkDataLanguageID || 0,
					strPromptName: titelValue,
					intPromptKind: promptData.intPromptKind || 0,
					txtSubject: promptData.txtSubject || null,
					txtImput: input,
					txtOutput: output,
					txtComment: notizValue,
					intMinLength: promptData.intMinLength || 0,
					intMaxLength: promptData.intMaxLength || 0,
					txtAudience: promptData.txtAudience || null,
					txtCallToAction: promptData.txtCallToAction || null,
					txtForm: promptData.txtForm || null,
					intEmojis: promptData.intEmojis || 0,
					datCreate: rst.datCreate,
					datTsControl: rst.datTsControl,
					intPublicPrompt: blnPublicPrompt ? 1 : 0,
					intTemplate: blnTemplete ? 1 : 0,
				},
			]);
			let date = new Date().toISOString().slice(0, 10);

			eigenschaftenWert.map((obj) => {
				callBackend({
					action: "save_prompt_category",
					payload: {
						fkUserID: loggedInUser.pkUserID,
						fkDepartmentID: obj.fkDepartmentID,
						fkPromptID: rst.pkPromptID,
						fkDataPromptCategoryID: obj.fkDataPromptCategoryID,
						fkDataPromptSubCategoryID:
							obj.fkDataPromptSubCategoryID,
					},
				}).then((rst2) => {
					setPromptCategories((oldCategories) => [
						...oldCategories,
						{
							pkPromptCategoryID: rst2.pkPromptCategoryID || 0,
							fkUserID: loggedInUser.pkUserID,
							fkDepartmentID: obj.fkDepartmentID,
							fkPromptID: rst.pkPromptID,
							fkDataPromptCategoryID:
								obj.fkDataPromptCategoryID || 0,
							fkDataPromptSubCategoryID:
								obj.fkDataPromptSubCategoryID || 0,
							// intStatus: 1, // ToDo: vom Server zurückgeben?
							// datTsControl: rst.datTsControl
						},
					]);
				});
			});

			if (departmentWert.length) {
				departmentWert.map((obj) => {
					callBackend({
						action: "save_prompt_department",
						payload: {
							fkDepartmentID: obj.fkDepartmentID,
							fkPromptID: rst.pkPromptID,
						},
					}).then((rst2) => {
						setPromptDepartments((oldCategories) => [
							...oldCategories,
							{
								fkDepartmentID: obj.fkDepartmentID,
								fkPromptID: rst.pkPromptID,
								// intStatus: 1, // ToDo: vom Server zurückgeben?
								// datTsControl: rst.datTsControl
							},
						]);
					});
				});
			} else {
				callBackend({
					action: "save_prompt_department",
					payload: {
						fkDepartmentID: loggedInUser.intMainDepartment,
						fkPromptID: rst.pkPromptID,
					},
				}).then((rst2) => {
					setPromptDepartments((oldCategories) => [
						...oldCategories,
						{
							fkDepartmentID: loggedInUser.intMainDepartment,
							fkPromptID: rst.pkPromptID,
						},
					]);
				});
			}

			tagsList.forEach((obj) => {
				callBackend({
					action: "save_prompt_tag",
					payload: {
						fkPromptID: rst.pkPromptID,
						fkDataPromptTagID: obj.pkDataPromptTagID,
					},
				});
			});
			const newPromptTags = tagsList.map((obj) => ({
				fkPromptID: rst.pkPromptID,
				fkDataPromptTagID: obj.pkDataPromptTagID,
				intStatus: 1,
				datTsControl: date,
			}));
			setPromptTags([...promptTags, ...newPromptTags]);
		});
		setNoteValue("");
		setTitleValue("");
		setErgebnisWert("");
		setEigenschaftenWert([]);
		setDepartmentWert([]);
		setTagsList([]);
		setReload();
		document.getElementById("inputText").value = "";
		setPromptData({ action: "reset_prompt" });
		setTemplete(false)
	};

	const clickSaveButtonHandler = () => {

		if (titleValue) {
			addPrompt(noteValue, titleValue)
			swal(labels.lblSuccessfullySaved)
		}

	};

	const [selectedChatType, setSelectedChatType] = useState("option1");
	const [selectedArt, setSelectedArt] = useState("art1");
	const [disableArt, setDisableArt] = useState(true);
	const [blnPublicPrompt, setPublicPrompt] = useState(
		loggedInUser?.fkDataRoleID < 2
	);

	const handleOptionChange = (event) => {
		const selectedOption = event.target.value;
		setSelectedChatType(selectedOption);
		// setChatType(selectedOption);

		setPromptData({ action: "reset_prompt" });
		//setInputText('')
		//setOutputText('')
	};

	const handleArtChange = (event) => {
		const selectedArtOption = event.target.value;
		setSelectedArt(selectedArtOption);
	};

	useEffect(() => {
		// setChatType(selectedChatType);
		setSelectedArt("art1");
		if (selectedChatType === "option4") {
			setDisableArt(false);
		} else {
			setDisableArt(true);
		}
	}, []);

	useEffect(() => {
		if (selectedChatType === "option4") {
			setDisableArt(false);
		} else {
			setDisableArt(true);
		}
	}, [selectedChatType]);

	useEffect(() => {
		setPromptData({ action: "reset_prompt" });
	}, []);

	useEffect(() => {
		if (ergebnisWert.trim()) {

			if (titleValue.trim()) {
				setActive(false)
			} else {

				setActive(true)
			}

		} else {
			setActive(true)
		}
	}, [ergebnisWert, titleValue])


	const buttonActive = () => {
		if (active) {
			if (titleValue.trim()) {
				swal(labels.lblProvideOutput)

			} else {
				window.scrollTo(0, 0);
				swal(labels.lblProvideTitle)
			}

		} else {
			window.scrollTo(0, 0);
			clickSaveButtonHandler()
		}

	}

	return (
		<>
			<SeitenListe />
			<div class="prompt-body">
				<h2 class="prompt-title">{labels.lblSavePrompt}</h2>
				<div class="prompt-content">
					<aside class="categorytags">
						{/* <form> */}
							<KategorienComponent
								key={`category_component_${blnReload}`}
								promptID={promptID}
								eigenschaftenWert={eigenschaftenWert}
								setEigenschaftenWert={setEigenschaftenWert}
								
							/>
							<TagsComponent
								key={`tags_component_${blnReload}`}
								tags={tags}
								setTags={setTags}
								tagsList={tagsList}
								setTagsList={setTagsList}
							/>
							{loggedInUser?.fkDataRoleID > 1 && (
							<div className="tags">
								<span>
									<label for="tags">{labels.lblTemplate}:</label>
									{/* <h3>{labels.lblTag}:</h3> */}
									<input
										key={`template_component_${blnReload}`}
										class="temp-checkBox"
										type="checkbox"
										id="blnPrivate"
										defaultChecked={blnTemplete}
										onClick={(e) => {
											setTemplete(e.target.checked);
										}}
									/>
								</span>
							</div>
						)}
						{/* </form> */}
					</aside>
					<section class="prompt-section">
						<div class="characteristics">
							{/* <form> */}
							<ChatComponent
								key={`chat_component_${blnReload}`}
								setNoteValue={setNoteValue}
								noteValue={noteValue}
								setTitleValue={setTitleValue}
								titleValue={titleValue}
								ergebnisWert={ergebnisWert}
								setErgebnisWert={setErgebnisWert}
								selectedChatType={selectedChatType}
								publicPrompt={blnPublicPrompt}
							/>
							<button
								style={{ margin: "25px auto 10px auto" }}
								// disabled={active}
								className="save"
								onClick={buttonActive}
							>
								{labels.lblSave}
							</button>
							{/* </form> */}
						</div>
					</section>
				</div>
			</div>
			<Footer />
		</>
	);

	// return (
	// 	<div>
	// 		{/* <Header items={pageName} /> */}
	// 		<SeitenListe />
	// 		<section className="Erfassungsseite">
	// 			<div className="wrapper">
	// 				<div className="SeiteForm">
	// 					{/* <div className="OptionForm">
	// 						<ChatDropdown
	// 							selectedOption={selectedChatType}
	// 							handleOptionChange={handleOptionChange}
	// 						/>
	// 						<ChatArtDropdown
	// 							selectedArtOption={selectedArt}
	// 							handleArtChange={handleArtChange}
	// 							disabled={disableArt}
	// 						/>
	// 					</div> */}
	// 					{/* <DepartmentComponent
	// 						promptID={promptID}
	// 						departmentWert={departmentWert}
	// 						setDepartmentWert={setDepartmentWert}
	// 					/> */}
	// 					<KategorienComponent
	// 						promptID={promptID}
	// 						eigenschaftenWert={eigenschaftenWert}
	// 						setEigenschaftenWert={setEigenschaftenWert}
	// 					/>
						
	// 					<TagsComponent
	// 						tags={tags}
	// 						setTags={setTags}
	// 						tagsList={tagsList}
	// 						setTagsList={setTagsList}
	// 					/>
	// 					{/* {loggedInUser?.fkDataRoleID > 1 && (
	// 						<div className="private_prompt">
	// 							<label htmlFor="blnPrivate">
	// 								Prompt für Mitarbeiter sichtbar machen
	// 							</label>
	// 							<input
	// 								type="checkbox"
	// 								id="blnPrivate"
	// 								defaultChecked={blnPublicPrompt}
	// 								onClick={(e) => {
	// 									setPublicPrompt(e.target.checked);
	// 								}}
	// 							/>
	// 						</div>
	// 					)} */}
	// 				</div>
	// 				<div className="HauptForm">
	// 					<ChatComponent
	// 						key={`chat_component_${blnReload}`}
	// 						setNoteValue={setNoteValue}
	// 						noteValue={noteValue}
	// 						setTitleValue={setTitleValue}
	// 						titleValue={titleValue}
	// 						ergebnisWert={ergebnisWert}
	// 						// chatType={chatType}
	// 						setErgebnisWert={setErgebnisWert}
	// 						selectedChatType={selectedChatType}
	// 						publicPrompt={blnPublicPrompt}
	// 					/>
	// 					<div className="speicherButton">
	// 						<button
	// 							disabled={active}
	// 							className="btn-right"
	// 							onClick={clickSaveButtonHandler}
	// 						>
								
	// 							<p>{labels.lblSave}</p>
	// 						</button>
	// 					</div>
	// 				</div>
	// 				<Footer />
	// 			</div>
	// 		</section>
	// 	</div>
	// );
	
};

export default PromptSpeichern;
