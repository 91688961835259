import LogoImage from "../images/Promptothek.png";

import padlock from "../images/padlock.png"
import profileUser from "../images/profile-user.png"
import firmenpunktB from "../images/firmenpunkt_logo-black.svg"
import firmenpunkt from "../images/firmenpunkt_logo.svg"

import { useSharedData } from "../SharedDataContext";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

const aufhellen = (hex, faktor) => {
	// Überprüfen, ob der HEX-Wert richtig formatiert ist
	if (!/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
		throw new Error('Ungültige HEX-Farbe');
	}

	// Entfernen des '#'-Symbols, falls vorhanden
	hex = hex.replace('#', '');

	// Konvertieren des HEX-Werts in RGB
	let r = parseInt(hex.substring(0, 2), 16);
	let g = parseInt(hex.substring(2, 4), 16);
	let b = parseInt(hex.substring(4, 6), 16);

	// Helligkeit des Weißwertes
	const weiß = 255;

	// Helligkeit der Farbe erhöhen, indem relative Helligkeit von Weiß hinzugefügt wird
	r += (weiß - r) * faktor / 100;
	g += (weiß - g) * faktor / 100;
	b += (weiß - b) * faktor / 100;

	// Begrenzen der Werte auf den Bereich von 0 bis 255
	r = Math.min(255, Math.max(0, r));
	g = Math.min(255, Math.max(0, g));
	b = Math.min(255, Math.max(0, b));

	// Konvertieren der RGB-Werte zurück in HEX
	r = Math.round(r).toString(16).padStart(2, '0');
	g = Math.round(g).toString(16).padStart(2, '0');
	b = Math.round(b).toString(16).padStart(2, '0');
	return `#${r}${g}${b}`;
}

const Login = () => {
	const navigate = useNavigate();
	const { handleLogin, users, firms, callBackend, objConfig } = useSharedData();

	const [login, setLogin] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");

	// Determine if the login button should be disabled
	const isButtonDisabled = !login || !password;

	if (firms[0]) {
		var r = document.querySelector(':root');

		r.style.setProperty('--mainColor', firms[0]?.strCompanyColor);
		r.style.setProperty('--secondColor', aufhellen(firms[0]?.strCompanyColor, 30));
		r.style.setProperty('--thirdColor', aufhellen(firms[0]?.strCompanyColor, 80));
	}

	const handleLoginClick = (event) => {
		event.preventDefault();
		callBackend({
			action: "login",
			payload: { strPassword: password, strUserName: login },
		})
			.then((rst) => {
				if (rst.serverAction === "login_success") {
					const user = users.find(
						(u) => u.pkUserID === Number(rst.pkUserID)
					);
					handleLogin(user);
					navigate("/promptspeichern");
				} else {
					setError("Ungültiger Login oder Passwort");
				}
			})
			.catch((err) => console.log(err));
	};

	return (objConfig?.strUrl?.length > 0 && firms[0] ?
		<div class="login-body">


			<div className="Login">
				<nav className="login-nav">

					{/* <div className="image-logo">
						<img src={firmenpunktB} />
					</div>
					<div className="logo">
						<img src={LogoImage} alt="Promptothek a library for everything" />
					</div>
					<div className="Language-Box">
						<span className="languages">DE</span>
						<span className="languages login-chosen">EN</span>
					</div> */}
					<div class='align-nav'>
						<div class="logo">
							<img src={firms[0]?.strCompanyLogo || firmenpunktB} alt="" class="image-logo" />
							<h1>PROMPTOTHEK</h1>
						</div>
					</div>
				</nav>

				<div className="login">
					<header className="login-header">
						<h1 className="login-title">LOGIN</h1>
						<p>Sign in to continue</p>
					</header>

					<form onSubmit={handleLoginClick}
						className="Form--log"
						action=""
						method="get"
						autoComplete="off"
					>
						<div className="username">
							<img src={profileUser} alt="" />
							<div className="input-username">
								<label htmlFor="strUserName" className="login-label">USERNAME</label>
								<input
									id="username"
									name="strUserName"
									type="text"
									placeholder="Username"
									autoComplete="username"
									value={login}
									onChange={(e) => setLogin(e.target.value)}
									required
								/>
							</div>
						</div>
						<div className="password">
							<img src={padlock} alt="" />
							<div className="input-password">
								<label htmlFor="strPassword" className="login-label">PASSWORD</label>
								<input
									id="Password"
									name="strPassword"
									type="password"
									placeholder="Passwort"
									autoComplete="current-password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									required
								/>
							</div>
						</div>
						{/* JS MUST BE USED TO REMIND THE USER THAT SHIFT LOCK IS ON */}
						{/* <div className="remember">
					<input type="checkbox" id="remember" />
					<label htmlFor="remember" className="login-label">Remember me</label>
				</div> */}
						<div className="button">
							<button
								id="login"
								type="submit"

								disabled={isButtonDisabled}
							>
								Log in
							</button>
							<p style={{ color: "white" }}>{error}</p>
						</div>
					</form>
				</div>

				<footer className="login-footer">
					<div className="Firmenpunkt-Logo">
						<span>A PRODUCT OF <img src={firmenpunkt} alt="Firmenpunkt GMBH" className="Firmenpunkt-Image" /></span>
					</div>
				</footer>
			</div>



		</div>
		: <></>
	);




	// 	<div className="LoginSeite">
	// 	{/* <Header items={pageName} /> */}

	// 	<div className="Logo_login">
	// 		<img id="Logo" name="Logo" src={LogoImage} alt="" srcSet="" />
	// 	</div>

	// 	<section className="LoginForm">
	// 		<h2>Login</h2>
	// 		<form
	// 			onSubmit={handleLoginClick}
	// 			className="Form--log"
	// 			action=""
	// 			method="get"
	// 			autoComplete="off"
	// 		>
	// 			<div>
	// 				<label htmlFor="Benutzername">Benutzername:</label>
	// 				<input
	// 					id="Benutzername"
	// 					name="Benutzername"
	// 					type="text"
	// 					placeholder="Login"
	// 					autoComplete="username"
	// 					value={login}
	// 					onChange={(e) => setLogin(e.target.value)}
	// 					required
	// 				/>
	// 			</div>

	// 			<div>
	// 				<label htmlFor="strPasswort"> Passwort:</label>
	// 				<input
	// 					id="Passwort"
	// 					name="strPasswort"
	// 					type="password"
	// 					placeholder="Passwort"
	// 					autoComplete="current-password"
	// 					value={password}
	// 					onChange={(e) => setPassword(e.target.value)}
	// 					required
	// 				/>
	// 			</div>

	// 			<div className="LoginButtonDiv">
	// 				<button
	// 					id="LoginSubmitButton"
	// 					type="submit"

	// 					disabled={isButtonDisabled}
	// 				>
	// 					Anmelden
	// 				</button>
	// 				<p style={{ color: "red" }}>{error}</p>
	// 			</div>
	// 		</form>
	// 	</section>
	// </div>
};
export default Login;
