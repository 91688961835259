import React, { useEffect, useRef } from "react";

import DepartmentItem from "./Departments/DepartmentItem";
import EigenschaftItem from "./Kategorien_Unterkategorien_Eigenschaften/EigenschaftItem";
import KategorieForm from "./Kategorien_Unterkategorien_Eigenschaften/KategorieForm";
import TagItem from "./Tags/TagItem";

import { useState } from "react";
import { useSharedData } from "../SharedDataContext";

import DepartmentComponent from "./Departments/DepartmentComponent";
import KategorienComponent from "./Kategorien_Unterkategorien_Eigenschaften/KategorienComponent";

import BookmarkOn from "../images/bookmark_on.svg";
import BookmarkOff from "../images/bookmark_off.svg";
import Star from "../images/star.png"
import Return from "../images/return.svg";
import Edit from "../images/edit.svg";
import Copy from "../images/copy.svg";
import Delete from "../images/delete.svg";
import Insert from "../images/insert.svg";
import Cancel from "../images/cancel.svg"
import Canceled from "../images/cancelled.svg"
import Save from "../images/save.svg"
import Saved from "../images/saved.svg"

import { useLocation } from "react-router-dom";
import { AiFillPlusCircle } from "react-icons/ai";
import { useNavigate } from "react-router";

import { FaRegCopy } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";

import swal from "sweetalert";
import { CollectionsOutlined } from "@mui/icons-material";

//
// items,
// setItems,

const PromptItemDetails = ({ loggedInUser }) => {
	const location = useLocation();

	const navigate = useNavigate();
	const { labels, setLabels } = useSharedData();

	const {
		prompts,
		setPrompts,
		tags,
		setTags,
		users,
		departments,
		markiertePrompts,
		setMarkiertePrompts,
		promptDepartments,
		promptCategories,
		promptTags,
		setPromptTags,
		callBackend,
		kategorieList,
		unterkategorieList,
	} = useSharedData();

	const [departmentWert, setDepartmentWert] = useState([]);
	const [eigenschaftenWert, setEigenschaftenWert] = useState([]);
	const promptId = Number(location.pathname.split("/")[2]);
	const prompt = prompts.find((item) => item.pkPromptID === promptId);
	if (typeof prompt !== "object" || typeof loggedInUser === "undefined") {
		window.location.href = "/";
	}
	const eigenschaftIDs = promptDepartments.filter(
		(entry) => entry.fkPromptID == promptId
	);
	const kategorieIDs = promptCategories.filter(
		(entry) => entry.fkPromptID == promptId
	);
	const [marked, setMarked] = useState(0);

	const objAutor = users.find((item) => item.pkUserID === prompt?.fkUserID);
	const autor = `${objAutor.strFirstName} ${objAutor.strSurName}`;
	const userBereich = departments
		.filter((dep) =>
			promptDepartments
				.filter((obj) => obj.fkPromptID === promptId)
				.map((obj2) => obj2.fkDepartmentID)
				.includes(dep.pkDepartmentID)
		)
		.map((dep) => dep.strDepartment)
		.join(", ");

	useEffect(() => {
		const selectedPrompt = markiertePrompts.filter(
			(item) =>
				item.fkUserID === loggedInUser?.pkUserID &&
				item.fkPromptID === promptId
		);
		if (!selectedPrompt || selectedPrompt.length === 0) {
			setMarked(0);
		} else {
			setMarked(1);
		}

		const filtered = eigenschaftIDs.map((element) => ({
			id: element.fkDepartmentID,
			eigenschaftName: `${departments.find(
				(kategorie) =>
					kategorie.pkDepartmentID === element.fkDepartmentID
			)?.strDepartment
				}`,
		}));
		setDepartmentWert(filtered);

		const filtered2 = kategorieIDs.map((element) => ({
			id: element.pkPromptCategoryID,
			eigenschaftName: `${kategorieList.find(
				(kategorie) =>
					kategorie.pkDataPromptCategoryID ===
					element.fkDataPromptCategoryID
			)?.strPromptCategoryName
				}${element.fkDataPromptSubCategoryID == -10 ? '' : ": " + unterkategorieList.find(
					(unterKategorie) =>
						unterKategorie.pkDataPromptSubCategoryID ===
						element.fkDataPromptSubCategoryID
				)?.strPromptSubCategoryName}
				`,
		}));

		setEigenschaftenWert(filtered2);
	}, [promptCategories]);

	let changeable = () => {
		if (loggedInUser === undefined || loggedInUser === null) {
			return false;
		} else {
			if (
				prompts.find((item) => item.pkPromptID === promptId)
					?.fkUserID === loggedInUser.pkUserID ||
				loggedInUser.fkDataRoleID > 1
			) {
				return true;
			} else return false;
		}
	};
	const isChangeable = changeable();

	let thisTagName = (TagID) => {
		return tags.find((item) => item.pkDataPromptTagID === TagID)
			?.strDataPromptTag;
	};

	const handleMarkieren = () => {
		setMarked(1);
		const newMarkiertPrompt = {
			fkPromptID: promptId,
			fkUserID: loggedInUser.pkUserID,
			Datum: new Date(),
		};

		markiertePrompts.push(newMarkiertPrompt);
		setMarkiertePrompts(markiertePrompts);
		callBackend({
			action: "save_bookmark",
			payload: { fkPromptID: promptId, fkUserID: loggedInUser.pkUserID },
		});
	};
	const handleNichtMarkieren = () => {
		setMarked(0);
		let neueListe = markiertePrompts.filter(
			(item) => item.fkPromptID !== promptId
		);
		setMarkiertePrompts(
			neueListe.map((item, index) => {
				item.lfdMarkedID = index + 1;
				return item;
			})
		);
		callBackend({
			action: "delete_bookmark",
			payload: { fkPromptID: promptId, fkUserID: loggedInUser.pkUserID },
		});
	};
	const handleFreigabe = () => {
		callBackend({
			action: "update_prompt",
			payload: {
				pkPromptID: prompt.pkPromptID,
				intPublicPrompt: (prompt.intPublicPrompt + 1) % 2,
			},
		});
		setPrompts(
			prompts.map((obj) =>
				obj.pkPromptID === prompt.pkPromptID
					? {
						...obj,
						intPublicPrompt: (prompt.intPublicPrompt + 1) % 2,
					}
					: obj
			)
		);
	};
	
	const handleLoeschen = () => {
		

		swal(labels.lblDeletePromptCheck, {
			buttons: {
				cancel:labels.lblYes ,
				confirm: labels.lblNo,
			},
		}).then((value) => {
			if(!value){
				if (marked === 1) {
					setMarkiertePrompts(
						markiertePrompts.filter((item) => item.fkPromptID !== promptId)
					);
					setPrompts(prompts.filter((item) => item.pkPromptID !== promptId));
				} else {
					setPrompts(prompts.filter((item) => item.pkPromptID !== promptId));
				}
				callBackend({
					action: "delete_prompt",
					payload: { pkPromptID: promptId },
				});
				callBackend({
					action: "delete_bookmark",
					payload: { fkPromptID: promptId },
				});
				callBackend({
					action: "delete_prompt_category",
					payload: { fkPromptID: promptId },
				});
				callBackend({
					action: "delete_prompt_department",
					payload: { fkPromptID: promptId },
				});
				callBackend({
					action: "delete_prompt_tag",
					payload: { fkPromptID: promptId },
				});
				
				navigate("/ergebnis");			}
		});
	};

	const handleZurueck = () => {
		navigate("/ergebnis");
	};

	const promptRef = useRef(null);
	const ergebnisRef = useRef(null);
	const noteRef = useRef(null)
	const [isPopupVisible1, setPopupVisible1] = useState(false);
	const [isPopupVisible2, setPopupVisible2] = useState(false);
	const [isPopupVisible3, setPopupVisible3] = useState(false);
	const handleCopyPrompt = () => {
		// const textToCopy = promptRef.current.value
		// navigator.clipboard.writeText(textToCopy)

		const range = document.createRange();
		range.selectNode(promptRef.current);
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);

		try {
			document.execCommand("copy");
			// You can provide feedback here, e.g., show a success message
			// Show the "kopiert erfolgreich" popup for a brief moment
			setPopupVisible1(true);
			setTimeout(() => {
				setPopupVisible1(false);
			}, 1500); // Adjust the timeout as needed (2 seconds in this example)
		} catch (error) {
			// Handle any errors that may occur during copying
			console.error("Copy failed:", error);
			// You can also provide feedback in case of an error
		} finally {
			window.getSelection().removeAllRanges();
		}
	};

	const handleCopyErgebnis = () => {
		// const textToCopy = ergebnisRef.current.value
		// navigator.clipboard.writeText(textToCopy)

		const range = document.createRange();
		range.selectNode(ergebnisRef.current);
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);

		try {
			document.execCommand("copy");
			// You can provide feedback here, e.g., show a success message
			// Show the "kopiert erfolgreich" popup for a brief moment
			setPopupVisible2(true);
			setTimeout(() => {
				setPopupVisible2(false);
			}, 1500); // Adjust the timeout as needed (2 seconds in this example)
		} catch (error) {
			// Handle any errors that may occur during copying
			console.error("Copy failed:", error);
			// You can also provide feedback in case of an error
		} finally {
			window.getSelection().removeAllRanges();
		}
	};

	const handleCopyNote = () => {
		// const textToCopy = ergebnisRef.current.value
		// navigator.clipboard.writeText(textToCopy)

		const range = document.createRange();
		range.selectNode(noteRef.current);
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);

		try {
			document.execCommand("copy");
			// You can provide feedback here, e.g., show a success message
			// Show the "kopiert erfolgreich" popup for a brief moment
			setPopupVisible3(true);
			setTimeout(() => {
				setPopupVisible3(false);
			}, 1500); // Adjust the timeout as needed (2 seconds in this example)
		} catch (error) {
			// Handle any errors that may occur during copying
			console.error("Copy failed:", error);
			// You can also provide feedback in case of an error
		} finally {
			window.getSelection().removeAllRanges();
		}
	};

	const [isEditing_notiz, setIsEditing_notiz] = useState(false);
	const [editedComment, setEditedComment] = useState(prompt.txtComment);

	const handleNotizBearbeiten = () => {
		prompts.map((obj) => {
			if (obj.pkPromptID === prompt.pkPromptID) {

				setEditedComment(obj.txtComment)
			}
		}
		)
		setIsEditing_notiz(!isEditing_notiz);
	};

	const handleCommentChange = (event) => {
		setEditedComment(event.target.value);
	};

	const handleSaveComment = () => {
		// Save the edited comment (you can implement this logic)
		setIsEditing_notiz(false);
		callBackend({
			action: "update_prompt",
			payload: {
				pkPromptID: prompt.pkPromptID,
				txtComment: editedComment,
			},
		});
		setPrompts(
			prompts.map((obj) =>
				obj.pkPromptID === prompt.pkPromptID
					? { ...obj, txtComment: editedComment }
					: obj
			)
		);
	};

	const [isEditing_title, setIsEditing_title] = useState(false);
	const [editedTitle, setEditedTitle] = useState(prompt.strPromptName);

	const handleTitleBearbeiten = () => {
		setIsEditing_title(!isEditing_title);
	};

	const handleTitleChange = (event) => {
		setEditedTitle(event.target.value);
	};

	const handleSaveTitle = () => {
		// Save the edited comment (you can implement this logic)
		setIsEditing_title(false);
		callBackend({
			action: "update_prompt",
			payload: {
				pkPromptID: prompt.pkPromptID,
				strPromptName: editedTitle,
			},
		});
		setPrompts(
			prompts.map((obj) =>
				obj.pkPromptID === prompt.pkPromptID
					? { ...obj, strPromptName: editedTitle }
					: obj
			)
		);
	};

	const addTag = () => {
		swal("Geben Sie einen neuen Tag ein", {
			content: {
				element: "input",
				attributes: { id: "input_tags", type: "text" },
			},
		}).then((tagValue) => {
			if (tagValue === null || tagValue.trim() === "") {
				swal("Kein Tag wird hinzugefügt!");
			} else {
				const tagAssigned = promptTags
					.filter((entry) => entry.fkPromptID == promptId)
					.map((entry) => entry.fkDataPromptTagID)
					.some(
						(item) =>
							thisTagName(item).toLowerCase() ===
							tagValue.toLowerCase()
					);
				const tagExists = tags.some(
					//ToDo: Tag auch auf User überprüfen?
					(obj) =>
						obj.strDataPromptTag.toLowerCase() ===
						tagValue.toLowerCase()
				);
				if (tagAssigned) {
					swal(`Das Tag '${tagValue}' existiert schon!`);
				} else if (tagExists) {
					const tagID = tags.find(
						(item) =>
							item.strDataPromptTag.toLowerCase() ===
							tagValue.toLowerCase()
					)?.pkDataPromptTagID;

					callBackend({
						action: "save_prompt_tag",
						payload: {
							fkPromptID: promptId,
							fkDataPromptTagID: tagID,
						},
					}).then(() => { });

					const datNow = new Date();
					const newPromptTag = {
						fkPromptID: promptId,
						fkDataPromptTagID: tagID,
						intStatus: 1,
						datTsControl: `${datNow.getFullYear()}-${datNow.getMonth() + 1
							}-${datNow.getDate()}`,
					};
					setPromptTags([...promptTags, newPromptTag]);
				} else {
					callBackend({
						action: "save_tag",
						payload: {
							strDataPromptTag: tagValue,
							fkUserID: loggedInUser.pkUserID,
						},
					})
						.then((rst) => {
							const datNow = new Date();
							const newTag = {
								pkDataPromptTagID: rst.pkDataPromptTagID,
								strDataPromptTag: tagValue,
								fkUserID: loggedInUser.pkUserID,
								Datum: `${datNow.getFullYear()}-${datNow.getMonth() + 1
									}-${datNow.getDate()}`,
							};
							setTags([...tags, newTag]);

							callBackend({
								action: "save_prompt_tag",
								payload: {
									fkPromptID: promptId,
									fkDataPromptTagID: rst.pkDataPromptTagID,
								},
							}).then(() => { });

							const newPromptTag = {
								fkPromptID: promptId,
								fkDataPromptTagID: rst.pkDataPromptTagID,
								intStatus: 1,
								datTsControl: `${datNow.getFullYear()}-${datNow.getMonth() + 1
									}-${datNow.getDate()}`,
							};
							setPromptTags([...promptTags, newPromptTag]);
						})
						.catch((err) => console.log(err));
				}
			}
		});
		document.getElementById("input_tags").setAttribute("list", "tags");
	};

	const [openAddEigenschaft, setOpenAddEigenschaft] = useState(false);

	const addEigenschschaft = () => {
		setOpenAddEigenschaft(!openAddEigenschaft);
	};

	const [exportedData, setExportedData] = useState(null);

	const handleExport = () => {
		// Collect and format the data you want to export here
		const dataToExport = {
			PromptID: promptId,
			Name: prompt.Name,
			Prompt: prompt.Input,
			Ergenis: prompt.Output,
			Notiz: prompt.Comment,
			Arbeitsbereiche: departmentWert.map((item) => item.eigenschaftName),
			Eigenschaften: eigenschaftenWert.map(
				(item) => item.eigenschaftName
			),
			Tags: promptTags
				.filter((entry) => entry.fkPromptID == promptId)
				.map((entry) => entry.fkDataPromptTagID)
				.map((item, index) => thisTagName(item)),
			Autor: autor,
			Datum: prompt.Date,

			// Add other properties as needed
		};

		// Convert the data to a JSON string
		const dataString = JSON.stringify(dataToExport);

		// Create a Blob containing the JSON data
		const blob = new Blob([dataString], { type: "application/json" });

		// Create a URL for the Blob
		const blobURL = URL.createObjectURL(blob);

		// Set the exported data in the component state
		setExportedData(blobURL);
	};
	document.addEventListener("DOMContentLoaded", function () {
		const copyIcons = document.querySelectorAll('.copy');

		copyIcons.forEach(icon => {
			icon.addEventListener('click', () => {
				const textToCopy = icon.closest('.h4-copy').nextElementSibling.textContent;

				navigator.clipboard.writeText(textToCopy).then(() => {
					const copyAlert = document.createElement('div');
					copyAlert.classList.add('copy-alert');
					copyAlert.textContent = 'Text copied!';
					icon.parentElement.appendChild(copyAlert);

					setTimeout(() => {
						copyAlert.remove();
					}, 2000);
				}).catch(err => {
					console.error('No se pudo copiar el texto: ', err);
				});
			});
		});
	});

	const fnCheckImageURL = (strTest)=>{
		return /^data:image\/\S{3,};base64,/.test(strTest);
	}

	return (
		<>
			<div className="view-body body">
				<div className="title-view">
					<div>
						<a onClick={handleZurueck}>
							<img class="return" src={Return} alt="Return" />
						</a>
						<h2 class="edit-title">{editedTitle}</h2>
						<div>

						{prompt.intTemplate === 1 && (
								<img
									className="highlighted"
									src={Star}
								/>
							)}

							{marked === 1 ? (
								<img
									className="highlighted"
									onClick={handleNichtMarkieren}
									src={BookmarkOn}
								/>
							) : (
								<img
									className="non-highlighted"
									onClick={handleMarkieren}
									src={BookmarkOff}
								/>
							)}

						</div>
					</div>
				</div>

				<div className="div-border">
					<div class="h4-copy">
						<h4>{labels.lblPromptInput}:</h4>
						<img class="copy" src={Copy} onClick={handleCopyPrompt} alt="Copy prompt"></img>
						{isPopupVisible1 && (
							<div id="CopyTextPrompt" class="copy-alert">Text copied!</div>
						)}

					</div>
					<p className="prompt" ref={promptRef}>
						{prompt.txtImput}
					</p>
				</div>
				<div class="div-border">
					<div class="h4-copy">
						<h4>{labels.lblAIOutput}:</h4>
						<img class="copy" onClick={handleCopyErgebnis} src={Copy} alt="Copy prompt" />
						{isPopupVisible2 && (
							<div id="CopyTextOutput" class="copy-alert">Text copied!</div>
						)}

					</div>
					{fnCheckImageURL(prompt.txtOutput)?<img class="output" src={prompt.txtOutput}></img>:<p class="output" ref={ergebnisRef}>
						{prompt.txtOutput}
					</p>}
				</div>
				<div class="div-border">
					<div class="h4-copy">
						<h4>{labels.lblNote}:</h4>
						<div class="edit-options">
							{isEditing_notiz ? (
								<>
									<div class="copy-alert hidden">Text copied!</div>
									<button class="exit-edit" onClick={handleNotizBearbeiten}>Cancel</button>
									<button class="save-edit" onClick={handleSaveComment}>{labels.lblSave}</button>
								</>
							) : (
								<>
									<img class="edit" src={Edit} onClick={handleNotizBearbeiten} alt="Edit" />
									<img class="copy" src={Copy} onClick={handleCopyNote} alt="Copy prompt" />
									{isPopupVisible3 && (
										<div id="CopyTextNote" class="copy-alert">Text copied!</div>
									)}
								</>
							)}


						</div>
					</div>

					{isEditing_notiz ? (
						<textarea
							class="note edit-mode"
							value={editedComment}
							onChange={handleCommentChange}
						/>
					) : (
						<p class="note" ref={noteRef}>{editedComment}</p>
						// <span>{editedComment}</span>
					)}

				</div>
				<div class="info">
					<div class="yes-br">
						<h4>{labels.lblCategory}:</h4>
						<div className="category-list">
							{eigenschaftenWert.map((item, index) => (
								<EigenschaftItem
									key={`Category_${index}`}
									id={item.id}
									name={item.eigenschaftName}
									isChangeable={isChangeable}
									isFullView={true}
								/>
							))}
							{isChangeable && (
								<img src={Insert} alt="insert category" class="insert" onClick={addEigenschschaft} />
								// <AiFillPlusCircle onClick={addEigenschschaft} />
							)}

						</div>

						<h4>{labels.lblTag}:</h4>
						<div className="tag-list">
							{promptTags
								.filter((entry) => entry.fkPromptID == promptId)
								.map((entry) => entry.fkDataPromptTagID)
								.map((item, index) => (
									<TagItem
										key={`Tag_${index}`}
										tagID={item}
										promptID={promptId}
										name={thisTagName(item)}
										isChangeable={isChangeable}
										isFullView={true}
									/>
								))}
							{isChangeable && <img src={Insert} alt="insert tag" class="insert" onClick={addTag} />}
						</div>

						

					</div>
					
					<div>
						<div className="no-br">
						    <h4>{labels.lblAuthor}:</h4>
							<p> {autor}</p>
						</div>
						<div className="no-br">
							<h4>{labels.lblDepartment}: </h4>
							<p> {userBereich}</p>
						</div>
						<div className="no-br">
							<h4>{labels.lblDate}: </h4>
							<p> {prompt.datCreate}</p>
						</div>
					</div>
				</div>

				{isChangeable === true ? (
							<button class="delete-button" onClick={handleLoeschen}>{labels.lblDelete}</button>
						) : (
							""
						)}


				{/* <div className="buttonGruppe" style={{ textAlign: "center" }}>
				{loggedInUser.fkDataRoleID > 1 && (
						<button onClick={handleFreigabe}>
							{prompt.intPublicPrompt ? "Verbergen" : "Freigeben"}
						</button>
					)}
				{marked === 0 ? (
					<button onClick={handleMarkieren}>{labels.lblMark}</button>
				) : marked === 1 ? (
					<button onClick={handleNichtMarkieren}>
						{labels.lblNoMark}
					</button>
				) : null}

				<a
					href={exportedData}
					download={`exported_${prompt.Name}.json`}
				>
					<button onClick={handleExport}> {labels.lblExport} </button>
				</a>

				{isChangeable === true ? (
					<button onClick={handleLoeschen}>{labels.lblDelete}</button>
				) : (
					""
				)}
				<button onClick={handleZurueck}> {labels.lblBack} </button>
			</div>
			<datalist id="tags">
				{tags.map((elm) => (
					<option
						value={elm.strDataPromptTag}
						key={`tag_option_${elm.pkDataPromptTagID}`}
					/>
				))}
			</datalist> */}
			</div>
			{openAddEigenschaft === true ? (
				<div id="categoryModal" class="modal">
					<div class="category-modal-content">
						<div class="form-close">
							<div>
								<form action="#">
									<KategorienComponent
										promptID={promptId}
										eigenschaftenWert={eigenschaftenWert}
										setEigenschaftenWert={setEigenschaftenWert}
										kategorieList={kategorieList}
										unterkategorieList={unterkategorieList}
										openAddEigenschaft={openAddEigenschaft}
										addEigenschschaft={addEigenschschaft}
									/>
								</form>
							</div>
							<span onClick={addEigenschschaft} class="category-close">&times;</span>
						</div>
					</div>
				</div>
			) : (
				""
			)}
		</>
	);
	return (
		<div className="PromptItemDetailsScroll">
			<div className="PromptItemDetails">
				<div className="details-title">
					{isEditing_title ? (
						<input
							onChange={handleTitleChange}
							value={editedTitle}
						/>
					) : (
						<h2>{editedTitle}</h2>
					)}
					{isChangeable === true ? (
						<span
							className="spanButton"
							onClick={handleTitleBearbeiten}
						>
							{isEditing_title ? (
								<button onClick={handleSaveTitle}>{labels.lblSave}</button>
							) : (
								<FiEdit />
							)}
						</span>
					) : (
						""
					)}
				</div>

				<div className="details-eingabe">
					<span className="spanTitel">{labels.lblPromptInput}: </span>
					<span className="spanContent" ref={promptRef}>
						{prompt.txtImput}
					</span>

					<span className="spanButton" onClick={handleCopyPrompt}>
						<FaRegCopy />
					</span>
					{isPopupVisible1 && (
						<div className="popup">{labels.lblCopy}</div>
					)}
				</div>
				<div className="details-ausgabe">
					<span className="spanTitel">{labels.lblAIOutput}: </span>

					<span className="spanContent" ref={ergebnisRef}>
						{prompt.txtOutput}
					</span>
					<span className="spanButton" onClick={handleCopyErgebnis}>
						<FaRegCopy />
					</span>
					{isPopupVisible2 && (
						<div className="popup">{labels.lblCopy}</div>
					)}
				</div>
				<div className="details-comment">
					<span className="spanTitel">{labels.lblNote}: </span>
					{isEditing_notiz ? (
						<textarea
							value={editedComment}
							onChange={handleCommentChange}
						/>
					) : (
						<span>{editedComment}</span>
					)}
					{isChangeable === true ? (
						<span
							className="spanButton"
							onClick={handleNotizBearbeiten}
						>
							{isEditing_notiz ? (
								<button onClick={handleSaveComment}>
									{labels.lblSave}
								</button>
							) : (
								<FiEdit />
							)}
						</span>
					) : (
						""
					)}
				</div>
				<div className="details-autor">{labels.lblAuthor}: {autor}</div>
				<div className="details-bereich">{labels.lblDepartment}: {userBereich}</div>
				<div className="details-datum">{labels.lblDate}: {prompt.datCreate}</div>
				<div className="details-mark">
					{marked === 1 ? (
						<img
							className="bookmark"
							onClick={handleNichtMarkieren}
							src={BookmarkOn}
						/>
					) : (
						<img
							className="bookmark"
							onClick={handleMarkieren}
							src={BookmarkOff}
						/>
					)}
				</div>
				{/* <div className="details-eigenschaften">
					<h4>Arbeitsbereiche:</h4>
					<div className="details-eigenschaften-container">
						{departmentWert.map((item, index) => (
							<DepartmentItem
								key={`Department_${index}`}
								departmentID={item.fkDepartmentID}
								promptID={promptId}
								departmentWert={departmentWert}
								setDepartmentWert={setDepartmentWert}
								name={item.eigenschaftName}
								isChangeable={isChangeable}
							/>
						))}
						{isChangeable && (
							<AiFillPlusCircle onClick={addEigenschschaft} />
						)}
						{openAddEigenschaft === true ? (
							<DepartmentComponent
								promptID={promptId}
								departmentWert={departmentWert}
								setDepartmentWert={setDepartmentWert}
							/>
						) : (
							""
						)}
					</div>
				</div> */}
				<div className="details-eigenschaften">
					<h4>{labels.lblCategory}:</h4>
					<div className="details-eigenschaften-container">
						{eigenschaftenWert.map((item, index) => (
							<EigenschaftItem
								key={`Category_${index}`}
								id={item.id}
								name={item.eigenschaftName}
								isChangeable={isChangeable}
							/>
						))}
						{isChangeable && (
							<AiFillPlusCircle onClick={addEigenschschaft} />
						)}
						{openAddEigenschaft === true ? (
							<KategorienComponent
								promptID={promptId}
								eigenschaftenWert={eigenschaftenWert}
								setEigenschaftenWert={setEigenschaftenWert}
								kategorieList={kategorieList}
								unterkategorieList={unterkategorieList}
							/>
						) : (
							""
						)}
					</div>
				</div>
				<div className="details-tags">
					<h4>{labels.lblTag}:</h4>
					<div className="details-tags-container">
						{promptTags
							.filter((entry) => entry.fkPromptID == promptId)
							.map((entry) => entry.fkDataPromptTagID)
							.map((item, index) => (
								<TagItem
									key={`Tag_${index}`}
									tagID={item}
									promptID={promptId}
									name={thisTagName(item)}
									isChangeable={isChangeable}
								/>
							))}
						{isChangeable && <AiFillPlusCircle onClick={addTag} />}
					</div>
				</div>
				<div className="buttonGruppe" style={{ textAlign: "center" }}>
					{/* {loggedInUser.fkDataRoleID > 1 && (
						<button onClick={handleFreigabe}>
							{prompt.intPublicPrompt ? "Verbergen" : "Freigeben"}
						</button>
					)} */}
					{marked === 0 ? (
						<button onClick={handleMarkieren}>{labels.lblMark}</button>
					) : marked === 1 ? (
						<button onClick={handleNichtMarkieren}>
							{labels.lblNoMark}
						</button>
					) : null}

					<a
						href={exportedData}
						download={`exported_${prompt.Name}.json`}
					>
						<button onClick={handleExport}> {labels.lblExport} </button>
					</a>

					{isChangeable === true ? (
						<button onClick={handleLoeschen}>{labels.lblDelete}</button>
					) : (
						""
					)}
					<button onClick={handleZurueck}> {labels.lblBack} </button>
				</div>
				<datalist id="tags">
					{tags.map((elm) => (
						<option
							value={elm.strDataPromptTag}
							key={`tag_option_${elm.pkDataPromptTagID}`}
						/>
					))}
				</datalist>
			</div>
		</div>
	);
};

export default PromptItemDetails;
