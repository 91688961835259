// SharedDataContext.jser
import React, {
	createContext,
	useContext,
	useEffect,
	useReducer,
	useState,
} from "react";
import axios from "axios";
// import tets from "../public/config.json"

const SharedDataContext = createContext();


//   export default loadConfig;
  

// const objConfig = {
// 	authToken: "",

// 	// strUrl: "https://fp.promptothek.de/prompt.backend.php",
// 	// strUrl: "https://default.promptothek.de/prompt.backend.php",

// 	// strUrl: "https://chesterton.promptothek.de/prompt.backend.php",
// 	// strUrl: "https://salini.promptothek.de/prompt.backend.php",
// 	// strUrl: "https://swavia.promptothek.de/prompt.backend.php",
// 	// strUrl: "https://tc.promptothek.de/prompt.backend.php",

// 	// strUrl: "https://4c.promptothek.de/prompt.backend.php",
// 	// strUrl: "https://usu.promptothek.de/prompt.backend.php",
// 	// strUrl: "https://cfocentre.promptothek.de/prompt.backend.php",
// 	// strUrl: "https://docubyte.promptothek.de/prompt.backend.php",
// };


export const useUser = () => {
	return useContext(SharedDataContext);
};

export const formatValues = (arrEntries) => {
	return arrEntries.map((obj) => {
		let objFormatted = {};
		Object.keys(obj).forEach((key) => {
			if (/^(pk|fk|int)/.test(key)) {
				Object.defineProperty(objFormatted, key, {
					value: Number(obj[key]),
					enumerable: true,
				});
			} else {
				Object.defineProperty(objFormatted, key, {
					value: obj[key],
					enumerable: true,
				});
			}
		});
		return objFormatted;
	});
};

export function SharedDataProvider({ children }) {
	const [kategorieList, setKategorieList] = useState([]);
	const [unterkategorieList, setUnterkategorieList] = useState([]);
	const [userZusBereichList, setUserZusBereichList] = useState([]);
	const [markiertePrompts, setMarkiertePrompts] = useState([]);
	const [loggedInUser, setLoggedInUser] = useState(null);
	const [error, setError] = useState(null);
	const [fkCompanyID, setFkCompanyID] = useState(null);

	const [promptCategories, setPromptCategories] = useState([]);
	const [promptDepartments, setPromptDepartments] = useState([]);
	const [promptTags, setPromptTags] = useState([]);
	const [filteredPrompts, setFilteredPrompts] = useState([]);

	const [departments, setDepartments] = useState([]);
	const [firms, setFirms] = useState([]);
	const [languages, setLanguages] = useState([]);
	const [outputs, setOutputs] = useState([]);
	const [platforms, setPlatforms] = useState([]);
	const [prompts, setPrompts] = useState([]);
	const [roles, setRoles] = useState([]);
	const [styles, setStyles] = useState([]);
	const [tags, setTags] = useState([]);
	const [tasks, setTasks] = useState([]);
	const [users, setUsers] = useState([]);
	const [accountRoles, setAccountRoles] = useState([]);
	const [userDepartments, setUserDepartments] = useState([]);
	const [zipCodes, setZipCodes] = useState([]);

	const [labels, setLabels] = useState([]);

	const [objConfig, setObjConfig] = useState({
		authToken:"",
		strUrl:""
	})

	useEffect(()=>{
		const loadConfig = async () => {
			const response = await fetch('/config.json');
			const config = await response.json();
			setObjConfig(config)
		  };
		  loadConfig()
	},[])

	const callBackend = async (objPayload) => {
		if (typeof objPayload.action != "string") {
			return { serverAction: "malformed_payload" };
		}
		let objResponse = {};
		if(objConfig.strUrl!=''){
		const res = await axios
			.post(objConfig.strUrl+"/prompt.backend.php", objPayload, {
				headers: { "content-type": "application/json" },
			})
			.catch((err) => {
				console.log(err);
				return {
					serverAction: "connection_error",
					status: err.response?.status,
					statusText: err.response?.statusText,
				};
			});
	
		objResponse = res.data;
		}
		return objResponse;
	};

	const objPromptDataFormat = {
		pkPromptID: 0,
		fkUserID: 0,
		fkDataPromptRoleID: 0,
		fkDataPromptProvideID: 0,
		fkDataPromptOutputID: 0,
		fkDataPromptPlatformID: 0,
		fkDataLanguageID: 0,
		strPromptName: null,
		intPromptKind: 0,
		txtSubject: null,
		txtImput: null,
		txtOutput: null,
		txtComment: null,
		intMinLength: 0,
		intMaxLength: 0,
		txtAudience: null,
		txtCallToAction: null,
		txtForm: null,
		intEmojis: 0,
		datCreate: null,
		intFlag: 0,
		intStatus: 0,
		datTsControl: null,
	};

	const fnHandlePromptData = (objState, { strID, value, action }) => {
		if (action === "reset_prompt") {
			return objPromptDataFormat;
		}
		let objChanges = {};
		Object.defineProperty(objChanges, strID, {
			value: value,
			enumerable: true,
		});
		return { ...objState, ...formatValues([objChanges])[0] };
	};

	const [promptData, setPromptData] = useReducer(
		fnHandlePromptData,
		objPromptDataFormat
	);

	const handleLogin = (user) => {
		setLoggedInUser(user);
		setError(null);

		const zusbereiche =
			user.fkDataRoleID > 1
				? departments
					.filter((dep) => dep.fkCompanyID === user.fkCompanyID)
					.map((dep) => ({
						fkDepartmentID: dep.pkDepartmentID,
						fkUserID: user.pkUserID,
					}))
				: userDepartments.filter((u) => u.fkUserID === user.pkUserID);

		setUserZusBereichList(zusbereiche);

		let filteredUserIDs = users
			.filter((u) => u.fkCompanyID === user.fkCompanyID)
			.map((item) => item.pkUserID);

		setFkCompanyID(user.fkCompanyID);

		setFilteredPrompts(
			prompts
				.filter((item) => filteredUserIDs.includes(item.fkUserID))
				.filter((item) => item.intPublicPrompt || user.fkDataRoleID > 1)
		);
	};

	const handleLogout = () => {
		setLoggedInUser(null);
	};

	useEffect(() => {
		if (loggedInUser) {
			let filteredBereiche = [];
			if (loggedInUser.fkDataRoleID > 1) {
				filteredBereiche = departments.filter(
					(dep) => dep.fkCompanyID === loggedInUser.fkCompanyID
				);
			} else {
				filteredBereiche = departments.filter((item) =>
					userDepartments
						.filter((u) => u.fkUserID === loggedInUser.pkUserID)
						.map((obj) => obj.fkDepartmentID)
						.includes(item.pkDepartmentID)
				);
			}

			setUserZusBereichList(filteredBereiche);
			setFilteredPrompts(
				prompts
					.filter((item) =>
						users
							.filter(
								(user) =>
									user.fkCompanyID ===
									loggedInUser.fkCompanyID
							)
							.map((user) => user.pkUserID)
							.includes(item.fkUserID)
					)
					.filter(
						(item) =>
							item.intPublicPrompt ||
							item.intTemplate ||
							loggedInUser.fkDataRoleID > 1
					)
			);
			callBackend({ action: "get_labels", payload: { fkDataLanguageID: loggedInUser.fkDataLanguageID } })
				.then((res) => {
					var newLabels = []
					var oldLabels = formatValues(res);

					for (let i = 0; i < oldLabels.length; i++) {

						newLabels[oldLabels[i]['strLabelID']] = []
						newLabels[oldLabels[i]['strLabelID']] = oldLabels[i]['strLabel']
					}
					setLabels(newLabels);
				})
				.catch((err) => console.log(err));
		}
	}, [prompts, loggedInUser, userDepartments]);

	// Initialize shared data here

	useEffect(() => {
		const f = async () => {
			if (tags.length === 0) {
				callBackend({ action: "get_tags" })
					.then((res) => {
						setTags(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (promptTags.length === 0) {
				callBackend({ action: "get_prompt_tags" })
					.then((res) => {
						setPromptTags(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (promptCategories.length === 0) {
				callBackend({ action: "get_prompt_categories" })
					.then((res) => {
						setPromptCategories(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (departments.length === 0) {
				callBackend({ action: "get_departments" })
					.then((res) => {
						setDepartments(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (firms.length === 0) {
				callBackend({ action: "get_firms" })
					.then((res) => {
						setFirms(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (languages.length === 0) {
				callBackend({ action: "get_languages" })
					.then((res) => {
						setLanguages(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (loggedInUser) {
				// if (outputs.length === 0) {
				callBackend({ action: "get_outputs", payload: { fkDataLanguageID: loggedInUser.fkDataLanguageID } })
					.then((res) => {
						setOutputs(formatValues(res));
					})
					.catch((err) => console.log(err));
				// }
				// if (roles.length === 0) {
					callBackend({ action: "get_roles", payload: { fkDataLanguageID: loggedInUser.fkDataLanguageID } })
						.then((res) => {
							setRoles(formatValues(res));
						})
						.catch((err) => console.log(err));
				// }
				// if (styles.length === 0) {
					callBackend({ action: "get_styles", payload: { fkDataLanguageID: loggedInUser.fkDataLanguageID } })
						.then((res) => {
							setStyles(formatValues(res));
						})
						.catch((err) => console.log(err));
				// }
				// if (tasks.length === 0) {
					callBackend({ action: "get_tasks", payload: { fkDataLanguageID: loggedInUser.fkDataLanguageID } })
						.then((res) => {
							setTasks(formatValues(res));
						})
						.catch((err) => console.log(err));
				// }
			}
			if (platforms.length === 0) {
				callBackend({ action: "get_platforms" })
					.then((res) => {
						setPlatforms(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (prompts.length === 0) {
				callBackend({ action: "get_prompts" })
					.then((res) => {
						setPrompts(formatValues(res));
					})
					.catch((err) => console.log(err));
			}

			if (markiertePrompts.length === 0) {
				callBackend({ action: "get_prompt_bookmarks" })
					.then((res) => {
						setMarkiertePrompts(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (promptDepartments.length === 0) {
				callBackend({ action: "get_prompt_departments" })
					.then((res) => {
						setPromptDepartments(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (users.length === 0) {
				callBackend({ action: "get_users" })
					.then((res) => {
						setUsers(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (userDepartments.length === 0) {
				callBackend({ action: "get_user_departments" })
					.then((res) => {
						setUserDepartments(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (zipCodes.length === 0) {
				callBackend({ action: "get_zipcodes" })
					.then((res) => {
						setZipCodes(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
			if (accountRoles.length === 0) {
				callBackend({ action: "get_account_roles" })
					.then((res) => {
						setAccountRoles(formatValues(res));
					})
					.catch((err) => console.log(err));
			}
		};
		f();
	}, [loggedInUser, objConfig]);

	// useEffect(() => {
	// 	if (prompts) {
	// 		console.log(prompts);
	// 	}
	// }, [prompts]);

	return (
		<SharedDataContext.Provider
			value={{
				accountRoles,
				setAccountRoles,
				tags,
				setTags,
				prompts,
				filteredPrompts,
				setPrompts,
				markiertePrompts,
				setMarkiertePrompts,
				kategorieList,
				setKategorieList,
				unterkategorieList,
				setUnterkategorieList,
				loggedInUser,
				setLoggedInUser,
				error,
				handleLogin,
				handleLogout,
				departments,
				setDepartments,
				users,
				setUsers,
				fkCompanyID,
				firms,
				setFirms,
				userZusBereichList,
				setUserZusBereichList,
				userDepartments,
				setUserDepartments,
				promptCategories,
				setPromptCategories,
				promptDepartments,
				setPromptDepartments,
				promptTags,
				setPromptTags,
				callBackend,
				promptData,
				setPromptData,
				languages,
				setLanguages,
				outputs,
				setOutputs,
				platforms,
				setPlatforms,
				roles,
				setRoles,
				styles,
				setStyles,
				tasks,
				setTasks,
				zipCodes,
				setZipCodes,
				labels,
				setLabels,
				formatValues,
				callBackend,
				objConfig
			}}
		>
			{children}
		</SharedDataContext.Provider>
	);
}

export function useSharedData() {
	return useContext(SharedDataContext);
}
