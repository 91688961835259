import React from 'react'
import { useState, useEffect } from 'react'
import Header from '../component/Header'
import PromptFilter from '../component/PropmtFilter'
import PromptList from '../component/PromptList'
import SeitenListe from '../component/SeitenListe'
import PromptItemDetails from '../component/PromptItemDetails'
import { useNavigate } from 'react-router'
import { formatValues, useSharedData } from '../SharedDataContext'

import { Link, useLocation } from 'react-router-dom'
import Footer from '../component/Footer'

const Ergebnis = () => {
  const { tags, setTags, callBackend } = useSharedData()
  const { setPrompts, setMarkiertePrompts, setPromptTags } = useSharedData();
  const { filteredPrompts , loggedInUser} = useSharedData()
  const [filteredprompts, setFilteredprompts] = useState(filteredPrompts)
  const {labels, setLabels} = useSharedData();
  const location = useLocation()
  const navigate = useNavigate()
  
  //Filter by Firm

  useEffect(() => {
    if (!loggedInUser) {
      navigate('/');
    } else {
      callBackend({ action: "get_prompts" })
        .then((res) => {
          setPrompts(formatValues(res));
        })
        .catch((err) => console.log(err));

      callBackend({ action: "get_prompt_bookmarks" })
        .then((res) => {
          setMarkiertePrompts(formatValues(res));
        })
        .catch((err) => console.log(err));

      callBackend({ action: "get_prompt_tags" })
        .then((res) => {
          setPromptTags(formatValues(res));
        })
        .catch((err) => console.log(err));
    }
  }, [])
  
  const [showButton, setShowButton] = useState(false)

  //Get the list of all users# ids

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowButton(location.pathname === '/ergebnis' ? false : true)
  }, [location])
  return(
    <div>
      <SeitenListe />
      
      {location.pathname === '/ergebnis' ? (
        <div class="body MainShape">
          <PromptFilter 
            filteredprompts={filteredPrompts}
            setFilteredprompts={setFilteredprompts}
          />
          <h1>{labels.rootSearch}</h1>
          <Link class="long-button" to={`/promptspeichern`}>
            {labels.lblAddNewPrompt}
          </Link>
          
          <PromptList
            filteredprompts={filteredprompts}
            tags={tags}
            setTags={setTags}
            loggedInUser={loggedInUser}
          />
        </div>
      ) : (
        <PromptItemDetails loggedInUser={loggedInUser} />
      )}
      
      <Footer/>
    </div>
  );

  return (
    <div>
      {/* <Header showButton={showButton} /> */}
      <SeitenListe />
      <section className='Ergebnisseite'>
        {location.pathname === '/ergebnis' ? (
          <div className='wrapper'>
            <div className='SeiteForm'>
              <PromptFilter 
              filteredprompts={filteredPrompts}
              setFilteredprompts={setFilteredprompts}
              />
            </div>
            <div className='HauptForm'>
              <PromptList
                filteredprompts={filteredprompts}
                tags={tags}
                setTags={setTags}
                loggedInUser={loggedInUser}
              />
            </div>
            
          </div>
        ) : (
          <PromptItemDetails loggedInUser={loggedInUser} />
        )}
      </section>
      <Footer/>
    </div>
  )
}

export default Ergebnis
