import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSharedData } from "../SharedDataContext";

import SeitenListe from "../component/SeitenListe";
import Footer from "../component/Footer";

const Notiz = () => {
	const { labels, loggedInUser } = useSharedData();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!loggedInUser) {
			navigate("/");
		}
	}, [loggedInUser, loggedInUser?.fkDataLanguageID]);

	return (
		<div>
			<SeitenListe />
			<div
				style={{
					width: "100%",
					marginTop: "210px",
					textAlign: "center",
				}}
			>
				<div
					style={{
						display: "inline-block",
						maxWidth: "1100px",
						textAlign: "left",
					}}
					dangerouslySetInnerHTML={{
						__html:
							location.pathname === "/datenschutzerklaerung"
								? labels.lblFullPrivacyPolicy
								: location.pathname === "/impressum"
								? labels.lblFullLegalNotice
								: "",
					}}
				/>
			</div>
			<Footer />
		</div>
	);
};

export default Notiz;
