import { useState } from 'react'
import { TiDelete } from 'react-icons/ti'
import { useSharedData } from "../../SharedDataContext";
import Delete from "../../images/delete.svg"

const EigenschaftItem = ({
  id,
  name,
  eigenschaftenWert,
  setEigenschaftenWert,
  isChangeable,
  isFullView,
  openAddEigenschaft = false
}) => {
  const [isVisible, setIsVisible] = useState(true)
  const { promptCategories, setPromptCategories, callBackend } = useSharedData();

  const handleDeleteClick = () => {
    setIsVisible(false)

    if (id != undefined) {

      setPromptCategories(promptCategories.filter((obj) => obj.pkPromptCategoryID !== id))
      callBackend({ action: "delete_prompt_category", payload: { pkPromptCategoryID: id } });
    } else {
      setEigenschaftenWert(eigenschaftenWert.filter((obj) => obj.eigenschaftName !== name));
    }
  }

  return (
    <div>
      {openAddEigenschaft && (
        isVisible && (
          <div class={isFullView ? "added-category" : "category-added"}>
            <span >
              {name}
            </span>
            {isChangeable === true ? (
              <img onClick={handleDeleteClick} class="delete" src={Delete} alt="Delete category"></img>

              // <TiDelete className='deleteButton' onClick={handleDeleteClick} />
            ) : (
              ''
            )}

          </div>
        )
      )}
      {!openAddEigenschaft && (

        <div class={isFullView ? "added-category" : "category-added"}>
          <span >
            {name}
          </span>
          {isChangeable === true ? (
            <img onClick={handleDeleteClick} class="delete" src={Delete} alt="Delete category"></img>

            // <TiDelete className='deleteButton' onClick={handleDeleteClick} />
          ) : (
            ''
          )}

        </div>

      )}


    </div>
  )
}

export default EigenschaftItem
