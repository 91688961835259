import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Login from './pages/Login'

import Error from './pages/Error'
import PromptSpeichern from './pages/PromptSpeichern'
import PromptErstellen from './pages/PromptErstellen'
import Dashboard from './pages/Dashboard'
import Einstellung from './pages/Einstellung'
import Ergebnis from './pages/Ergebnis'
import Abmelden from './pages/Abmelden'
import Notiz from './pages/Notiz'
import { SharedDataProvider } from './SharedDataContext'

function App() {
  return (
    <div>
      <BrowserRouter>
        <SharedDataProvider>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/abmelden' element={<Abmelden />} />
            <Route path='prompterstellen' element={<PromptErstellen />} />
            <Route path='prompterstellen/*' element={<PromptErstellen />} />
            <Route path='ergebnis/*' element={<Ergebnis />} />
            {/* <Route path='einstellung' element={<Einstellung />} /> */}
            <Route path='einstellung/*' element={<Einstellung />} />
            <Route path='promptspeichern' element={<PromptSpeichern />} />
            <Route path='datenschutzerklaerung' element={<Notiz />} />
            <Route path='impressum' element={<Notiz />} />
            <Route path='*' element={<Error />} />
          </Routes>
        </SharedDataProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
