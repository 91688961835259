import React, { useState, useRef, useEffect, useContext } from "react";
import { useSharedData } from "../SharedDataContext";
import { FaRegCopy } from "react-icons/fa";
import StructuralChat from "./StructuralChat";
import ChatAssistant from "./ChatAssistent";
import StandardChat from "./StandardChat";
import InputRow from "./InputRow";
import { validate } from "uuid";
import { selectClasses } from "@mui/material";
import CopySvg from "../images/copy.svg"
import swal from "sweetalert";
import img_delete from "../images/delete.svg"

const ChatComponent = ({
	setNoteValue,
	noteValue,
	setTitleValue,
	titleValue,
	ergebnisWert,
	setErgebnisWert,
	platforms,
	languages,
	selectedChatType,
	promptSave,
	setShowSave,
	showSave
}) => {
	const { promptData, setPromptData, outputs, roles, tasks } =
		useSharedData();

	const promptRef = useRef(null);
	const promptOutputRef = useRef(null);
	const [isPopupVisible1, setPopupVisible1] = useState(false);
	const [outputDisabled, setOutputDisabled] = useState(true);
	const [inputText, setInputText] = useState("");
	const [textString, setTextString] = useState("");
	const [textStringBackup, setTextStringBackup] = useState("");

	const [chatHistory, setChatHistory] = useState([]);
	const { labels, setLabels } = useSharedData();
	const [active, setActive] = useState(true)

	const [formData, setFormData] = useState({
		role: "",
		task: "",
		thema: "",
		stil: "",
		emojis: "",
		länge: {
			min: "",
			max: "",
		},
		zielgruppe: "",
		callToAction: "",
		form: "",
		sprache: "",
		format: "",
		platform: "",
	});

	const [roleValue, setRoleValue] = useState("");
	const [selectedRoleOption, setSelectedRoleOption] =
		useState("");
	const [taskValue, setTaskValue] = useState("");
	const [selectedTaskOption, setSelectedTaskOption] =
		useState("");

	const [formatValue, setFormatValue] = useState("");
	const [selectedFormatOption, setSelectedFormatOption] =
		useState("");
	const [platformValue, setPlatformValue] = useState("");
	const [selectedPlatformOption, setSelectedPlatformOption] =
		useState("");

	const handleInputChange = (event) => {
		setInputText(event.target.value);
	};

	const handleOutputChange = (event) => {
		setErgebnisWert(event.target.value);
	};

	useEffect(() => {
		setInputText("");
		setErgebnisWert("");
	}, [selectedChatType,titleValue]);

	const handleSubmit = (event) => {
		if(active){
			event.preventDefault();
			window.scrollTo(0, 0);
			swal(labels.lblProvideTitle)
		}else{
			event.preventDefault();

			if (!textString) return;
	
			const newMessage = {
				text: textString,
				sender: "user",
			};
			setInputText(textString);
			setOutputDisabled(false);
			setChatHistory([...chatHistory, newMessage]);
	
			// Simulate a response from an AI model (you can replace this with actual API calls)
			const responseMessage = {
				text: "This is a response from ChatGPT.",
				sender: "bot",
			};
			setChatHistory([...chatHistory, responseMessage]);
			
			
		}
		
	};

	// Function to handle changes in the StructuralChat component
	const handleTitleChange = (event) => {
		setTitleValue(event.target.value);
		
		
		// fnGenStructured('')
	}
	const handleNoteChange = (event) => {
		setNoteValue(event.target.value)
	}

	const handleCopyPrompt = () => {
		// const textToCopy = promptRef.current.value
		// navigator.clipboard.writeText(textToCopy)

		const range = document.createRange();
		range.selectNode(promptRef.current);
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range);

		try {
			document.execCommand("copy");
			// You can provide feedback here, e.g., show a success message
			// Show the "kopiert erfolgreich" popup for a brief moment
			setPopupVisible1(true);
			setTimeout(() => {
				setPopupVisible1(false);
			}, 2000); // Adjust the timeout as needed (2 seconds in this example)
		} catch (error) {
			// Handle any errors that may occur during copying
			console.error("Copy failed:", error);
			// You can also provide feedback in case of an error
		} finally {
			window.getSelection().removeAllRanges();
		}
	};

	const arrSimplePrompt = [
		{
			strRowLabel: labels.lblRole+":",
			arrInputs: [
				{
					strType: "select",
					options: roles.map((obj) => ({
						value: obj.pkDataPromptRoleID,
						strName: obj.strPromptRoleName,
					})),
					strVariableName: "fkDataPromptRoleID",
					value: promptData.fkDataPromptRoleID,
				},
				{
					strType: "text",
					
					strVariableName: "roleValue",
					strPlaceholder: labels.lblRolePlaceholder,
					value: roleValue || "",
				},
			],
			rows:1,
		},
		{
			strRowLabel: labels.lblTask+":",
			arrInputs: [
				{
					strType: "select",
					options: tasks.map((obj) => ({
						value: obj.pkDataPromptProvideID,
						strName: obj.strPromptProvideName,
					})),
					strVariableName: "fkDataPromptProvideID",
					value: promptData.pkDataPromptProvideID,
				},
				{
					strType: "text",
					strVariableName: "task",
					strPlaceholder: labels.lblTaskPlaceholder,
					
					value: taskValue || "",
				},
			],
			rows:5,
		},
		{
			strRowLabel: labels.lblFormat+":",
			arrInputs: [
				{
					strType: "select",
					options: outputs.map((obj) => ({
						value: obj.pkDataPromptOutputID,
						strName: obj.strPromptOutputName,
					})),
					strVariableName: "fkDataPromptOutputID",
					value: promptData.fkDataPromptOutputID,
				},
				{
					strType: "text",
					strVariableName: "formatValue",
					strPlaceholder: labels.lblFormatPlaceholder,
					value: formatValue || "",
				},
			],
		},
	];



	const arrBildPrompt = [
		{
			strRowLabel: labels.lblRole+":",
			arrInputs: [
				{
					strType: "select",
					options: roles.map((obj) => ({
						value: obj.pkDataPromptRoleID,
						strName: obj.strPromptRoleName,
					})),
					strVariableName: "fkDataPromptRoleID",
					value: promptData.fkDataPromptRoleID,
				},
				{
					strType: "text",
					
					strVariableName: "roleValue",
					strPlaceholder: labels.lblRolePlaceholder,
					value: roleValue || "",
				},
			],
			rows:1,
		},
		{
			strRowLabel: labels.lblTask+":",
			arrInputs: [
				{
					strType: "select",
					options: tasks.map((obj) => ({
						value: obj.pkDataPromptProvideID,
						strName: obj.strPromptProvideName,
					})),
					strVariableName: "fkDataPromptProvideID",
					value: promptData.pkDataPromptProvideID,
				},
				{
					strType: "text",
					strVariableName: "task",
					strPlaceholder: labels.lblTaskPlaceholder,
					
					value: taskValue || "",
				},
			],
			rows:5,
		},
		{
			strRowLabel: labels.lblFormat+":",
			arrInputs: [
				{
					strType: "select",
					options: outputs.map((obj) => ({
						value: obj.pkDataPromptOutputID,
						strName: obj.strPromptOutputName,
					})),
					strVariableName: "fkDataPromptOutputID",
					value: promptData.fkDataPromptOutputID,
				},
				{
					strType: "text",
					strVariableName: "formatValue",
					strPlaceholder: labels.lblFormatPlaceholder,
					value: formatValue || "",
				},
			],
		},
	];

	const fnCheckImageURL = (strTest)=>{
		return /^data:image\/\S{3,};base64,/.test(strTest);
	}


	const fnSimplePromptChange = (objInput) => {
		if (/^(pk|fk|int)/.test(objInput.strID)) {
			const insertValue = Number(objInput.value);
			setPromptData({
				strID: objInput.strID,
				value: insertValue && insertValue > 0 ? insertValue : 0,
			});
		} else if (/^(txt|dat|str)/.test(objInput.strID)) {
			setPromptData({ strID: objInput.strID, value: objInput.value });
		}

		const updatedFormData = structuredClone(formData);
		switch (objInput.strID) {
			case "fkDataPromptRoleID":
				const txtPromptRole =
					roles.find(
						(obj) =>
							obj.pkDataPromptRoleID === Number(objInput.value)
					)?.strPromptRoleName || "";
				setSelectedRoleOption(txtPromptRole);
				updatedFormData.role = `${txtPromptRole}${roleValue ? " " + roleValue : ""
					}`;
				break;
			case "roleValue":
				setRoleValue(objInput.value);
				updatedFormData.role = `${selectedRoleOption ? selectedRoleOption + " " : ""
					}${objInput.value}`;
				break;
			case "fkDataPromptOutputID":
				const txtPromptFormat =
					outputs.find(
						(obj) =>
							obj.pkDataPromptOutputID === Number(objInput.value)
					)?.strPromptOutputName || "";
				setSelectedFormatOption(txtPromptFormat);
				updatedFormData.format = `${txtPromptFormat}${formatValue ? " " + formatValue : ""
					}`;
				break;
			case "formatValue":
				setFormatValue(objInput.value);
				updatedFormData.format = `${selectedFormatOption ? selectedFormatOption + " " : ""
					}${objInput.value}`;
				break;
			case "fkDataPromptProvideID":
				const txtPromptTask =
					tasks.find(
						(obj) =>
							obj.pkDataPromptProvideID === Number(objInput.value)
					)?.strPromptProvideName || "";
				setSelectedTaskOption(txtPromptTask);
				updatedFormData.task = `${txtPromptTask}${taskValue ? " " + taskValue : ""
					}`;
				break;
			case "task":
				setTaskValue(objInput.value);
				updatedFormData.task = `${selectedTaskOption ? selectedTaskOption + " " : ""
					}${objInput.value}`;
				break;
			case "txtSubject":

				// setTaskValue(objInput.value);
				// updatedFormData.role = `${
				// 	selectedRoleOption ? selectedRoleOption + " " : ""
				// }${objInput.value}`;

				updatedFormData.thema = objInput.value;
				break;
			case "fkDataPromptPlatformID":
				const txtPromptPlatform =
					platforms.find(
						(obj) =>
							obj.pkDataPromptPlatformID ===
							Number(objInput.value)
					)?.strPromptPlatformName || "";
				setSelectedPlatformOption(txtPromptPlatform);
				updatedFormData.platform = `${txtPromptPlatform}${platformValue ? " " + platformValue : ""
					}`;
				break;
			case "platformValue":
				setPlatformValue(objInput.value);
				updatedFormData.platform = `${selectedPlatformOption ? selectedPlatformOption + " " : ""
					}${objInput.value}`;
				break;
			case "fkDataLanguageID":
				updatedFormData.sprache =
					languages.find(
						(obj) => obj.pkDataLanguageID === Number(objInput.value)
					)?.strLanguage || "";
				break;
			case "txtAudience":
				updatedFormData.zielgruppe = objInput.value;
				break;
			case "intMaxLength":
				const valMaxLength = Number(objInput.value);
				updatedFormData.länge.max =
					valMaxLength && valMaxLength > 0 ? valMaxLength : 0;
				break;
			case "intMinLength":
				const valMinLength = Number(objInput.value);
				updatedFormData.länge.min =
					valMinLength && valMinLength > 0 ? valMinLength : 0;
				break;
			case "txtCallToAction":
				updatedFormData.callToAction = objInput.value;
				break;
			case "intEmojis":
				const arrEmojis = ["", labels.lblFew, labels.lblMuch];
				updatedFormData.emojis = arrEmojis[objInput.value];
				break;
			case "txtForm":
				updatedFormData.form = objInput.value;
				break;
			default:
				break;
		}

		setFormData(updatedFormData);
	};

	const fnGenUnstructured = (values) => {
		setInputText("");
		setOutputDisabled(true);
		setErgebnisWert("");
		const { role = "", task = "", format = "" } = values;
		let structuredPrompt = "";
		// if (task) {

		// 	structuredPrompt = labels.lblTitle+': '+titleValue+'\n'
		// 	structuredPrompt += `${role ? `${labels.lblRole}: ${role} \n${labels.lblTask}:` : labels.lblTask+":"
		// 		} ${task}${format ? `\n${labels.lblFormat}: ${format}` : ""}`;
		// }
		structuredPrompt += role ? `${labels.lblRole}: ${role}\n` : "";
			structuredPrompt += task ? `${labels.lblTask}: ${task}\n` : "";
			structuredPrompt += format ? `${labels.lblFormat}: ${format}\n` : "";
		setTextString(structuredPrompt);
		setTextStringBackup(structuredPrompt)
	};
	const fnGenStructured = (values) => {
		setInputText("");
		setOutputDisabled(true);
		setErgebnisWert("");
		const {
			role,
			task,
			thema,
			stil, //ToDo: multiselect? texteingabe?
			emojis,
			länge,
			zielgruppe,
			callToAction,
			form,
			sprache,
			format,
			platform,
			
			object,
			adjective,
			position,
			picStyle,
			parameter,
			numberImg,
			onlyPrompt
		} = values;
		let structuredPrompt = "";

		if (selectedChatType === "option3") {
			// structuredPrompt = labels.lblTitle+': '+titleValue+'\n'
			// structuredPrompt += `${role ? `${labels.lblRole}: ${role} \n${labels.lblTask}:` : labels.lblTask+":"
			// 	} ${task}${format ? `\n${labels.lblFormat}: ${format}` : ""}`;
			// // structuredPrompt += task ? `\nThema: ${task}` : "";
			structuredPrompt += role ? `${labels.lblRole}: ${role}\n` : "";
			structuredPrompt += task ? `${labels.lblTask}: ${task}\n` : "";
			structuredPrompt += format ? `${labels.lblFormat}: ${format}\n` : "";

			structuredPrompt += stil ? `${labels.lblStyle}: ${stil}\n` : "";
			structuredPrompt += emojis ? `${labels.lblEmojis}: ${emojis}\n` : "";
			structuredPrompt +=
				länge.min || länge.max
					? länge.max
						? `${labels.lblLength}: ${labels.lblBetween} ${länge.min || "0"} ${labels.lblAnd} ${länge.max
						} ${labels.lblWords} ${labels.lblLong}\n`
						: `${labels.lblLength}: ${labels.lblMin} ${länge.min} ${labels.lblWords} ${labels.lblLong}\n`
					: "";
			structuredPrompt += zielgruppe ? `${labels.lblTargetGroup}: ${zielgruppe}\n` : "";
			structuredPrompt += callToAction
				? `${labels.lblCallToAction}: ${callToAction}\n`
				: "";
			structuredPrompt += form ? `${labels.lblTextStructure}: ${form}\n` : "";
			structuredPrompt += sprache ? `${labels.lblLanguage}: ${sprache}\n` : "";
			structuredPrompt += platform ? `${labels.lblPlatform}: ${platform}\n` : "";
		}
		if(selectedChatType === "option4"){
			// structuredPrompt = labels.lblTitle+': '+titleValue
			structuredPrompt += object ? `${labels.lblObject}: ${object}\n` : "";
			structuredPrompt += adjective ? `${labels.lblAdjective}: ${adjective}\n` : "";
			structuredPrompt += position ? `${labels.lblPosition}: ${position}\n` : "";
			structuredPrompt += numberImg ? `${labels.lblNumberImg}: ${numberImg}\n` : "";
			
			structuredPrompt += picStyle ? `${labels.lblStyle}: ${picStyle}\n` : "";
			structuredPrompt += sprache ? `${labels.lblLanguage}: ${sprache}\n` : "";
			structuredPrompt += parameter ? `${labels.lblParameter}: ${parameter}\n` : "";
			structuredPrompt += onlyPrompt ==labels.lblYes ? `${labels.lblOnlyPrompt}\n` : "";

			
		}

		setTextString(structuredPrompt);
		setTextStringBackup(structuredPrompt)
	};

	useEffect(() => {
		switch (selectedChatType) {
			case "option1":
				break;
			case "option2":
				fnGenUnstructured(formData);
				break;
			case "option3":
				fnGenStructured(formData);
				break;
			case "option4":
				break;
			default:
				break;
		}
	}, [formData, selectedChatType]);

	useEffect(() => {
		titleValue!=''?setTextString(labels.lblTitle+': '+titleValue+"\n"+textStringBackup):setTextString('')
		if (textString.trim()) {
			if (titleValue.trim()) {
				setActive(false)
				
			} else {
				setActive(true)
			}
		} else {
			setActive(true)
		}
	}, [textString, titleValue])

	useEffect(()=>{
		if(setShowSave){
			if(inputText!==''){
				setShowSave(true)
			}else{
				setShowSave(false)
			}
		}
		
	},[inputText])
	const testtest = ()=>{
		alert('test')
	}



	return (
		<form onSubmit={handleSubmit}>
			<div className="bluebg">
				<div class="labelquestion">
					<label for="title">{labels.lblTitle}:</label>
					{/* <span>?</span> */}
				</div>
				<input type="text" name="title" id="title" placeholder={labels.lblTitlePlaceholder}  value={titleValue} onChange={handleTitleChange}></input>
				{selectedChatType === "option1" && (
					<>
						<div class="labelquestion">
							<label>{labels.lblPromptInput}:</label>
							{/* <span>?</span> */}


						</div>
						<div class="textarea-container">
							<textarea
								type="text"
								className="prompTextArea"
								cols="30" rows="10"
								id="inputText"
								placeholder={labels.lblSavePromptPlaceholder}
								value={inputText}
								ref={promptRef}
								onChange={handleInputChange}
							/>
							{/* <img
								class="copy-prompt textarea-image"
								onClick={handleCopyPrompt}
								src={CopySvg} />

							{isPopupVisible1 && (
								<div className="popup">{labels.lblCopy}</div>
							)} */}
						</div>
					</>
				)}
				{selectedChatType === "option2" && (
					arrSimplePrompt.map((inp) => (
						<InputRow
							key={inp.strRowLabel}
							strRowLabel={inp.strRowLabel}
							arrInputs={inp.arrInputs}
							fnOnChange={fnSimplePromptChange}
							rows={inp.rows}
						/>
					))
				)}
				{selectedChatType === "option3" && (
					// <div>
					<StructuralChat
						key={selectedChatType+'_Chat'}
						onChatValuesChange={fnGenStructured}
						selectedChatType={selectedChatType}
						// picture={false}
					/>
					// </div>
				)}
				{selectedChatType === "option4" && (
					// <div>
					<StructuralChat
						key={selectedChatType+'_Chat'}
						onChatValuesChange={fnGenStructured}
						selectedChatType={selectedChatType}
						// picture={true}
					/>
					// </div>
				)}
				{/* {selectedChatType === "option4" && (
						<div className='showBlock4'>
						<label>Assistant Prompt</label>
						<ChatAssistant
							onAssistantValuesChange={handleAssistantValuesChange}
							selectedChatType={selectedChatType}
						/>
						</div>
					)} */}
				{/* </div> */}


				{/* 
				<div className="showBlock1-Input">
						<div className="showBlock1-InputPadding">
							<label>Notiz:</label>
							<textarea  
								className="prompTextArea"
								value={noteValue}
								onChange={handleNoteChange}
							/>
						</div>
					</div> */}
				{selectedChatType === "option1" && (
					<>
						<div class="labelquestion">
							<label for="note">{labels.lblNote}:</label>
							{/* <span>?</span> */}
						</div>
						<textarea name="note" id="note" cols="30" rows="3" placeholder={labels.lblNotePlaceholder} value={noteValue} onChange={handleNoteChange}></textarea>
						<div class="labelquestion">
							<label for="output">{labels.lblAIOutput}:</label>
							{/* <span>?</span> */}
						</div>
						<div class="textarea-container"
						onDrop={(dropEvent) => {
							dropEvent.preventDefault();
							const objReader = new FileReader();
							objReader.addEventListener("load", () => {
								setErgebnisWert(objReader.result);
							});

							let objFile = dropEvent.dataTransfer.items[0].getAsFile();
							if(objFile){
								objReader.readAsDataURL(objFile);
							} else {
								const strURL = dropEvent.dataTransfer.getData('url')
								if(fnCheckImageURL(strURL)){
									setErgebnisWert(strURL);
								} else {
									fetch(strURL).then((response)=>{
										objReader.readAsDataURL(response.blob);
									}).catch((err)=>{
										swal(labels.lblRequireLocalUpload)
									})
								}
								console.log(strURL);
							}
						}}
						onDragOver={(dragOverEvent) => {
							dragOverEvent.preventDefault();
						}}>
							{fnCheckImageURL(ergebnisWert)
								?<div style={{display: "inline-block", position:"relative"}}>
									<img src={ergebnisWert} style={{maxWidth: "100%"}} />
									<button 
										type="button"
										style={{position:"absolute", top: "5px", right: "5px", width: "auto", backgroundColor:"transparent"}}
										onClick={()=>{
											setErgebnisWert("");
										}}
									>
										<img src={img_delete} style={{width: "2em"}}/>
									</button>
								</div>
								:<></>
							}
							<textarea
								name="output"
								id="outputText"
								cols="30"
								rows="10"
								placeholder={labels.lblPlaceholderOutput}
								required
								value={ergebnisWert}
								ref={promptOutputRef}
								onChange={handleOutputChange}
								style={fnCheckImageURL(ergebnisWert)?{display:"none"}:{}}
								disabled={false}
							/>
							{/* <img
								class="copy-prompt textarea-image"
								// onClick={handleCopyPrompt}
								src={CopySvg} />

							{isPopupVisible1 && (
								<div className="popup">{labels.lblCopy}</div>
							)} */}
						</div>
					</>
				)}
			</div>


			{selectedChatType !== "option1" && (
				// <div className="row2">
					<button type="submit" class="create" >
						{labels.lblPromptGenerate}
					</button>
				// {/* </div> */}
			)}

			{inputText !== "" && selectedChatType !== "option1" && (
				
				<div className="bluebg">

					<div class="labelquestion">
						<label for="generatedtext">{labels.lblAIGenerate}:</label>
						{/* <span>?</span> */}
					</div>
					{/* 
					<div className="showBlock1-Input">
						<div className="showBlock1-InputPadding"> */}
					{/* <label>{labels.lblAIGenerate}:</label> */}
					<div class="textarea-container">
						<textarea
							type="text"
							className="prompTextArea"
							cols="30" rows="10"
							placeholder={labels.lblSavePromptPlaceholder}
							id="inputText"
							value={inputText}
							ref={promptRef}
							onChange={handleInputChange}
						/>
						<img
							class="copy-prompt textarea-image"
							onClick={handleCopyPrompt}
							src={CopySvg} />

						{/* {isPopupVisible1 && (
							<div className="popup">{labels.lblCopy}</div>
						)} */}
						{isPopupVisible1 && (
							<div id="CopyTextPrompt" class="copy-alert">{labels.lblTextCopied}</div>
						)}
					</div>
					{/* <span
								className="spanButton"
								onClick={handleCopyPrompt}
							>
								<FaRegCopy />
							</span>
							{isPopupVisible1 && (
								<div className="popup">{labels.lblCopy}</div>
							)} */}
					{/* </div>
					</div> */}


					<div class="labelquestion">
						<label for="output">{labels.lblAIOutput}:</label>
						{/* <span>?</span> */}
					</div>

					{/* <label>{labels.lblAIOutput}:</label> */}
					<div 
						class="textarea-container"
						onDrop={(dropEvent) => {
							dropEvent.preventDefault();
							const objReader = new FileReader();
							objReader.addEventListener("load", () => {
								setErgebnisWert(objReader.result);
							});

							let objFile = dropEvent.dataTransfer.items[0].getAsFile();
							if(objFile){
								objReader.readAsDataURL(objFile);
							} else {
								const strURL = dropEvent.dataTransfer.getData('url')
								if(fnCheckImageURL(strURL)){
									setErgebnisWert(strURL);
								} else {
									fetch(strURL).then((response)=>{
										objReader.readAsDataURL(response.blob);
									}).catch((err)=>{
										swal(labels.lblRequireLocalUpload)
									})
								}
								console.log(strURL);
							}
						}}
						onDragOver={(dragOverEvent) => {
							dragOverEvent.preventDefault();
						}}
					
					>
						{fnCheckImageURL(ergebnisWert)
								?<div style={{display: "inline-block", position:"relative"}}>
									<img src={ergebnisWert} style={{maxWidth: "100%"}} />
									<button 
										type="button"
										style={{position:"absolute", top: "5px", right: "5px", width: "auto", backgroundColor:"transparent"}}
										onClick={()=>{
											setErgebnisWert("");
										}}
									>
										<img src={img_delete} style={{width: "2em"}}/>
									</button>
								</div>
								:<></>
							}
						<textarea
							className="prompTextArea"
							value={ergebnisWert}
							id="outputText"
							cols="30" rows="10"
							placeholder={
								
								selectedChatType !== "option4"?labels.lblPlaceholderOutput:labels.lblPlaceholderOutputImage
							}
							ref={promptOutputRef}
							onChange={handleOutputChange}
							style={fnCheckImageURL(ergebnisWert)?{display:"none"}:{}}
							disabled={outputDisabled}
						/>
						{/* <img
							class="copy-prompt textarea-image"
							// onClick={handleCopyPrompt}
							src={CopySvg} />

						{isPopupVisible1 && (
							<div className="popup">{labels.lblCopy}</div>
						)} */}
					</div>

					<div class="labelquestion">
						<label for="note">{labels.lblNote}:</label>
						{/* <span>?</span> */}
					</div>
					<textarea name="note" id="note" cols="30" rows="3" placeholder={labels.lblNotePlaceholder} value={noteValue} onChange={handleNoteChange}></textarea>
				</div>
			)}


		</form>
	);
};

export default ChatComponent;
