import React, { Fragment, useState } from "react";
import { useSharedData } from "../SharedDataContext";
import BenutzerInfoItem from "./BenutzerInfoItem";
import Return from "../images/return.svg";

const BenutzerList = () => {
	const { loggedInUser, users, userDepartments, departments, accountRoles } = useSharedData();
	const [user, setUser] = useState([{ pkUserID: 0 }]);
	const firmUsers = users.filter(
		(user) => user.fkCompanyID === loggedInUser.fkCompanyID
	);
	const [show, setShow] = useState(false);
	const { labels, setLabels } = useSharedData();

	const [changeField, setchangeField] = useState(false);
	const showField = () => {
		if (changeField) {
			setchangeField(false);
		} else {
			setchangeField(true);
		}
	};

	const clickButtonHandler = (id) => {
		setShow(false);
		const user = users.filter((u) => u.pkUserID === Number(id));
		setUser(user);
		setShow(true);
		showField();
	};

	const closeDetails = () => {
		setShow(false);
		showField();
	};

	return (
		<>
			<div>

				<table className="pi settings-body table MainShape">
					<thead>
						<tr>
							<th class="table-title" colspan="3">{labels.lblUserlist}</th>
						</tr>
					</thead>
					<tbody>
	
						<div>
							{!changeField && (
								<>
									<table className="BenutzerList">
										<thead>
											<tr>
												<th style={{textAlign:"left", paddingLeft:"50px"}}>{labels.lblUserName}</th>
												{/* <th>{labels.lblFirstName}</th>
												<th>{labels.lblEmail}</th> */}
												<th></th>
											</tr>
										</thead>

										<tbody>
											{firmUsers.map((user) => (
												<tr className="row" key={user.pkUserID}>
													<th className="userListValue"><span className="rowSpanWidth" style={{width:"350px"}}>{user.strUserName}</span></th>
													{/* <td>{user.strFirstName}</td>
													<td>{user.strEmail}</td> */}
													<td className="td-button userListButton" style={{ width: "200px" }}>
														<button
															className="table-button"
															type="button"
															onClick={() =>
																clickButtonHandler(
																	user.pkUserID
																)
															}
															style={{
																margin: "0px",
																width: "170px",
															}}
														>
															<a>
																{labels.lblDetail}
															</a>
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</>
							)}
							{show && changeField && (
								<div>
									{/* <h2>{labels.lblUserDetails}</h2> */}

									<>
										<span
											onClick={closeDetails}
										>
											<p className="closeSpan">
												<img class="return" src={Return} alt="Return" />
											</p>
										</span>
									</>
									<table className="loggedInUser">
										<tbody>
											<BenutzerInfoItem
												label={labels.lblLastName}
												strEditType="text"
												strValueID="strSurName"
												value={user[0].strSurName}
												userID={user[0].pkUserID}
											/>
											<BenutzerInfoItem
												label={labels.lblFirstName}
												strEditType="text"
												strValueID="strFirstName"
												value={user[0].strFirstName}
												userID={user[0].pkUserID}
											/>

											<BenutzerInfoItem
												label={labels.lblEmail}
												strEditType="text"
												strValueID="strEmail"
												value={user[0].strEmail}
												userID={user[0].pkUserID}
											/>
											<BenutzerInfoItem
												label={labels.lblUserName}
												strEditType="text"
												strValueID="strUserName"
												value={user[0].strUserName}
												userID={user[0].pkUserID}
											/>
											{user[0] && (
												<BenutzerInfoItem
													label={labels.lblMainDepartment}
													boolEdit={loggedInUser?.fkDataRoleID > 1}
													strEditType="select"
													strValueID="intMainDepartment"
													arrOptions={departments
														.filter(
															(department) =>
																department.fkCompanyID ===
																user[0].fkCompanyID
														)
														.map((department) => ({
															value: department.pkDepartmentID,
															name: department.strDepartment,
														}))}
													value={user[0].intMainDepartment}
													userID={user[0].pkUserID}
												/>
											)}
											<BenutzerInfoItem
												label={labels.lblDepartment}
												boolEdit={loggedInUser?.fkDataRoleID > 1}
												strEditType="multiselect"
												arrOptions={departments
													.filter(
														(department) =>
															department.fkCompanyID ===
															user[0].fkCompanyID
													)
													.map((department) => ({
														value: department.pkDepartmentID,
														name: department.strDepartment,
													}))}
												value={userDepartments.filter(dep => dep.fkUserID == user[0].pkUserID).map(
													(item) => item.fkDepartmentID
												)}
												userID={user[0].pkUserID}
											/>
											<BenutzerInfoItem
												label={labels.lblRole}
												strEditType="select"
												strValueID="fkDataRoleID"
												arrOptions={accountRoles
													.map((role) => ({
														value: role.pkDataRoleID,
														name: role.strRole,
													}))}
												value={user[0].fkDataRoleID}
												userID={user[0].pkUserID}
											/>
											<BenutzerInfoItem
												label={labels.lblCreationDate}
												strEditType="disabled"
												value={user[0].datCreate}
												userID={user[0].pkUserID}
											/>
										</tbody>
									</table>
								</div>
							)}
						</div>
					</tbody>
				</table>
			</div>

		</>
	);
};

export default BenutzerList;
