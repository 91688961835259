import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../images/firmenpunkt_logo.svg";
import { useSharedData } from "../SharedDataContext";

const Footer = () => {
	const navigate = useNavigate();
	const {labels, setLabels} = useSharedData();
	const datenschutzerklärungClick = () => {
		navigate("/datenschutzerklaerung");
	};

	const impressumClick = () => {
		navigate("/impressum");
	};

	return (
		<Fragment>
			<div className="footer">
				{/* <div className="footerRight">
					<span>{labels.lblProductOf}</span><img src={Logo}></img> 
				</div>
				<div className="footerLeft">
					<a>{labels.lblDataProtection}</a>
					<a>{labels.lblImprint}</a>
				</div> */}
				<p class="curserPointer">
				{/* <span onClick={datenschutzerklärungClick}>{labels.lblDataProtection}</span> | <span onClick={impressumClick}>{labels.lblImprint}</span> */}
				</p>
        		{/* <span class="image">E P</span> */}
				<span class="curserDefault">{labels.lblProductOf} <a href="https://www.firmenpunkt.de/" class="image">FIRMENPUNKT GMBH</a></span>
			</div>
		</Fragment>
	);
};

export default Footer;
