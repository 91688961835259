import React, { useState } from "react";
import FirmInfoItem from "./FirmInfoItem";
import FirmLogoUpload from "./FirmLogoUpload";
import { useEffect } from "react";
import { useSharedData } from "../SharedDataContext";
import BereicheList from "./BereicheList";
import { useUser } from "../SharedDataContext";

const FirmInfo = ({ firmId }) => {
	const {loggedInUser, firms, setFirms, zipCodes, callBackend} = useSharedData();
	const [name, setName] = useState("");
	const [strasse, setStrasse] = useState("");
	const [zusatz, setZusatz] = useState("");
	const [plzID, setPLZID] = useState("");
	const [city, setCity] = useState("")
	const [country, setCountry] = useState("");
	const [url, setURL] = useState("");
	const [mainColor, setMainColor] = useState("");
	
	const [color, setColor] = useState(true);
	const { labels, setLabels } = useSharedData();
	useEffect(() => {
		if (firms) {
			const filteredFirm = firms.filter(
				(uk) => uk.pkCompanyID === firmId
			);

			if (filteredFirm.length > 0) {
				setName(filteredFirm[0].strCompanyName);
				setStrasse(filteredFirm[0].strStreet);
				setPLZID(filteredFirm[0].strCompanyZipCode);
				setURL(filteredFirm[0].strWebsite);
				setZusatz(filteredFirm[0].strAddition);
				setMainColor(filteredFirm[0].strCompanyColor);
				setCountry(filteredFirm[0].strCountry)
				setCity(filteredFirm[0].strCompanyCity)
			}
		}
	}, [color]);


	const handleChangeColorClick = ()=>{
		setColor(!color)
		var r = document.querySelector(':root');
		r.style.setProperty('--mainColor', mainColor);
		r.style.setProperty('--secondColor', aufhellen(mainColor,40));
		r.style.setProperty('--thirdColor', aufhellen(mainColor,90));
	}
	const changeColor = ()=>{
		var inputColor = document.getElementById('color').value
		var r = document.querySelector(':root');
		r.style.setProperty('--mainColor', inputColor);
		r.style.setProperty('--secondColor', aufhellen(inputColor,40));
		r.style.setProperty('--thirdColor', aufhellen(inputColor,90));
	}
	const saveColor2 = ()=>{
		
		var inputColor = document.getElementById('color').value
		var r = document.querySelector(':root');
		r.style.setProperty('--mainColor', inputColor);
		
	}

	const aufhellen=(hex, faktor) =>{
		// Überprüfen, ob der HEX-Wert richtig formatiert ist
		if (!/^#([0-9A-F]{3}){1,2}$/i.test(hex)) {
			throw new Error('Ungültige HEX-Farbe');
		}
	
		// Entfernen des '#'-Symbols, falls vorhanden
		hex = hex.replace('#', '');
	
		// Konvertieren des HEX-Werts in RGB
		let r = parseInt(hex.substring(0, 2), 16);
		let g = parseInt(hex.substring(2, 4), 16);
		let b = parseInt(hex.substring(4, 6), 16);
	
		// Helligkeit des Weißwertes
		const weiß = 255;
	
		// Helligkeit der Farbe erhöhen, indem relative Helligkeit von Weiß hinzugefügt wird
		r += (weiß - r) * faktor / 100;
		g += (weiß - g) * faktor / 100;
		b += (weiß - b) * faktor / 100;
	
		// Begrenzen der Werte auf den Bereich von 0 bis 255
		r = Math.min(255, Math.max(0, r));
		g = Math.min(255, Math.max(0, g));
		b = Math.min(255, Math.max(0, b));
	
		// Konvertieren der RGB-Werte zurück in HEX
		r = Math.round(r).toString(16).padStart(2, '0');
		g = Math.round(g).toString(16).padStart(2, '0');
		b = Math.round(b).toString(16).padStart(2, '0');
		return `#${r}${g}${b}`;
	}

	const saveColor = () => {
		if (!color) {
			var inputColor = document.getElementById('color').value
			callBackend({
				action: "update_firm",
				payload: {
					pkCompanyID: loggedInUser.fkCompanyID,
					strCompanyColor: inputColor,
				},
			}).catch((err) => console.log(err));
			setFirms(
				firms.map((item) =>
					item.pkCompanyID === loggedInUser.fkCompanyID
						? {
								...item,
								strCompanyColor: inputColor,
						  }
						: item
				)
			);
			var r = document.querySelector(':root');
			r.style.setProperty('--mainColor', inputColor);
			r.style.setProperty('--secondColor', aufhellen(inputColor,30));
			r.style.setProperty('--thirdColor', aufhellen(inputColor,80));
		}
		setColor(!color);
	};

	return (
		<div>
			<div>
				<h2>{labels.lblCompanyInformation}</h2>
				{name !== "" && (
					<table class="cd settings-body table">
						<thead>
							<tr>
								<th class="table-title" colspan="2">
									{labels.lblCompanyInformation}
								</th>
							</tr>
						</thead>

						<tbody>
							<tr className="row">
								<th className="td1">{labels.lblCompanyLogo}</th>
								<td className="td2">
									<FirmLogoUpload />
								</td>
							</tr>
							<FirmInfoItem
								label={labels.lblName}
								value={name}
								strEditType="text"
								strValueID="strCompanyName"
							/>
							<FirmInfoItem
								label={labels.lblStreet}
								value={strasse}
								strEditType="text"
								strValueID="strStreet"
							/>
							<FirmInfoItem
								label={labels.lblAddition}
								value={zusatz}
								strEditType="text"
								strValueID="strAddition"
							/>
							
							<FirmInfoItem
								label={labels.lblLocality}
								value={plzID+" "+city}
								strEditType="text"
							/>
							<FirmInfoItem
								label={labels.lblCountry}
								value={country}
								strEditType="text"
								strValueID="strCountry"
							/>
							<FirmInfoItem
								label={labels.lblBranch}
								strEditType="text"
								value="Technology"
							/>
							{/*??*/}
							<FirmInfoItem
								label={labels.lblWebsite}
								strEditType="link"
								value={url}
							/>

							<tr className="row">
								<th className="td1">{labels.lblMainColor}</th>

								{loggedInUser.fkDataRoleID <= 1 && color&&(
									<>
										<td className="td2">
											<input disabled type="color" value={mainColor} class="color title"></input>
										</td>
									</>
								)}
								
								{loggedInUser.fkDataRoleID > 1 && color&&(
									<>
										<td className="td2">
									
										<div class="title-bookmark">
											<input disabled data-title={labels.lblPressChageColor} type="color" value={mainColor} class="color title"></input>
										<button
												className="company-change-button"
												onClick={handleChangeColorClick}
											>
												<a id="openModalLink">{labels.lblChange}</a>
											</button>
											</div>
										</td>
									</>
								)}
								{loggedInUser.fkDataRoleID > 1 && !color&&(
									<>
										<td className="td2">
										<div class="title-bookmark">
											<input data-title={labels.lblAddColor} type="color" class="color title" id="color"></input>
											
											<button onClick={handleChangeColorClick} class="td2-change-button">{labels.lblCancel}</button>
											<button onClick={changeColor} class="td2-change-button">{labels.lblPreview}</button>
											<button onClick={saveColor} class="td2-change-button">{labels.lblSave}</button>
											</div>
										</td>
									</>
								)}
								{/* <td className="td-button">
									
								</td> */}
							</tr>
							{/* <button onClick={toggleGreen}>Grün</button>
							<button onClick={toggleBlue}>Blau</button>
							<input type="color" value={newMainColor} id="color"></input>
							<button onClick={setMainColor}>Ändern</button> */}
						</tbody>
					</table>
				)}
			</div>
			<div>{<BereicheList />}</div>
		</div>
	);

	return (
		<div>
			<div>
				<h2>{labels.lblCompanyInformation}</h2>
				{name !== "" && (
					<table>
						<tbody>
							<FirmInfoItem
								label={labels.lblName}
								value={name}
								strEditType="text"
								strValueID="strCompanyName"
							/>
							<FirmInfoItem
								label={labels.lblStreet}
								value={strasse}
								strEditType="text"
								strValueID="strStreet"
							/>
							<FirmInfoItem
								label={labels.lblLocality}
								value={plzID}
								strEditType="select"
								arrOptions={zipCodes
									.filter((obj) => obj.pkDataZipCodeID !== 0)
									.map((obj) => ({
										value: obj.pkDataZipCodeID,
										name: `${obj.strZipCode} ${obj.strCity}`,
									}))}
							/>
							<FirmInfoItem
								label={labels.lblBranch}
								strEditType="text"
								value="Technology"
							/>
							{/*??*/}
							<FirmInfoItem
								label={labels.lblWebsite}
								strEditType="link"
								value={url}
							/>
						</tbody>
					</table>
				)}
			</div>
			<div>
				<h2>{labels.lblDepartment}</h2>
				{<BereicheList />}
			</div>
		</div>
	);
};

export default FirmInfo;
